import { Button } from "antd";
import styled from "styled-components";

export const StyledPage = styled.div`
  display: block;
  margin: auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 56px;
  padding: 10px 25px;
  overflow-y: hidden;
  position: relative;
`;

export const StyledPreviewButton = styled(Button)`
  background-color: var(--primary-color);
  color: #fff;
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  height: 60px;
  width: 60px;
  border-radius: 5px;

  :hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

export const StyledPreviewWrapper = styled.div`
  background-color: #f6faff;
  align-items: start;
  display: flex;
  // flex-direction: column;
  position: relative;
  justify-content: center;
  // align-items: center;
  color: initial;
  width: 100%;
  height: 100%;
  padding: 15px;

  overflow-y: scroll;

  ${StyledPage}:hover {
    background-color: rgba(0, 0, 0, 0.2);

    ${StyledPreviewButton} {
      display: block;
      background-color: #f0f2f5;
    }
  }
`;
