import { useMutation } from "@apollo/client";
import { RouteComponentProps, useLocation } from "@reach/router";
import { Modal, Result } from "antd";
import Button from "app/components/ui/button";
import Loader from "app/components/ui/loader";
import { RESEND_VERIFICATION_MAIL } from "app/graphql/auth/mutations";
import React, { useState } from "react";
import { StyledFormWrapper } from "../styles";

const UnVerified: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [resendVerificationEmail, { loading }] = useMutation(RESEND_VERIFICATION_MAIL);
  const [isSuccess, setSuccess] = useState(false);
  const { state }: any = useLocation();

  const handleSubmit = () => {
    resendVerificationEmail({
      variables: {
        input: {
          email: state?.email
        }
      }
    }).then(() => {
      setSuccess(true);
    });
  };

  const handleEmailMessage = () => {
    Modal.warn({
      title: "Vous n'avez pas reçu votre e-mail ?",
      content: (
        <div>
          Il est possible qu&apos;il se trouve dans votre dossier de courriels indésirables ou dans l&apos;onglet
          &quot;Promotions&quot; de Gmail. Veuillez vérifier ces dossiers et ajouter notre adresse à votre liste de
          contacts pour vous assurer de recevoir tous nos courriels à l&apos;avenir.
        </div>
      ),
      closable: true
    });
  };

  return (
    <StyledFormWrapper className="text-center space-y-10 h-[300px] flex justify-center flex-col">
      {isSuccess ? (
        <Result
          style={{ padding: 0 }}
          status="success"
          title="E-mail de vérification renvoyer avec succès!"
          subTitle=""
        />
      ) : (
        <>
          <div className="text-center text-primary font-bold text-3xl pb-5">Impossible de vous connecter</div>

          <span className="text-lg text-center">votre adresse e-mail n&apos;a pas été vérifiée.</span>

          <Button
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
            color="primary"
            variant="contained"
            style={{ height: 48 }}
            block
            type="submit"
          >
            {loading ? <Loader /> : " Me renvoyer l'e-mail"}
          </Button>

          <Button
            onClick={handleEmailMessage}
            disabled={loading}
            loading={loading}
            color="primary"
            variant="text"
            style={{ height: 48 }}
            block
            type="submit"
          >
            Vous n&apos;avez pas reçu votre e-mail ?
          </Button>
        </>
      )}
    </StyledFormWrapper>
  );
};

export default UnVerified;
