import { RouteComponentProps } from "@reach/router";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Button from "app/components/ui/button";

import { useStore } from "app/contexts/store-context";
import { CheckMarkIcon } from "app/icons";
import TextEditor from "app/main/text-editor";
import { getResumeTitle } from "app/utils";
import { FormEvent } from "react";

const Overview = (props: RouteComponentProps): JSX.Element => {
  const {
    state: { resume },
    dispatch
  } = useStore();

  const handleEdition = () => {
    // onChange(inputRef.current.input.value);
    // setEditionMode(false);
  };

  return (
    <div className="space-y-5">
      <span className="font-bold">Donner un titre à votre CV</span>

      <div className="flex gap-2">
        <Input defaultValue={getResumeTitle(resume || {})} />
        <Button className="edition !w-[100px] !h-[48px]" variant="contained" color="primary" onClick={handleEdition}>
          Valider
        </Button>
      </div>
    </div>
  );
};

export default Overview;
