import { Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Input from "app/components/ui/input";
import Label from "app/components/ui/label";
import ProgressDate from "app/components/ui/progress/progress-date";
import { ProgressEntryProps } from "app/components/ui/progress/types";
import TextEditor from "app/main/text-editor";
import { Project } from "generated/graphql";
import { ChangeEvent } from "react";

export const EntriesInputs = ({ progression: entry, index, onChange }: ProgressEntryProps<Project>) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    onChange(name, value, index);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target;

    onChange(name, checked, index);
  };

  const handleDescriptionChange = (text: string) => {
    onChange("description", text, index);
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Label>Titre du projet</Label>
        <Input value={entry.title} name="title" onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <Label>Lien du projet</Label>
        <Input name="link" type="url" value={entry.link} onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <Checkbox name="isCurrent" onChange={handleCheckboxChange} checked={entry.isCurrent}>
          Je travaille toujours sur ce projet
        </Checkbox>
      </Col>

      <Col xs={24} md={12}>
        <Label>Date de début</Label>
        <ProgressDate
          key={1}
          value={entry.startDate}
          onChange={(value) => {
            onChange("startDate", value, index);
          }}
        />
      </Col>

      <Col xs={24} md={12}>
        {entry.isCurrent ? (
          <div className="flex items-end h-full align-bottom">
            <div className="w-full border-1 border-[1px] rounded-[4px] border-[#d9d9d9] h-[48px]  flex items-center pl-2">
              Présent
            </div>
          </div>
        ) : (
          <>
            <Label>Date de fin</Label>
            <ProgressDate
              key={2}
              value={entry.endDate}
              onChange={(value) => {
                onChange("endDate", value, index);
              }}
            />
          </>
        )}
      </Col>

      <Col span={24}>
        <Label>Description</Label>
        <TextEditor value={entry.description} onChange={handleDescriptionChange} />
      </Col>
    </Row>
  );
};

export default EntriesInputs;
