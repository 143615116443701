import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { authManager } from "./utils";

interface PrivateRouteProps extends RouteComponentProps {
  as: React.FC<RouteComponentProps>;
  path: string;
}

export function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const { as: Component, ...rest } = props;
  const token = authManager.readToken();

  if (!token) return <Redirect noThrow to="/login" />;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...rest} />;
}
