import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import { message } from "antd";
import Button from "app/components/ui/button";
import MainLayout from "app/components/ui/layout";
import Loader from "app/components/ui/loader";
import Modal from "app/components/ui/modal";
import { PRICINS } from "app/graphql/queries";
import { BUY_CREDITS } from "app/graphql/subscriptions/mutations";
import clsx from "clsx";
import { useState } from "react";

const pluralize = (str, count) => `${str}${count > 1 ? "s" : ""}`;

interface CreditSubscriptionItemProps {
  index: number;
  name: string;
  price: number;
  unit: number;
  text: string;
  label: string;
  onChoose: (name: string) => void;
}

function CreditSubscriptionItem({ index, name, unit, price, text, label, onChoose }: CreditSubscriptionItemProps) {
  const handleChoose = () => {
    onChoose(name);
  };
  return (
    <div
      className={clsx(
        "relative max-w-[300px] w-full shadow-md rounded-[4px] px-6 py-10 h-[350px]  flex  flex-col text-center gap-4 justify-between bg-white border-tertiary",
        {
          // "border-t-8": index === 0,
          "border-t-8": index === 1
        }
      )}
    >
      <div className="text-lg font-semibold uppercase text-primary">
        {unit} {pluralize("crédit", unit)}
      </div>
      <div className="text-3xl font-bold text-black">{price} FCFA</div>
      <Button onClick={handleChoose} block variant="contained" color="primary" className="!p-6">
        J&apos;achète
      </Button>
    </div>
  );
}

function Subscriptions(props: RouteComponentProps) {
  const { data, loading: pricingLoading } = useQuery(PRICINS);
  const [buyCredits, { loading }] = useMutation(BUY_CREDITS);
  const [cgvCheckBox, setCGV] = useState(false);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [selectedOption, setOption] = useState(null);

  const handleConfirm = () => {
    buyCredits({ variables: { input: { name: selectedOption } } }).then((res) => {
      if (res?.data?.result?.paymentUrl) {
        const tab = window.open(res.data.result.paymentUrl, "_blank");
        if (!tab) {
          window.location.href = res.data.result.paymentUrl;
        }
        setConfirmModal(false);
      } else {
        message.error("Une erreur s'est produite");
      }
    });
  };

  const handleClose = () => {
    setConfirmModal(false);
  };

  const handleChoose = (name: string) => {
    setOption(name);
    setConfirmModal(true);
  };

  if (pricingLoading) {
    return <Loader />;
  }

  return (
    <MainLayout>
      <div className="flex flex-col h-full">
        <div className="p-10 space-y-5 text-center text-white bg-primary">
          <h1 className="text-2xl font-medium text-center">Achetez des crédits pour télécharger vos CV</h1>
          <p className="italic">* Un crédit correspond à un téléchargement</p>
        </div>

        <div className="xl:w-[70%] w-full m-auto py-10 ">
          <div className="grid grid-cols-1 gap-10 p-5 m-auto text-black md:grid-cols-2 place-items-center">
            {data?.result.map((plan, index) => (
              <CreditSubscriptionItem
                index={index}
                key={plan.id}
                name={plan.id}
                unit={plan.credits}
                price={plan.price}
                text={plan.description}
                label={plan.name}
                onChoose={handleChoose}
              />
            ))}
          </div>
        </div>
      </div>

      <Modal
        visible={showConfirmModal}
        title="Voulez-vous poursuivre votre achat ?"
        onOk={handleConfirm}
        onCancel={handleClose}
        okText="Continuer"
        cancelText="Annuler"
        confirmLoading={loading}
      >
        <div>
          <div>Vous serez redirigé vers notre page de paiement</div>
        </div>
      </Modal>
    </MainLayout>
  );
}

export default Subscriptions;
