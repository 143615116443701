import { Col, Row } from "antd";
import Input from "app/components/ui/input";
import Label from "app/components/ui/label";
import { Reference } from "generated/graphql";
import { ChangeEvent } from "react";

interface ProgressionInput<T> {
  progression: T;
  index: number;
  onChange: (name: string, value: any, index: number) => void;
}

export const ReferenceInputs = ({ progression: experience, index, onChange }: ProgressionInput<Reference>) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    onChange(name, value, index);
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Label>Nom</Label>
        <Input value={experience.fullname} name="fullname" onChange={handleInputChange} />
      </Col>

      <Col xs={24} md={12}>
        <Label>Titre</Label>
        <Input value={experience.title} name="title" onChange={handleInputChange} />
      </Col>

      <Col xs={24} md={12}>
        <Label>Entreprise</Label>
        <Input name="company" value={experience.company} onChange={handleInputChange} />
      </Col>

      <Col xs={24} md={12}>
        <Label>Téléphone</Label>
        <Input name="phone" value={experience.phone} onChange={handleInputChange} />
      </Col>
      <Col xs={24} md={12}>
        <Label>E-mail</Label>
        <Input name="email" value={experience.email} onChange={handleInputChange} />
      </Col>
    </Row>
  );
};

export default ReferenceInputs;
