import { gql } from "@apollo/client";

export const TEMPLATES = gql(`
 query templates {
  result: templates {
    id
    uid
    name
    preview
    component
  }
 }
`);
