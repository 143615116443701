import { useMutation } from "@apollo/client";
import { Input, message } from "antd";
import Modal from "app/components/ui/modal";
import { DUPLICATE_RESUME } from "app/graphql/resumes/mutations";
import { BaseModalProps } from "app/types";
import { useState } from "react";

interface DuplicateResumeProps extends BaseModalProps {
  resumeId: string;
}

function DuplicateResume({ visible, resumeId, onCancel }: DuplicateResumeProps) {
  const [title, setTitle] = useState("");
  const [duplicateResume] = useMutation(DUPLICATE_RESUME);

  const handleCancel = () => {
    onCancel();
    setTitle("");
  };

  const handleConfirm = () => {
    duplicateResume({ variables: { id: resumeId, newTitle: title } }).then(() => {
      message.success("CV dupliquer avec succès");
      onCancel(true);
      setTitle("");
    });
  };

  const handleChange = (e) => {
    setTitle(e.target.value);
  };
  return (
    <Modal
      visible={visible}
      title="Dupliquer un CV"
      okText="Confirmer"
      cancelText="Annuler"
      onCancel={handleCancel}
      onOk={handleConfirm}
    >
      <div>
        <Input placeholder="Titre du nouveau CV" value={title} onChange={handleChange} />
      </div>
    </Modal>
  );
}

export default DuplicateResume;
