import { Resume } from "generated/graphql";
import { lazy } from "react";
import Compress from "browser-image-compression";

/**
 * Capitalize the given string
 * @param {string} str string to format
 * @returns {string}  string (first char to uppercase & the rest in lowerCase)
 */
export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const loadTemplate = (path: string) =>
  lazy(() => import(`app/main/Templates/${path}`).catch(() => import(`app/main/Templates/loading`)));

export const getResumeTitle = ({ title, personalInfo: { firstname, lastname } = {} }: Resume) =>
  title || (firstname || lastname ? `CV de ${firstname || ""} ${lastname || ""}` : "CV sans titre");

export function compressImage(file: File) {
  const options = {
    maxSizeMB: 0.1,
    useWebWorker: true,
    fileType: "image/jpeg"
  };

  return Compress(file, options)
    .then((compressedBlob) => {
      const convertedBlobFile = new File([compressedBlob], file.name, {
        type: file.type,
        lastModified: Date.now()
      });
      return convertedBlobFile;
    })
    .catch((e) => null);
}

/**
 *
 * @param {any[]} items
 * @param {string} term
 * @param {string[]} columnsToSearchIn
 */
export function makeSearch<T extends any[]>(items: T, term: string, columnsToSearchIn?: string[]) {
  // const getNeedles = (obj: any): boolean =>
  //   Object.keys(obj).some((key) => {
  //     const haystack =
  //       obj[key] !== null && typeof obj[key] === "object"
  //         ? // Go full recursive when the key is an object ✨
  //           getNeedles(obj[key])
  //         : doesExist(obj, term, key, columnsToSearchIn);

  //     console.log({ haystack });
  //     return haystack;
  //   });

  return items?.filter((item) => item.value?.toLowerCase()?.includes(term.toLowerCase())) as T;
}

/**
 *
 * @param obj
 * @param term
 * @param key
 * @param columnsToSearchIn
 * @returns {boolean}
 */
const doesExist = (obj: any, term: string, key: string, columnsToSearchIn?: string[]) => {
  return String(obj[key]).toLowerCase().includes(term);

  return columnsToSearchIn.includes(key) && String(obj[key]).toLowerCase().includes(term);
};
