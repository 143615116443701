import { ITemplateTheme } from "app/types";

export const DRAWER_WITH = 240;
export const APP_BAR_HEIGHT = 64;
export const LOGO_PATH = "/logo.png";

export const MONTHS = [
  { label: "Janvier", value: 0 },
  { label: "Février", value: 1 },
  { label: "Mars", value: 2 },
  { label: "Avril", value: 3 },
  { label: "Mai", value: 4 },
  { label: "Juin", value: 5 },
  { label: "Juillet", value: 6 },
  { label: "Août", value: 7 },
  { label: "Septembre", value: 8 },
  { label: "Octobre", value: 9 },
  { label: "Novembre", value: 10 },
  { label: "Décembre", value: 11 }
];

const CURRENT_YEAR = new Date().getFullYear();
export const YEARS_RANGE = Array.from({ length: 50 }, (_, i) => CURRENT_YEAR - i);

export const LanguageLevelLabel = {
  0: "Choisissez votre niveau",
  1: "Débutant",
  2: "Intermédiaire",
  3: "Avancé",
  4: "Courant",
  5: "Bilingue ou Natif"
};

export const LanguageLevelLabelArray = Object.entries(LanguageLevelLabel).map(([value, label]) => ({
  label,
  value: Number(value)
}));

export const SkillLevelLabel = {
  0: "Choisissez votre niveau",
  1: "Débutant",
  2: "Intermédiaire",
  3: "Avancé",
  4: "Expert",
  5: "Maîtrise"
};

export const SkillLevelLabelArray = Object.entries(SkillLevelLabel).map(([value, label]) => ({
  label,
  value: Number(value)
}));

export interface ITemplate {
  path: string;
  component: string;
  theme?: ITemplateTheme;
}
export const TEMPLATES: ITemplate[] = [
  {
    path: "/resumes/resume-01.png",
    component: "01",
    theme: {
      colors: {
        bg: {
          "bg-1": "#2d2f32",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-02.png",
    component: "02",
    theme: {
      colors: {
        bg: {
          "bg-1": "#504ea1",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-03.png",
    component: "03",
    theme: {
      colors: {
        bg: {
          "bg-1": "#e9be27",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-04.png",
    component: "04",
    theme: {
      colors: {
        bg: {
          "bg-1": "#2d2f32",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  },
  {
    path: "/resumes/resume-05.png",
    component: "04",
    theme: {
      colors: {
        bg: {
          "bg-1": "#2d2f32",
          "bg-2": "#ffffff"
        },
        text: {}
      }
    }
  }
];

export const FONT_SIZE_RATIO = {
  XSS: 0.625,
  XS: 0.75,
  S: 0.875,
  M: 1,
  L: 1.125,
  XL: 1.25,
  XLL: 1.375
};

export const INPUT_ROW_SPACING = [12, 12] as any;

export const PICTURE_SIZE = {
  xs: -20,
  s: -10,
  m: 0,
  l: +10,
  xl: +20
};

export const ProfileTitleOption = [
  { value: "Administration du personnel" },
  { value: "Agent commercial" },
  { value: "Agent d'entretien" },
  { value: "Agent de  sûreté" },
  { value: "Agent logistique" },
  { value: "Analyste de données" },
  { value: "Analyste-concepteur si/formateur/maintenancier" },
  { value: "Assistant comptable" },
  { value: "Assistant comptable et financier" },
  { value: "Assistante comptable" },
  { value: "Assistante de direction" },
  { value: "Assistante rh" },
  { value: "Audit, contrôle de gestion, contrôle financier" },
  { value: "Banque et gestion de patrimoine" },

  { value: "Biotechnologies et environnement" },
  { value: "Bknbdfn" },
  { value: "Caissier" },
  { value: "Caissière" },
  { value: "Change maker" },
  { value: "Chargés de relations entreprises" },
  { value: "Chauffeur" },
  { value: "Chauffeur personnel" },
  { value: "Chef comptable" },
  { value: "Chef de projet digital junior" },
  { value: "Cloud & devops engineer" },
  { value: "Coach sportif" },
  { value: "Commercial" },
  { value: "Commerciale" },
  { value: "Communicatrice, marketeuse, publicitaire" },
  { value: "Community manager" },
  { value: "Comptable" },
  { value: "Conseiller client" },
  { value: "Conseillère client" },
  { value: "Contrôleur des actions humanitaires et sociales" },
  { value: "Data scientist" },
  { value: "Designer" },
  { value: "Developpeur" },
  { value: "Developpeur web" },
  { value: "Developpeur web mobile" },
  { value: "Devoloppeur mobile" },
  { value: "Digital product manager" },
  { value: "Directeur" },
  { value: "Directeur commercial" },
  {
    value:
      "Directeur pays - directeur du business development - directeur commercial - directeur stratégie & développement"
  },
  { value: "Développeur d'application" },
  { value: "Développeur fullstack" },
  { value: "Développeur web et devops" },
  { value: "Développeuse" },
  { value: "Développeuse web" },
  { value: "Electricien" },
  { value: "Esthétcienne/coiffeuse/markettiste/receptionniste" },
  { value: "Etudiant en transport logistique" },
  { value: "Etudiante" },
  { value: "Frontend dev" },
  { value: "Full stack javascript developer" },
  { value: "Gestion des ressources humaines" },
  { value: "Gestionnaire" },
  { value: "Gestionnaire de bureau" },
  { value: "Gestionnaire de projet et communication" },
  { value: "Gestionnaire de stock" },
  { value: "Graphic designer" },

  { value: "Ingénieur agronome" },
  { value: "Ingénieur développeur" },
  { value: "Ingénieur génie électrique spécialiste en énergie électrique" },
  { value: "Ingénieur informatique" },
  { value: "Ingénieur logiciel" },
  { value: "Ingénieur système de gestion automatisé" },
  { value: "Ingénieure réseau et télecommunication" },
  { value: "It support" },
  { value: "Juriste" },
  { value: "Juriste & rh" },
  { value: "Juriste-conseil" },
  { value: "Logisticien" },

  { value: "Maman" },
  { value: "Mecanicien" },
  { value: "Mobile developer" },
  { value: "Médecin" },
  { value: "Office & happiness manager" },
  { value: "Opérateur topographe" },
  { value: "Product owner" },
  { value: "Rayonnist" },
  { value: "Regerger" },
  { value: "Responsable commercial" },
  { value: "Responsable communication digitale" },
  { value: "Responsable comptable" },
  { value: "Responsable des opérations" },
  { value: "Responsable marketing" },
  { value: "Responsable production" },
  { value: "Responsable ressources humaines" },
  { value: "Réceptionniste polyvalente" },
  { value: "Réferent digital" },
  { value: "Référent digital" },
  { value: "Serveuse" },
  { value: "Slasher" },
  { value: "Social média manager" },
  { value: "Software & devops engineer" },
  { value: "Software engineering manager" },
  { value: "Spécialiste en rse & développement durable" },
  { value: "Stagiaire" },
  { value: "Stagiaire rh" },
  { value: "Stagiaires en droit fiscal" },
  { value: "Stagiare" },
  { value: "Styliste de mode" },
  { value: "Superviseur de zone omas.com mtn" },
  { value: "Talent acquisition & employer branding" },
  { value: "Teacher" },
  { value: "Technicien en systèmes, réseaux & cloud computing" },
  { value: "Technicien réseau" },
  { value: "Technicien supérieur" },
  { value: "Technicienne en tele&net" },
  { value: "Technicienne supérieure en télécommunications et réseaux de données" },
  { value: "Transit assistant" },
  { value: "Ux/ui designer" },
  { value: "Vendeuse à sdm" },
  { value: "Vendre au supermarché" },
  { value: "Étudiant" },
  { value: "Étudiant 1er année en software" },
  { value: "Étudiante" }
];
