import { gql } from "@apollo/client";
import { RESUME_FRAGMENT } from "./fragments";

export const MY_RESUMES = gql`
  query myResumes {
    result: myResumes {
      ...ResumeFragment
    }
  }
  ${RESUME_FRAGMENT}
`;

export const RESUME = gql`
  query resume($id: ID!) {
    resume(id: $id) {
      ...ResumeFragment
    }
  }
  ${RESUME_FRAGMENT}
`;

export const RESUME_PREVIEW = gql`
  query resumePreview($id: ID!) {
    result: resumePreview(id: $id) {
      ...ResumeFragment
    }
  }
  ${RESUME_FRAGMENT}
`;
