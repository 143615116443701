import { useMutation } from "@apollo/client";
import { Col, Radio, Row } from "antd";
import Button from "app/components/ui/button";
import Input from "app/components/ui/input";
import Label from "app/components/ui/label";
import { INPUT_ROW_SPACING } from "app/constants";
import { UPDATE_USER_INFOS } from "app/graphql/auth/mutations";
import { User } from "generated/graphql";
import _ from "lodash";
import { useEffect, useState } from "react";
import TextEditor from "../text-editor";

interface ProfileProps {
  data: User;
}

function Profile({ data }: ProfileProps) {
  const [formValues, setFormValues] = useState<User>();
  const [updateUserInfos] = useMutation(UPDATE_USER_INFOS);

  useEffect(() => {
    setFormValues(data);
  }, [data]);

  const handleChange = (e) => {
    e.preventDefault();

    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleProfileChange = (value) => {
    setFormValues((prev) => ({
      ...prev,
      profile: value
    }));
  };

  const handleSave = () => {
    updateUserInfos({
      variables: {
        input: _.omit(formValues, ["__typename", "id"])
      }
    }).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="p-8 bg-white rounded-xl mt-5 min-h-full shadow-sm text-[rgba(25_47_98_0.74)]">
      <div className="flex justify-between">
        <span className="text-base font-semibold">Informations personnelles</span>
        <Button onClick={handleSave}>Enrégistrer</Button>
      </div>
      <Row gutter={INPUT_ROW_SPACING}>
        <Col span={24}>
          <Label htmlFor="title">Genre</Label>
          <div className="h-[48px] rounded-sm flex items-center">
            <Radio.Group name="gender" value={formValues?.gender} onChange={handleChange}>
              <Radio value="man">Homme</Radio>
              <Radio value="woman">Femme</Radio>
            </Radio.Group>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <Label htmlFor="firstname">Prénom</Label>
          <Input
            value={formValues?.firstname}
            id="firstname"
            placeholder="Ex: Paul"
            name="firstname"
            onChange={handleChange}
          />
        </Col>

        <Col xs={24} md={12}>
          <Label htmlFor="lastname">Nom</Label>
          <Input
            id="lastname"
            value={formValues?.lastname}
            placeholder="Ex: Martin"
            name="lastname"
            onChange={handleChange}
          />
        </Col>

        <Col xs={24} md={12}>
          <Label htmlFor="email">E-mail</Label>
          <Input
            id="email"
            value={formValues?.email}
            placeholder="Ex: paulmartin@email.com"
            name="email"
            type="email"
            onChange={handleChange}
            disabled
          />
        </Col>

        <Col xs={24} md={12}>
          <Label htmlFor="phone">Téléphone</Label>
          <Input
            id="phone"
            value={formValues?.phone}
            placeholder="Ex: +221 XX XXX XX XX"
            name="phone"
            onChange={handleChange}
          />
        </Col>

        <Col span={24}>
          <Label htmlFor="address">Profil</Label>
          <TextEditor value={formValues?.profile} onChange={handleProfileChange} />
        </Col>
      </Row>
    </div>
  );
}

export default Profile;
