import Button from "app/components/ui/button";
import Loader from "app/components/ui/loader";
import { useStore } from "app/contexts/store-context";
import useTemplate from "app/hooks/useTemplate";
import React from "react";
import Page from "./page";
import { StyledPreviewWrapper } from "./styles";

const Preview = (): JSX.Element => {
  const {
    state: { resume, isSaved }
  } = useStore();
  const Template = useTemplate(resume.template);

  return (
    <StyledPreviewWrapper id="preview-wrapper">
      {/* <Button>{isSaved ? "Sauvegardé" : <Loader color="primary" />}</Button> */}
      <Page>
        <React.Suspense
          fallback={
            <div className="flex items-center justify-center h-full p-4 bg-[#FDFEFF]">
              <Loader color="primary" />
            </div>
          }
        >
          {Template &&
            React.cloneElement(<Template />, {
              resume,
              sections: resume.sections?.reduce((prev, curr) => ({ ...prev, [curr.name]: curr }), {}),
              theme: {},
              settings: resume?.settings || resume?.template?.settings
            })}
        </React.Suspense>
      </Page>
    </StyledPreviewWrapper>
  );
};

export default Preview;
