import { RouteComponentProps } from "@reach/router";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Button from "app/components/ui/button";

import { useStore } from "app/contexts/store-context";
import useConfirmDownload from "app/hooks/use-download";
import { CheckMarkIcon, EyeIcon, SaveIcon } from "app/icons";
import TextEditor from "app/main/text-editor";
import { previewDrawerAtom } from "app/store/template";
import { getResumeTitle } from "app/utils";
import { useAtom } from "jotai";
import { FormEvent } from "react";

const Review = (props: RouteComponentProps): JSX.Element => {
  const {
    state: { resume },
    dispatch
  } = useStore();
  const { downloadResume } = useConfirmDownload();
  const [overviewVisible, showPreviewDrawer] = useAtom(previewDrawerAtom);

  const handleEdition = () => {
    // onChange(inputRef.current.input.value);
    // setEditionMode(false);
  };

  const handleDownload = () => {
    downloadResume(resume);
  };

  const handlePreview = () => {
    showPreviewDrawer(true);
  };

  return (
    <div className="space-y-10">
      <h4 className="text-xl text-center ">Votre CV est prêt, vous pouvez le prévisualiser ou le télécharger </h4>
      {/* <span className="font-bold">Donner un titre à votre CV</span> */}

      {/* <div className="flex gap-2">
        <Input defaultValue={getResumeTitle(resume || {})} />
        <Button className="edition !w-[100px] !h-[48px]" variant="contained" color="primary" onClick={handleEdition}>
          Valider
        </Button>
      </div> */}

      <div className="flex flex-col gap-5">
        <Button
          variant="contained"
          startIcon={<SaveIcon color="#fff" />}
          className="!h-[48px] !pl-8 !pr-8"
          onClick={handleDownload}
        >
          <span> Télécharger le CV</span>
        </Button>

        <Button
          variant="outlined"
          startIcon={<EyeIcon color="var(--primary-color)" />}
          className="!h-[48px] !pl-8 !pr-8"
          onClick={handlePreview}
        >
          <span> Prévisualiser le CV</span>
        </Button>
      </div>
    </div>
  );
};

export default Review;
