import { RouteComponentProps } from "@reach/router";
import { PlusIcon, TrashIcon } from "app/icons";
import { SytledAddingButton } from "app/components/ui/progress";
import { StrengthInput } from "generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "app/contexts/store-context";
import { StyledSkillItem } from "app/components/ui/skills/styles";
import Label from "app/components/ui/label";
import Input from "app/components/ui/input";

import { Button, Col, Row } from "antd";

function Strength(props: RouteComponentProps) {
  const {
    state: {
      resume: { strengths, ...rest }
    },
    dispatch
  } = useStore();

  const handleNew = () => {
    const newEntry: StrengthInput = {
      uid: uuidv4(),
      name: ""
    };
    dispatch({
      type: "resume",
      payload: { ...rest, strengths: [...strengths, newEntry] }
    });
  };

  const handleChange = (uid, value) => {
    if (uid !== null) {
      const foundEntryIndex = strengths.findIndex((item) => item.uid === uid);

      const oldData = [...strengths];
      oldData[foundEntryIndex] = { ...oldData[foundEntryIndex], name: value };

      dispatch({
        type: "resume",
        payload: { ...rest, strengths: [...oldData] }
      });
    }
  };

  const handleDeletion = (uid) => {
    if (uid !== null) {
      dispatch({
        type: "resume",
        payload: { ...rest, strengths: [...strengths.filter((item) => item.uid !== uid)] }
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-4">
        {strengths?.map(({ name, uid }) => (
          <StyledSkillItem key={uid} className="">
            <Row className="w-full" gutter={[10, 0]}>
              <Col span={23}>
                <Label>Qualité</Label>
                <Input
                  name="jobTitle"
                  value={name}
                  onChange={(e) => {
                    handleChange(uid, e.target.value);
                  }}
                />
              </Col>

              <Col span={1}>
                <div className="flex items-center h-full">
                  <Button
                    className="close"
                    onClick={() => {
                      handleDeletion(uid);
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </Col>
            </Row>
            {/* <div className="flex-col !items-start w-full">
              <Label>Qualité</Label>
              <Input name="jobTitle" value={strength.name} onChange={handleInputChange} />
            </div>
            <div className=" basis-6">
              <Button className="close" onClick={handleDeletion}>
                <TrashIcon />
              </Button>
            </div> */}
          </StyledSkillItem>
        ))}
      </div>
      <div>
        <SytledAddingButton className=" border-primary" icon={<PlusIcon color="#fff" />} onClick={handleNew}>
          Ajouter une qualité
        </SytledAddingButton>
      </div>
    </div>
  );
}

export default Strength;
