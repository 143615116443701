/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { capitalize } from "app/utils";
import clsx from "clsx";
import React from "react";
import styled from "styled-components";

export type ColorType = "default" | "primary" | "secondary";
type VariantType = "contained" | "outlined" | "text";

const StyledButton = styled.button<{ variant?: VariantType; color?: ColorType; block?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  gap: 5px;
  padding: 8px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: ${(props) => (props.block ? "100%" : "auto")};
  align-self: center;

  :hover {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
  }

  :focus {
    background-color: ${(props) => props.color};
  }

  &.text {
    background-color: transparent;
    color: #fff;

    &.textPrimary {
      color: var(--primary-color);

      :hover {
        background-color: rgba(80, 42, 205, 0.05);
      }
    }

    &.textSecondary {
      color: #fff;

      :hover {
        background-color: rgba(101, 218, 255, 0.3);
      }
    }
  }

  &.outlined {
    &.outlinedDefault {
      border-color: rgba(86, 87, 88, 0.16);
      color: #000;

      :hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    &.outlinedPrimary {
      border-color: #502acd;
      color: var(--primary-color);

      :hover {
        background-color: rgba(80, 42, 205, 0.04);
      }
    }
  }

  &.contained {
    border-color: #502acd;

    &.containedPrimary {
      background-color: var(--primary-color);

      :hover {
        background-color: #2c1593;
      }

      &.disabled {
        background-color: #2c1593;
      }
    }

    &.containedSecondary {
      background-color: var(--secondary-color);

      :hover {
        background-color: #49addb;
      }
    }
  }
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  block?: boolean;
  loading?: boolean;
  color?: ColorType;
  type?: "button" | "submit";
  variant?: VariantType;
}

const Button = ({
  startIcon,
  endIcon,
  children,
  disabled,
  color,
  variant,
  block,
  className,
  loading,
  ...rest
}: ButtonProps) => (
  <StyledButton
    block={block}
    variant={variant}
    color={color}
    className={clsx(className, {
      [variant]: !!variant,
      [color]: !!color,
      [`${variant}${capitalize(color)}`]: !!color,
      disabled
    })}
    {...rest}
    disabled={disabled}
  >
    {startIcon}
    {children}
    {endIcon}
  </StyledButton>
);

Button.defaultProps = {
  color: "primary",
  variant: "text",
  type: "button",
  loading: false,
  block: false
};

export default Button;
