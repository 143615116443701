import { ApolloProvider } from "@apollo/client";
import { LocationProvider, Router } from "@reach/router";
import { ConfigProvider } from "antd";
import AuthRoutes, { PrivateRoute as PrivateRoutes } from "./auth";
import Logout from "./auth/containers/Logout";
import { authManager } from "./auth/utils";
import apolloClient from "./config/apollo";
import { StoreProvider } from "./contexts/store-context";
import { UserProvider } from "./contexts/user-context";
import Main from "./main";
import PublicPreview from "./main/public-preview";
import SubscriptionError from "./main/subscriptions/error";
import SubscriptionSuccess from "./main/subscriptions/success";
import GlobalStyle from "./styles";

function App(): JSX.Element {
  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#502acd"
          }
        }}
      >
        <LocationProvider>
          <GlobalStyle />
          <StoreProvider>
            <UserProvider>
              <Router>
                <Logout path="logout" />
                <PublicPreview path="preview/:id" />
                <SubscriptionError path="subscriptions/error" />
                <SubscriptionSuccess path="subscriptions/success" />
                {authManager.readToken() ? <PrivateRoutes as={Main} path="/*" /> : <AuthRoutes path="/*" />}
              </Router>
            </UserProvider>
          </StoreProvider>
        </LocationProvider>
      </ConfigProvider>
    </ApolloProvider>
  );
}

export default App;
