import { Col, Row } from "antd";
import Input from "app/components/ui/input";
import Label from "app/components/ui/label";
import ProgressDate from "app/components/ui/progress/progress-date";
import { ProgressEntryProps } from "app/components/ui/progress/types";
import { Certificate } from "generated/graphql";
import { ChangeEvent } from "react";

export const CertificateInputs = ({ progression, index, onChange }: ProgressEntryProps<Certificate>): JSX.Element => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    onChange(name, value, index);
  };

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <Label>Certificat</Label>
        <Input value={progression.name} name="name" onChange={handleInputChange} />
      </Col>

      <Col xs={24} md={12}>
        <Label>Organisation émettrice</Label>
        <Input name="organization" value={progression.organization} onChange={handleInputChange} />
      </Col>

      <Col xs={24} md={12}>
        <Label>Lien URL</Label>
        <Input name="link" value={progression.link} onChange={handleInputChange} />
      </Col>

      <Col xs={24} md={12}>
        <Label>Date d&apos;émission</Label>
        <ProgressDate
          key={1}
          value={progression.issueDate}
          onChange={(value) => {
            onChange("issueDate", value, index);
          }}
        />
      </Col>
    </Row>
  );
};

export default CertificateInputs;
