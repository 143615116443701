import { User } from "generated/graphql";
import React, { createContext, useContext, useMemo, useState } from "react";

export interface StoreProviderProps {
  children: React.ReactNode;
}

export const UserContext = createContext<
  { user: User; setUser: React.Dispatch<React.SetStateAction<User>> } | undefined
>(undefined);

UserContext.displayName = "StoreContext";

export const UserProvider = ({ children }: StoreProviderProps) => {
  const [user, setUser] = useState<User>(null);

  const contextValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
