/* eslint-disable no-nested-ternary */
import { useMutation } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import { Modal } from "antd";
import Button from "app/components/ui/button";
import CircularLoader from "app/components/ui/loader/circular";
import { useStore } from "app/contexts/store-context";
import { DELETE_RESUME_PICTURE } from "app/graphql/resumes/mutations";
import { RESUME } from "app/graphql/resumes/queries";
import { AvatarIcon, TrashIcon } from "app/icons";
import { pictureCacheTimeAtom, pictureUploadingAtom } from "app/store";
import { ProfilePicture } from "generated/graphql";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import PictureUpload from "./picture-upload";

interface PictureUploadProps extends RouteComponentProps {
  picture: ProfilePicture;
}

const PictureUploadButton = ({ picture }: PictureUploadProps): JSX.Element => {
  const [shouldShowUploadModal, toggleUploadModal] = useState(false);
  const [deleteResumePicture] = useMutation(DELETE_RESUME_PICTURE);
  const pictureTime = useAtomValue(pictureCacheTimeAtom);

  const {
    state: {
      resume: { ...rest }
    }
  } = useStore();
  const [isPictureUploading, toggleUploading] = useAtom(pictureUploadingAtom);

  const displayUploadModal = () => {
    toggleUploadModal(!shouldShowUploadModal);
  };

  const handlePictureDeletion = () => {
    toggleUploading(true);
    deleteResumePicture({
      variables: { id: rest.id },
      refetchQueries: [
        {
          query: RESUME,
          variables: {
            id: rest.id
          }
        }
      ]
    })
      .then(() => {
        toggleUploading(false);
      })
      .catch(() => {
        toggleUploading(false);
      });
  };

  const handlePictureDeletionConfirmation = (e) => {
    e.stopPropagation();

    Modal.confirm({
      title: "Voulez-vous supprimer cette photo ?",
      cancelText: "Non",
      okText: "Oui",
      onOk: () => {
        handlePictureDeletion();
      }
    });
  };

  return (
    <>
      <div
        className="border rounded shadow-sm p-2 w-full flex items-center gap-2 cursor-pointer  justify-between"
        onClick={displayUploadModal}
        aria-hidden="true"
      >
        <div className="flex items-center gap-2">
          <div className="flex items-center justify-center w-[60px] h-[60px] bg-black/5 rounded-full border-[0.2px]">
            {isPictureUploading ? (
              <CircularLoader />
            ) : picture?.croppedUrl ? (
              <img alt="Avatar" src={`${picture?.croppedUrl}&t=${pictureTime}`} className="w-full rounded-full" />
            ) : (
              <AvatarIcon className="w-6 " />
            )}
          </div>
          <span className="font-semibold"> {picture ? "Changez de photo" : " Ajoutez votre photo"}</span>
        </div>
        {picture?.croppedUrl && (
          <Button onClick={handlePictureDeletionConfirmation} className="self-end">
            <TrashIcon />
          </Button>
        )}
      </div>
      <PictureUpload visible={shouldShowUploadModal} onClose={displayUploadModal} picture={picture} />
    </>
  );
};

export default PictureUploadButton;
