import { RouteComponentProps, Router } from "@reach/router";
import { EditorLayout } from "app/components/ui/layout";
import useResize from "app/hooks/useResize";
import EditionSteps from "../steps";
import EditionDrawerSteps from "../steps/drawer";
import Preview from "../preview";
import { StyledEditorWrapper } from "../styles";
import AwardSection from "./awards";
import Certificates from "./certificates";
import Education from "./education";
import Experiences from "./experiences";
import Interests from "./interests";
import Languages from "./languages";
import Navigation from "./navigation";
import Overview from "./overview";
import Presentation from "./presentation";
import Profile from "./profile";
import ProjectSection from "./projects";
import References from "./references";
import SectionHeader from "./section-header";
import Skills from "./skills";
import Strength from "./strength";
import { StyledBody, StyledFormWrapper } from "./styles";
import Review from "./review";

const EditorForm = (props: RouteComponentProps): JSX.Element => {
  const { width } = useResize();

  return (
    <EditorLayout>
      <StyledEditorWrapper>
        <div className="body-absolute">
          <div className="body">
            <StyledFormWrapper className="w-full lg:w-[55%]">
              <StyledBody>
                <EditionSteps />
                <EditionDrawerSteps />
                <div className="content">
                  <div className="header">
                    <SectionHeader />
                  </div>
                  <div className="body">
                    <Router style={{ width: "100%" }}>
                      <Presentation path="presentation" default />
                      <Profile path="profile" />
                      <Experiences path="experiences" />
                      <Education path="educations" />
                      <Skills path="skills" />
                      <Languages path="languages" />
                      <References path="references" />
                      <Certificates path="certificates" />
                      <Interests path="interests" />
                      <Overview path="overview" />
                      <Strength path="strengths" />
                      <AwardSection path="awards" />
                      <ProjectSection path="projects" />
                      <Review path="review" />
                    </Router>
                  </div>
                  <div className="p-2">
                    <Navigation />
                  </div>
                </div>
              </StyledBody>
            </StyledFormWrapper>
            {width >= 1024 && <Preview />}
          </div>
        </div>
      </StyledEditorWrapper>
    </EditorLayout>
  );
};

export default EditorForm;
