import { CheckMarkIcon } from "app/icons";
import clsx from "clsx";
import { Template } from "generated/graphql";
import { StyledTemplateItem } from "./style";

interface TemplateItemProps {
  item: Template;
  isSelected: boolean;
  onSelect: (selectedItem: Template) => void;
}

const TemplateItem = ({ item, isSelected, onSelect }: TemplateItemProps) => {
  const handleSelection = () => {
    onSelect(item);
  };

  return (
    <StyledTemplateItem
      onClick={handleSelection}
      aria-hidden
      className={clsx(
        "relative cursor-pointer mb-3 rounded border overflow-hidden hover:after:content-[''] hover:after:absolute hover:after:top-0 hover:after:w-full hover:after:h-full hover:after:bg-black/5 ",
        {
          selected: isSelected
        }
      )}
    >
      <img src={item.preview} alt="" />
      <span className="highlight-mark bg-primary h-[32px] w-[32px] absolute rounded-full items-center justify-center">
        <CheckMarkIcon />
      </span>
    </StyledTemplateItem>
  );
};

export default TemplateItem;
