import { IconProps } from "app/types";
import clsx from "clsx";

export const DashboardIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.5 9c.276 0 .5.224.5.5v6c0 .276-.224.5-.5.5h-6c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5h6zm9 0c.276 0 .5.224.5.5v6c0 .276-.224.5-.5.5h-6c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5h6zM6 10H1v5h5v-5zm9 0h-5v5h5v-5zM6.5 0c.276 0 .5.224.5.5v6c0 .276-.224.5-.5.5h-6C.224 7 0 6.776 0 6.5v-6C0 .224.224 0 .5 0h6zm9 0c.276 0 .5.224.5.5v6c0 .276-.224.5-.5.5h-6c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5h6zM6 1H1v5h5V1zm9 0h-5v5h5V1z"
      clipRule="evenodd"
    />
  </svg>
);

DashboardIcon.defaultProps = {
  color: "#fff",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const DocumentIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6 3.91667C5.26863 3.91667 5 4.17783 5 4.5C5 4.82217 5.26863 5.08333 5.6 5.08333H10.4C10.7314 5.08333 11 4.82217 11 4.5C11 4.17783 10.7314 3.91667 10.4 3.91667H5.6Z"
      fill={color}
    />
    <path
      d="M5 8C5 7.67783 5.26863 7.41667 5.6 7.41667H10.4C10.7314 7.41667 11 7.67783 11 8C11 8.32217 10.7314 8.58333 10.4 8.58333H5.6C5.26863 8.58333 5 8.32217 5 8Z"
      fill={color}
    />
    <path
      d="M5.6 10.9167C5.26863 10.9167 5 11.1778 5 11.5C5 11.8222 5.26863 12.0833 5.6 12.0833H10.4C10.7314 12.0833 11 11.8222 11 11.5C11 11.1778 10.7314 10.9167 10.4 10.9167H5.6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6 1C2.26863 1 2 1.26117 2 1.58333V14.4167C2 14.7388 2.26863 15 2.6 15H13.4C13.7314 15 14 14.7388 14 14.4167V1.58333C14 1.26117 13.7314 1 13.4 1H2.6ZM3.2 13.8333V2.16667H12.8V13.8333H3.2Z"
      fill={color}
    />
  </svg>
);

DocumentIcon.defaultProps = {
  color: "#fff",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const UserIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.361 11.895c.4.281.64.74.639 1.23v2.523c0 .277-.224.5-.5.5h-13c-.276 0-.5-.223-.5-.5v-2.523c0-.49.238-.949.654-1.24 1.891-1.225 4.116-1.832 6.325-1.736 2.25-.095 4.476.511 6.382 1.746zm-6.382-.747c-2.044-.087-4.064.464-5.766 1.566-.134.094-.213.247-.213.41v2.024h12v-2.024c0-.163-.08-.316-.198-.4-1.717-1.112-3.737-1.663-5.823-1.576zM8 .148c2.21 0 4 1.791 4 4 0 2.21-1.79 4-4 4s-4-1.79-4-4c0-2.209 1.79-4 4-4zm0 1c-1.657 0-3 1.344-3 3 0 1.657 1.343 3 3 3s3-1.343 3-3c0-1.656-1.343-3-3-3z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H16V16H0z" transform="translate(0 0.148438)" />
      </clipPath>
    </defs>
  </svg>
);

UserIcon.defaultProps = {
  color: "#fff",
  height: "16",
  width: "17"
};

export const TrashIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
    />
  </svg>
);

TrashIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const CheckMarkIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
  </svg>
);

export const ArrowRightIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fillRule="evenodd"
      d="M10.146 12.867c-.195-.178-.195-.465 0-.643L14.243 8.5H.469C.209 8.5 0 8.276 0 8s.21-.5.469-.5h13.774l-4.097-3.724c-.195-.178-.195-.465 0-.643.196-.177.512-.177.708 0l5 4.546c.195.177.195.465 0 .642l-5 4.546c-.196.177-.512.177-.708 0z"
      clipRule="evenodd"
    />
  </svg>
);

ArrowRightIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const ArrowLeftIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.85355 3.13313C6.04882 3.31064 6.04882 3.59845 5.85355 3.77596L1.757 7.5H15.5312C15.7901 7.5 16 7.72386 16 8C16 8.27614 15.7901 8.5 15.5312 8.5H1.757L5.85355 12.224C6.04882 12.4016 6.04882 12.6894 5.85355 12.8669C5.65829 13.0444 5.34171 13.0444 5.14645 12.8669L0.146447 8.32141C-0.0488155 8.1439 -0.0488155 7.8561 0.146447 7.67859L5.14645 3.13313C5.34171 2.95562 5.65829 2.95562 5.85355 3.13313Z"
      fill={color}
    />
  </svg>
);

ArrowLeftIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const AddCircleFilledIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8C0 3.6 3.6 0 8 0ZM8 2.54197C7.7349 2.54197 7.52 2.75687 7.52 3.02197L7.51987 7.51987L3.02197 7.52C2.75687 7.52 2.54197 7.7349 2.54197 8C2.54197 8.2651 2.75687 8.48 3.02197 8.48L7.51987 8.48013L7.52 12.978C7.52 13.2431 7.7349 13.458 8 13.458C8.2651 13.458 8.48 13.2431 8.48 12.978L8.48013 8.48013L12.978 8.48C13.2431 8.48 13.458 8.2651 13.458 8C13.458 7.7349 13.2431 7.52 12.978 7.52L8.48013 7.51987L8.48 3.02197C8.48 2.75687 8.2651 2.54197 8 2.54197Z"
      fill={color}
    />
  </svg>
);

AddCircleFilledIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const EditionIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
    />
  </svg>
);

EditionIcon.defaultProps = {
  color: "#19212C",
  height: "24",
  width: "24",
  className: "w-6 h-6"
};

export const ArtIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.37679 0.64244C6.71974 1.1167 6.75402 1.64048 6.60207 2.29886C6.57461 2.41784 6.54661 2.52232 6.49521 2.70407C6.33267 3.27876 6.31531 3.455 6.36845 3.53077C6.7286 4.00208 7.08422 3.96577 8.87139 3.37395C10.8837 2.70756 12.1925 2.6113 13.7205 3.38636L13.728 3.39029C15.9269 4.55466 16.3517 7.27092 15.7546 9.8156C14.759 13.4869 11.6091 16.0096 8.04011 15.9994C4.54826 16.0427 1.43908 13.6445 0.376992 10.0887C-0.685091 6.53289 0.54878 2.65261 3.4253 0.519825C4.39134 -0.170007 5.7596 -0.216477 6.37679 0.64244ZM3.98674 1.4013C1.47763 3.26175 0.398651 6.65492 1.32741 9.76436C2.25616 12.8738 4.97504 14.9709 8.03565 14.9331C11.1667 14.9419 13.9247 12.7332 14.7916 9.53752C15.2826 7.44366 14.936 5.22314 13.2889 4.34785C12.0459 3.7186 10.9591 3.79899 9.16664 4.39258C6.919 5.1369 6.35184 5.19479 5.58416 4.18959C5.23968 3.70246 5.2766 3.32754 5.54022 2.39548C5.58689 2.23045 5.61126 2.1395 5.63343 2.04345C5.71976 1.66942 5.70595 1.45852 5.58649 1.29331C5.35078 0.965285 4.55985 0.992148 3.98674 1.4013ZM5.99707 10.1342C6.54807 10.1342 6.99475 10.6117 6.99475 11.2007C6.99475 11.7896 6.54807 12.2671 5.99707 12.2671C5.44607 12.2671 4.99939 11.7896 4.99939 11.2007C4.99939 10.6117 5.44607 10.1342 5.99707 10.1342ZM10.4866 5.86864C11.8641 5.86864 12.9808 7.06225 12.9808 8.53464C12.9808 10.007 11.8641 11.2007 10.4866 11.2007C9.10912 11.2007 7.99243 10.007 7.99243 8.53464C7.99243 7.06225 9.10912 5.86864 10.4866 5.86864ZM10.4866 6.93504C9.66012 6.93504 8.9901 7.65121 8.9901 8.53464C8.9901 9.41808 9.66012 10.1342 10.4866 10.1342C11.3131 10.1342 11.9831 9.41808 11.9831 8.53464C11.9831 7.65121 11.3131 6.93504 10.4866 6.93504ZM4.00171 6.93504C4.55272 6.93504 4.99939 7.41249 4.99939 8.00144C4.99939 8.5904 4.55272 9.06785 4.00171 9.06785C3.45071 9.06785 3.00403 8.5904 3.00403 8.00144C3.00403 7.41249 3.45071 6.93504 4.00171 6.93504Z"
      fill={color}
    />
  </svg>
);

ArtIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const CloseIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8594 4.14059C12.0469 4.32804 12.0469 4.63196 11.8594 4.81941L8.679 8L11.8594 11.1806C12.0469 11.368 12.0469 11.672 11.8594 11.8594C11.672 12.0469 11.368 12.0469 11.1806 11.8594L8 8.679L4.81941 11.8594C4.63196 12.0469 4.32804 12.0469 4.14059 11.8594C3.95314 11.672 3.95314 11.368 4.14059 11.1806L7.321 8L4.14059 4.81941C3.95314 4.63196 3.95314 4.32804 4.14059 4.14059C4.32804 3.95314 4.63196 3.95314 4.81941 4.14059L8 7.321L11.1806 4.14059C11.368 3.95314 11.672 3.95314 11.8594 4.14059Z"
      fill={color}
    />
  </svg>
);

CloseIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const CloseCircleFilledIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8C0 3.6 3.6 0 8 0ZM11.8594 4.14059C11.672 3.95314 11.368 3.95314 11.1806 4.14059L8 7.321L4.81941 4.14059C4.63196 3.95314 4.32804 3.95314 4.14059 4.14059C3.95314 4.32804 3.95314 4.63196 4.14059 4.81941L7.321 8L4.14059 11.1806C3.95314 11.368 3.95314 11.672 4.14059 11.8594C4.32804 12.0469 4.63196 12.0469 4.81941 11.8594L8 8.679L11.1806 11.8594C11.368 12.0469 11.672 12.0469 11.8594 11.8594C12.0469 11.672 12.0469 11.368 11.8594 11.1806L8.679 8L11.8594 4.81941C12.0469 4.63196 12.0469 4.32804 11.8594 4.14059Z"
      fill={color}
    />
  </svg>
);

CloseCircleFilledIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const ProfileIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
    />
  </svg>
);

ProfileIcon.defaultProps = {
  color: "#19212C",
  height: "35",
  width: "35"
};

export const IdentificationIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
    />
  </svg>
);

IdentificationIcon.defaultProps = {
  color: "#19212C",
  height: "35",
  width: "35"
};

export const DownloadIcon = ({ color }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke={color}
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
    />
  </svg>
);

DownloadIcon.defaultProps = {
  color: "#19212C",
  height: "35",
  width: "35"
};

export const DuplicateIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
    />
  </svg>
);

DuplicateIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const LanguagesIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
    />
  </svg>
);

export const SkillsIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
    />
  </svg>
);

export const CertificateIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
    />
  </svg>
);

export const ReferenceIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
    />
  </svg>
);

export const HobbyIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
    />
  </svg>
);

export const WorkIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
    />
  </svg>
);

export const EducationIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
    />
  </svg>
);

export const Education1Icon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.125 1.833v.633a3.246 3.246 0 0 0-1.196-.23c-.82 0-1.641.312-2.269.94l1.526 1.526h.509v.51c.394.393.908.6 1.425.623v1.04H2.75V8.25a.92.92 0 0 0 .917.917H8.25c.76 0 1.375-.615 1.375-1.375V1.833h-5.5zm-.509 2.938v-.985H2.571l-.476-.477c.26-.1.545-.156.834-.156.614 0 1.187.239 1.622.67l.646.646-.091.091a1.238 1.238 0 0 1-.88.367c-.216 0-.426-.055-.61-.156zm5.092 3.02a.46.46 0 0 1-.458.459.46.46 0 0 1-.458-.458v-.917h-2.75V5.688c.26-.106.504-.261.715-.472l.091-.092 1.297 1.293h.647V5.77l-2.75-2.736V2.75h3.666v5.042z"
      fill={color}
    />
  </svg>
);

Education1Icon.defaultProps = {
  color: "#fff",
  height: "11",
  width: "11"
};

export const FullScreenIcon = (): JSX.Element => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
  </svg>
);

export const WorkOutlineIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.417 2.75v-.917H4.583v.917h1.834zm-4.584.917v5.041h7.334V3.667H1.833zm7.334-.917c.508 0 .916.408.916.917v5.041a.913.913 0 0 1-.916.917H1.833a.913.913 0 0 1-.916-.917L.92 3.667a.91.91 0 0 1 .912-.917h1.834v-.917c0-.508.408-.916.916-.916h1.834c.508 0 .916.408.916.916v.917h1.834z"
      fill={color}
    />
  </svg>
);

WorkOutlineIcon.defaultProps = {
  color: "#fff",
  height: "11",
  width: "11"
};

export const EyeIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C10.1293 2 12.0732 3.09656 13.7992 4.90476C14.3937 5.52758 14.9201 6.19316 15.3741 6.85896C15.5332 7.09237 15.6722 7.30956 15.7903 7.5052C15.8623 7.62444 15.9121 7.71134 15.9389 7.76057C16.0204 7.90981 16.0204 8.09019 15.9389 8.23943C15.9121 8.28866 15.8623 8.37556 15.7903 8.4948C15.6722 8.69044 15.5332 8.90763 15.3741 9.14104C14.9201 9.80684 14.3937 10.4724 13.7992 11.0952C12.0732 12.9034 10.1293 14 8 14C5.87074 14 3.92683 12.9034 2.20082 11.0952C1.60632 10.4724 1.0799 9.80684 0.625949 9.14104C0.466801 8.90763 0.327831 8.69044 0.209686 8.4948C0.137678 8.37556 0.0879066 8.28866 0.0610522 8.23943C-0.0203507 8.09019 -0.0203507 7.90981 0.0610522 7.76057C0.0879066 7.71134 0.137678 7.62444 0.209686 7.5052C0.327831 7.30956 0.466801 7.09237 0.625949 6.85896C1.0799 6.19316 1.60632 5.52758 2.20082 4.90476C3.92683 3.09656 5.87074 2 8 2ZM8 3C6.19176 3 4.47942 3.96594 2.92418 5.59524C2.37025 6.17555 1.87713 6.79903 1.45218 7.42229C1.29679 7.65019 1.17161 7.84608 1.07811 8C1.17161 8.15392 1.29679 8.34981 1.45218 8.57771C1.87713 9.20097 2.37025 9.82445 2.92418 10.4048C4.47942 12.0341 6.19176 13 8 13C9.80824 13 11.5206 12.0341 13.0758 10.4048C13.6298 9.82445 14.1229 9.20097 14.5478 8.57771C14.7032 8.34981 14.8284 8.15392 14.9219 8C14.8284 7.84608 14.7032 7.65019 14.5478 7.42229C14.1229 6.79903 13.6298 6.17555 13.0758 5.59524C11.5206 3.96594 9.80824 3 8 3ZM8 5C9.65685 5 11 6.34315 11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

EyeIcon.defaultProps = {
  color: "#fff",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const EyeSlashIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
    />
  </svg>
);

EyeSlashIcon.defaultProps = {
  color: "#fff",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const GridIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 9C6.77614 9 7 9.22386 7 9.5V15.5C7 15.7761 6.77614 16 6.5 16H0.5C0.223858 16 0 15.7761 0 15.5V9.5C0 9.22386 0.223858 9 0.5 9H6.5ZM15.5 9C15.7761 9 16 9.22386 16 9.5V15.5C16 15.7761 15.7761 16 15.5 16H9.5C9.22386 16 9 15.7761 9 15.5V9.5C9 9.22386 9.22386 9 9.5 9H15.5ZM6 10H1V15H6V10ZM15 10H10V15H15V10ZM6.5 0C6.77614 0 7 0.223858 7 0.5V6.5C7 6.77614 6.77614 7 6.5 7H0.5C0.223858 7 0 6.77614 0 6.5V0.5C0 0.223858 0.223858 0 0.5 0H6.5ZM15.5 0C15.7761 0 16 0.223858 16 0.5V6.5C16 6.77614 15.7761 7 15.5 7H9.5C9.22386 7 9 6.77614 9 6.5V0.5C9 0.223858 9.22386 0 9.5 0H15.5ZM6 1H1V6H6V1ZM15 1H10V6H15V1Z"
      fill={color}
    />
  </svg>
);

GridIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const SaveIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
    />
  </svg>
);

SaveIcon.defaultProps = {
  color: "#121417",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const MoreIcon = ({ width, height }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="courier"
    fill="none"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      fill="#19212C"
      fillRule="evenodd"
      d="M8 6c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zM2 6c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm12 0c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
      clipRule="evenodd"
    />
  </svg>
);

MoreIcon.defaultProps = {
  color: "#19212C",
  height: "16",
  width: "16",
  className: "w-6 h-6"
};

export const DotsVerticalIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
    />
  </svg>
);

export const GoogleIcon = (): JSX.Element => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9902 9.67772C18.9902 8.8993 18.9255 8.33126 18.7857 7.74219H9.68945V11.2556H15.0287C14.9211 12.1287 14.3398 13.4436 13.048 14.3272L13.0299 14.4448L15.906 16.6221L16.1052 16.6415C17.9352 14.99 18.9902 12.56 18.9902 9.67772Z"
      fill="#4285F4"
    />
    <path
      d="M9.68906 18.9345C12.3048 18.9345 14.5008 18.0929 16.1048 16.6413L13.0476 14.327C12.2295 14.8846 11.1315 15.2738 9.68906 15.2738C7.12706 15.2738 4.9526 13.6223 4.17747 11.3396L4.06385 11.349L1.07329 13.6107L1.03418 13.7169C2.62734 16.8096 5.89983 18.9345 9.68906 18.9345Z"
      fill="#34A853"
    />
    <path
      d="M4.17765 11.3397C3.97313 10.7506 3.85476 10.1194 3.85476 9.46727C3.85476 8.81504 3.97313 8.1839 4.16689 7.59483L4.16147 7.46937L1.13344 5.17139L1.03437 5.21744C0.377746 6.50081 0.000976562 7.94198 0.000976562 9.46727C0.000976562 10.9926 0.377746 12.4337 1.03437 13.717L4.17765 11.3397Z"
      fill="#FBBC05"
    />
    <path
      d="M9.68905 3.66069C11.5083 3.66069 12.7354 4.4286 13.4352 5.07032L16.1694 2.46152C14.4901 0.936221 12.3048 0 9.68905 0C5.89983 0 2.62734 2.12488 1.03418 5.21753L4.1667 7.59492C4.9526 5.31224 7.12706 3.66069 9.68905 3.66069Z"
      fill="#EB4335"
    />
  </svg>
);

export const PlusIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg
    fill={color}
    width="20"
    height="20"
    viewBox="0 0 24 24"
    id="plus"
    data-name="Flat Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon flat-color"
    stroke={color}
    strokeWidth="0.00024000000000000003"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

    <g id="SVGRepo_iconCarrier">
      <path
        id="primary"
        d="M12,20a1,1,0,0,1-1-1V13H5a1,1,0,0,1,0-2h6V5a1,1,0,0,1,2,0v6h6a1,1,0,0,1,0,2H13v6A1,1,0,0,1,12,20Z"
        // style="fill: #000000;"
      />
    </g>
  </svg>
);

export const PlusSmallIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-4 h-4"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
  </svg>
);

export const CloseRoundIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const IdeaIcon = ({ color, height, width }: IconProps): JSX.Element => (
  <svg width="20px" height="20px" viewBox="-8 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g id="Group_27" data-name="Group 27" transform="translate(-509 -89)">
      <path id="Path_31" data-name="Path 31" d="M520,113.2l4.167-8.2H520l10.333-9.8-4.5,7.8H530Z" fill="#7d50f9" />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M541,105a16,16,0,1,0-23,14.383V125a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2h1v1a2,2,0,0,0,2,2h1v1a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2v-1h1a2,2,0,0,0,2-2v-1h1a2,2,0,0,0,2-2v-2a2,2,0,0,0-2-2v-5.617A16,16,0,0,0,541,105Zm-15,30h-2v-1h2Zm3-3h-8v-1h8Zm3-3H518v-2h14Zm-1.4-11.172-.6.261V125h-4v-6h-2v6h-4v-6.911l-.6-.261a14,14,0,1,1,11.2,0Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export const UsersIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("stroke", className)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
    />
  </svg>
);

export const CalendarDaysIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("stroke", className)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
    />
  </svg>
);

export const TrcukIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("stroke", className)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
    />
  </svg>
);

export const GlobeIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("stroke", className)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525"
    />
  </svg>
);

export const PhoneIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("stroke", className)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
);

export const MapIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("stroke", className)}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    />
  </svg>
);

export const EnvelopeIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("stroke", className)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
    />
  </svg>
);

export const InternetIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={clsx("fill", className)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
    />
  </svg>
);

export const AvatarIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);

export const StartIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
    />
  </svg>
);

export const TrophyIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
    />
  </svg>
);

export const HeartIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
    />
  </svg>
);

export const LinkIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
    />
  </svg>
);

LinkIcon.defaultProps = {
  className: "w-6 h-6"
};

export const LinkArrowIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
    />
  </svg>
);

LinkArrowIcon.defaultProps = {
  className: "w-6 h-6"
};

export const ToolsIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    // className={"w-6 h-6"}
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
    />
  </svg>
);

export const DollarIcon = ({ color, height, width, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);
