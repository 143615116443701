import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps, Router } from "@reach/router";
import { StoreProviderState, useStore } from "app/contexts/store-context";
import { UPDATE_RESUME } from "app/graphql/resumes/mutations";
import { RESUME } from "app/graphql/resumes/queries";
import { useDebounce } from "app/hooks/useDebounce";
import _ from "lodash";
import { useEffect } from "react";
import TemplateSelection from "../../template-selection";
import Customization from "./customization";
import EditorForm from "./form";

interface EditorProps extends RouteComponentProps {
  id?: string;
}
const ResumeEditor = ({ id }: EditorProps): JSX.Element => {
  const { data } = useQuery(RESUME, { variables: { id } });
  // const [getResume, { data }] = useLazyQuery(RESUME);
  const [updateResume] = useMutation(UPDATE_RESUME);

  const { dispatch, state } = useStore();
  const debouncedState: StoreProviderState = useDebounce(state, 3000);

  // useEffect(() => {
  //   getResume({ variables: { id } });
  // }, []);

  useEffect(() => {
    if (!debouncedState.isSaved) {
      updateResume({
        variables: {
          id,
          input: {
            title: debouncedState.resume.title,
            profile: debouncedState.resume.profile,
            interests: debouncedState.resume.interests,
            personalInfo: _.omit(debouncedState.resume.personalInfo, "__typename"),
            experiences: debouncedState.resume.experiences?.map((item) => _.omit(item, "__typename")),
            educations: debouncedState.resume.educations?.map((item) => _.omit(item, "__typename")),
            references: debouncedState.resume.references?.map((item) => _.omit(item, "__typename")),
            languages: debouncedState.resume.languages?.map((item) => _.omit(item, "__typename")),
            certificates: debouncedState.resume.certificates?.map((item) => _.omit(item, "__typename")),
            strengths: debouncedState.resume.strengths?.map((item) => _.omit(item, "__typename")),
            sections: debouncedState.resume.sections?.map((item) => _.omit(item, "__typename")),
            awards: debouncedState.resume.awards?.map((item) => _.omit(item, "__typename")),
            projects: debouncedState.resume.projects?.map((item) => _.omit(item, "__typename")),
            skills: debouncedState.resume.skills?.map(({ __typename, ...rest }) => ({
              ...rest,
              skills: rest.skills?.map((item) => _.omit(item, "__typename"))
            })),
            settings: {
              ..._.omit(debouncedState.resume.settings, "__typename"),
              colors: _.omit(debouncedState.resume.settings.colors, "__typename"),
              picture: _.omit(debouncedState.resume.settings.picture, "__typename")
            }
          }
        }
      }).then(() => {
        dispatch({ type: "toggle-draft" });
      });
    }
  }, [debouncedState]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "init-resume", payload: data.resume });
    }
  }, [data]);

  return (
    <>
      <Router>
        <EditorForm path="/*" />
        <Customization path="appearance" />
      </Router>

      <TemplateSelection />
    </>
  );
};

ResumeEditor.defaultProps = {
  id: null
};

export default ResumeEditor;
