import { Dropdown, Input, Menu } from "antd";
import Button from "app/components/ui/button";
import { useStore } from "app/contexts/store-context";
import { CheckMarkIcon, DotsVerticalIcon, EditionIcon, EyeIcon, EyeSlashIcon, IdeaIcon } from "app/icons";
import useSection from "app/main/useSection";
import { useRef, useState } from "react";
import styled from "styled-components";
import SectionTips from "./section-tips";

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .title {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    margin-right: 12px;
  }

  .edition {
    height: 35px;
    width: 35px;
    border: 0;
  }

  input {
    height: 35px;
  }
`;

function SectionHeader() {
  const {
    state: { resume },
    dispatch
  } = useStore();
  const [shouldShowTipsDrawer, toggleTipsDrawer] = useState(false);
  const [isEditionMode, setEditionMode] = useState(false);
  const inputRef = useRef(null);
  const section = useSection();

  const handleDisplayTips = () => {
    toggleTipsDrawer(!shouldShowTipsDrawer);
  };

  const handleEditSectionTitle = () => {
    setEditionMode(true);
  };

  const handleEdition = () => {
    const { value } = inputRef.current.input;

    if (!value) return;

    const { sections } = resume;
    const foundSection = sections.findIndex((search) => search.name === section.current.name);

    if (foundSection === -1) return;

    const prevData = [...sections];

    const data = { ...prevData[foundSection] };
    data.label = value;
    prevData[foundSection] = data;

    dispatch({ type: "resume", payload: { ...resume, sections: [...prevData] } });
    setEditionMode(false);
  };

  const toggleSectionVisibility = () => {
    const { sections } = resume;

    const foundSection = sections.findIndex((search) => search.name === section.current.name);

    if (foundSection === -1) return;

    const prevData = [...sections];

    const data = { ...prevData[foundSection] };
    data.isVisible = !data.isVisible;
    prevData[foundSection] = data;

    dispatch({ type: "resume", payload: { ...resume, sections: [...prevData] } });
    setEditionMode(false);
  };

  const menu = (
    <Menu
      className=" min-w-[180px]"
      items={[
        {
          key: "1",
          label: (
            <Button
              onClick={handleEditSectionTitle}
              variant="text"
              startIcon={<EditionIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Renommer la section
            </Button>
          )
        },
        {
          key: "2",
          label: (
            <Button
              onClick={toggleSectionVisibility}
              variant="text"
              startIcon={
                section?.current?.isVisible ? (
                  <EyeSlashIcon className="w-5" color="var(--primary-color)" />
                ) : (
                  <EyeIcon className="w-5" color="var(--primary-color)" />
                )
              }
              className="!w-full !justify-start"
            >
              {section?.current?.isVisible ? "Cacher la section" : "Afficher la section"}
            </Button>
          )
        }
      ]}
    />
  );

  return (
    <StyledWrapper>
      <div className="flex gap-2">
        <StyledWrapper>
          {isEditionMode ? (
            <div className="flex gap-2">
              <Input ref={inputRef} defaultValue={section?.current?.label} />
              <Button className="edition" variant="contained" color="primary" onClick={handleEdition}>
                <CheckMarkIcon />
              </Button>
            </div>
          ) : (
            <span className="flex gap-2 items-center">
              <span className="title">{section?.current?.label}</span>
            </span>
          )}
        </StyledWrapper>
      </div>
      <div className="flex items-center gap-2">
        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
          <Button>
            <DotsVerticalIcon />
          </Button>
        </Dropdown>

        <Button onClick={handleDisplayTips} color="secondary" variant="contained" className="edition">
          <IdeaIcon />
        </Button>
      </div>

      <SectionTips visible={shouldShowTipsDrawer} onClose={handleDisplayTips} />
    </StyledWrapper>
  );
}

export default SectionHeader;
