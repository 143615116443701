enum HTTP_STATUS_CODES {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  INTERNAL_SERVER_ERROR = 500,
  UNPROCESSABLE = 422,
  CONFLICT = 409,
  FORBIDDEN = 403,
  UNAUTHORIZED = 401
}

export default HTTP_STATUS_CODES;
