import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { authManager } from "app/auth/utils";
import HTTP_STATUS_CODES from "app/constants/http-status-codes";

const isUnauthorized = (errors: GraphQLErrors) =>
  errors.some(
    ({ extensions: { code, response } }) =>
      code === "UNAUTHENTICATED" || (response as any).statusCode === HTTP_STATUS_CODES.UNAUTHORIZED
  );

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_END_POINT,
  credentials: "same-origin"
});

const authLink = setContext((_, { headers }) => {
  const token = authManager.readToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (
    window.location.pathname !== "/login" &&
    (isUnauthorized(graphQLErrors) || (networkError as any)?.statusCode === 401)
  ) {
    authManager.logout();
  }
});

const appLink = errorLink.concat(authLink.concat(httpLink));

const client = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache()
});

export default client;
