import { Link, RouteComponentProps } from "@reach/router";
import { Result } from "antd";
import Button from "app/components/ui/button";

function SubscriptionError(props: RouteComponentProps) {
  return (
    <div className="flex items-center justify-center h-screen p-10 text-center">
      <Result
        style={{ padding: 0 }}
        status="error"
        title={
          <div className="!text-2xl font-semibold">
            Ooos, il y a eu un problème lors du traitement de votre paiement.
          </div>
        }
        subTitle={
          <div className="!text-lg">
            Veuillez vérifier que les informations de paiement que vous avez fournies sont correctes et réessayer.
          </div>
        }
        extra={
          <div className="text-center">
            <Link to="/">
              <Button key="login" variant="contained" color="primary">
                Continuer
              </Button>
            </Link>
          </div>
        }
      />
    </div>
  );
}

export default SubscriptionError;
