import { useQuery } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import { Tabs } from "antd";
import MainLayout from "app/components/ui/layout";
import { CURRENT_USER } from "app/graphql/auth/queries";
import Profile from "./profile";
import Settings from "./settings";

const { TabPane } = Tabs;

function Account(props: RouteComponentProps) {
  const { data } = useQuery(CURRENT_USER);

  return (
    <MainLayout>
      <div className="p-5 lg:px-[5rem] xl:px-[15rem] 2xl:px-[25rem] py-10 min-h-screen  overflow-auto">
        <span className="text-2xl font-bold ">
          {data?.result?.firstname} {data?.result?.lastname}{" "}
        </span>
        <Tabs className="mt-5 h-full mb-5 text-inherit">
          <TabPane className="relative" tab="Profil" key="1">
            <Profile data={data?.result} />
          </TabPane>

          <TabPane className="relative" tab="Paramètres" key="2">
            <Settings data={data?.result} />
          </TabPane>
        </Tabs>
      </div>
    </MainLayout>
  );
}

export default Account;
