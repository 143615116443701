import { RouteComponentProps, Router } from "@reach/router";
import { EditorLayout } from "app/components/ui/layout";
import useResize from "app/hooks/useResize";
import Navigation from "../resume/form/navigation";
import { StyledEditorWrapper } from "../resume/styles";
import EditionSteps from "./steps";
import SectionHeader from "../resume/form/section-header";
import Preview from "../resume/preview";
import { StyledBody, StyledFormWrapper } from "../resume/form/styles";
import Contact from "./form/contact";

const EditorForm = (props: RouteComponentProps): JSX.Element => {
  const { width } = useResize();

  return (
    <EditorLayout>
      <StyledEditorWrapper>
        <div className="body-absolute">
          <div className="body">
            <StyledFormWrapper className="w-full lg:w-[55%]">
              <StyledBody>
                <EditionSteps />
                <div className="content">
                  <div className="header">
                    <SectionHeader />
                  </div>
                  <div className="body">
                    <Router style={{ width: "100%" }}>
                      <Contact path="contact" />
                    </Router>
                  </div>
                  <div className="p-2">
                    <Navigation />
                  </div>
                </div>
              </StyledBody>
            </StyledFormWrapper>
            {width >= 1024 && <Preview />}
          </div>
        </div>
      </StyledEditorWrapper>
    </EditorLayout>
  );
};

export default EditorForm;
