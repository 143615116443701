import styled from "styled-components";

export const StyledBody = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;

  .title {
    font-size: 1.6rem;
  }

  button {
    height: 40px;
  }
`;
