import { Link, RouteComponentProps } from "@reach/router";
import { Result } from "antd";
import Button from "app/components/ui/button";

function SubscriptionSuccess(props: RouteComponentProps) {
  return (
    <div className="flex items-center justify-center h-screen p-10 text-center">
      <Result
        style={{ padding: 0 }}
        status="success"
        title={<div className="!text-2xl font-semibold">Félicitations ! Votre paiement a été traité avec succès.</div>}
        subTitle={
          <div className="!text-lg">
            Vous pouvez maintenant profiter pleinement de notre plateforme et télécharger les CV professionnels de votre
            choix.
          </div>
        }
        extra={
          <div className="text-center">
            <Link to="/">
              <Button key="login" variant="contained" color="primary">
                Continuer
              </Button>
            </Link>
          </div>
        }
      />
    </div>
  );
}

export default SubscriptionSuccess;
