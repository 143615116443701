import { Input, Tooltip } from "antd";
import { CheckMarkIcon, EditionIcon } from "app/icons";
import { getResumeTitle } from "app/utils";
import { Resume } from "generated/graphql";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../button";

const StyledInput = styled.input`
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 0 2px;
  display: block;
  border-radius: 2px;
  // width: 400px;
  border: 1px solid none;

  ::placeholder {
    color: #fff;
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const StyledWrapper = styled.div`
  input {
    height: 30px;
    background-color: rgb(0 0 0 / 33%);
    color: #fff;
    border: 0;
  }

  button {
    width: 32px;

    &.edition {
      border-color: var(--secondary-color) !important;
    }
  }
  span.title {
    text-overflow: ellipsis;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
  }
`;

interface ResumeTitleInputProps {
  resume: Resume;
  onChange: (title: string) => void;
}

function ResumeTitleInput({ resume, onChange }: ResumeTitleInputProps) {
  const [isEditionMode, setEditionMode] = useState(false);
  const inputRef = useRef(null);

  const toggleEditionMode = () => {
    setEditionMode(true);
  };

  const handleEdition = () => {
    onChange(inputRef.current.input.value);
    setEditionMode(false);
  };
  return (
    <StyledWrapper>
      {isEditionMode ? (
        <div className="flex gap-2">
          <Input ref={inputRef} defaultValue={getResumeTitle(resume || {})} />
          <Button className="edition" variant="contained" color="primary" onClick={handleEdition}>
            <CheckMarkIcon />
          </Button>
        </div>
      ) : (
        <span className="flex items-center gap-2 text-white hover:underline">
          <span className="title">{getResumeTitle(resume || {})}</span>
          <Tooltip title="Modifier le titre de votre CV">
            <Button color="secondary" variant="outlined" onClick={toggleEditionMode}>
              <EditionIcon color="#fff" />
            </Button>
          </Tooltip>
        </span>
      )}
    </StyledWrapper>
  );
}

export default ResumeTitleInput;
