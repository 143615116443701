import { useMutation } from "@apollo/client";
import Drawer from "app/components/ui/drawer";
import { useStore } from "app/contexts/store-context";
import { UPDATE_RESUME } from "app/graphql/resumes/mutations";
import { templateDrawerAtom, templatesAtom } from "app/store/template";
import { Template } from "generated/graphql";
import { useAtom } from "jotai";
import TemplateItem from "./item";

function TemplateSelection() {
  const [templates] = useAtom(templatesAtom);
  const [shouldShowDrawer, hideDrawer] = useAtom(templateDrawerAtom);
  const {
    dispatch,
    state: { resume }
  } = useStore();
  const [updateResume] = useMutation(UPDATE_RESUME);

  const handleTemplateSelection = (selectedItem: Template) => {
    if (selectedItem.uid !== resume?.template.uid) {
      updateResume({
        variables: {
          id: resume.id,
          input: {
            template: selectedItem.id
          }
        }
      }).then((result) => {
        if (result?.data.result) {
          dispatch({ type: "resume", payload: result.data.result });
        }
      });
    }
    hideDrawer(false);
  };

  const handleClose = () => {
    hideDrawer(false);
  };

  return (
    <Drawer header="Modèles" width="342px" isOpen={shouldShowDrawer} onClose={handleClose}>
      {/* TODO Template searching */}
      {/* <input
      className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm mb-4"
      placeholder="Rechercher des modèles..."
      type="text"
      name="search"
    /> */}

      {templates.map((template) => (
        <TemplateItem
          key={template.id}
          item={template}
          isSelected={template.component === resume?.template?.component}
          onSelect={handleTemplateSelection}
        />
      ))}
    </Drawer>
  );
}

TemplateSelection.defaultProps = {};

export default TemplateSelection;
