/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx";
import React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = ({ className, ...rest }: InputProps) => (
  <input
    {...rest}
    autoComplete="off"
    className={clsx(
      className,
      "h-[48px] placeholder:text-slate-400 block bg-white w-full border  rounded py-2 pl-3 pr-3 shadow-sm hover:border-[#502acd] focus:outline-none focus:border-[#502acd] sm:text-sm mb-4"
    )}
  />
);

export default Input;
