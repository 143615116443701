import { DuplicateIcon, DownloadIcon, EditionIcon, SaveIcon, TrashIcon, DotsVerticalIcon } from "app/icons";
import Button from "app/components/ui/button";
import { getResumeTitle } from "app/utils";
// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNow } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import fr from "date-fns/locale/fr";
import { Resume } from "generated/graphql";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";

const StyledWrapper = styled.div`
  display: flex;
  gap: 5px;

  img {
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 1px 1px 21px -6px rgba(0, 0, 0, 0.1);
  }

  .title {
    font-weight: 500;
    font-size: 14px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
  }

  .infos {
    // display: flex;
    flex-direction: column;
    gap: 20px;

    .date-info {
      font-style: italic;
      font-size: 12px;
    }

    .actions {
      display: flex;
      gap: 5px;
      flex-direction: column;

      button {
        width: 140px;
        align-self: flex-start;
        justify-content: flex-start;
        gap: 10px;
      }
    }
  }
`;

interface ResumeItemProps {
  item: Resume;
  onAction: (item: Resume, action: string) => void;
}

function ResumeItem({ item, onAction }: ResumeItemProps) {
  const handleDelete = () => {
    onAction(item, "DELETION");
  };

  const handleDuplicate = () => {
    onAction(item, "DUPLICATION");
  };

  const handleEdit = () => {
    onAction(item, "EDITION");
  };

  const handleView = () => {
    onAction(item, "VIEW");
  };

  const handleDownload = () => {
    onAction(item, "DOWNLOAD");
  };

  const menu = (
    <Menu
      className=" min-w-[180px]"
      items={[
        {
          key: "1",
          label: (
            // <div className="flex gap-4">
            <Button
              onClick={handleEdit}
              variant="text"
              startIcon={<EditionIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Éditer
            </Button>
            // </div>
          )
        },
        {
          key: "2",
          label: (
            // <div className="flex gap-4">
            <Button
              onClick={handleDuplicate}
              variant="text"
              startIcon={<DuplicateIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Dupliquer
            </Button>
            // </div>
          )
        },
        {
          key: "3",
          label: (
            // <div className="flex gap-4">
            <Button
              onClick={handleDownload}
              variant="text"
              startIcon={<SaveIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Télécharger
            </Button>
            // </div>
          )
        },
        {
          key: "4",
          label: (
            // <div className="flex gap-4">
            <Button
              onClick={handleDelete}
              variant="text"
              startIcon={<TrashIcon className="w-5" color="var(--primary-color)" />}
              className="!w-full !justify-start"
            >
              Supprimer
            </Button>
            // </div>
          )
        }
      ]}
    />
  );

  return (
    <StyledWrapper className="justify-center md:justify-start sha ">
      <div className="flex flex-col   space-y-1  relative ">
        <div
          role="button"
          tabIndex={0}
          aria-hidden="true"
          className="image bg-slate-100 p-3 rounded-md shadow-sm"
          onClick={handleView}
        >
          <img src={item.template?.preview} alt="" width="250" />
        </div>
        <div className="flex flex-col ">
          <span className="text-base title">{getResumeTitle(item)}</span>
          <span className="date-info text-[10px] italic">
            Modifié il y a {formatDistanceToNow(new Date(item.updatedAt), { locale: fr })}
          </span>
        </div>

        <div className=" lg:hidden absolute right-2 top-2 bg-white shadow-xl rounded-md ">
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <Button>
              <DotsVerticalIcon />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="hidden lg:flex infos">
        <div className="actions">
          <Button
            onClick={handleEdit}
            variant="text"
            startIcon={<EditionIcon className="w-5" color="var(--primary-color)" />}
          >
            Éditer
          </Button>
          <Button
            onClick={handleDuplicate}
            variant="text"
            startIcon={<DuplicateIcon className="w-5" color="var(--primary-color)" />}
          >
            Dupliquer
          </Button>
          <Button
            onClick={handleDownload}
            variant="text"
            startIcon={<SaveIcon className="w-5" color="var(--primary-color)" />}
          >
            Télécharger
          </Button>
          <Button
            onClick={handleDelete}
            variant="text"
            startIcon={<TrashIcon className="w-5" color="var(--primary-color)" />}
          >
            Supprimer
          </Button>
        </div>
      </div>
    </StyledWrapper>
  );
}

export default ResumeItem;
