import { useMutation } from "@apollo/client";
import { navigate, RouteComponentProps, useParams } from "@reach/router";
import { notification, Result } from "antd";
import Button from "app/components/ui/button";
import Loader from "app/components/ui/loader";
import { VERIFY } from "app/graphql/auth/mutations";
import React, { useEffect, useState } from "react";
import { StyledFormWrapper } from "../styles";
import { authManager } from "../utils";

const Activation: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [verify, { loading }] = useMutation(VERIFY);
  const [isSuccess, setSuccess] = useState(false);

  const { token } = useParams();

  useEffect(() => {
    if (token) {
      verify({ variables: { input: { token } } })
        .then((res) => {
          notification.success({ message: "Compte validé avec succès" });
          authManager.storeToken(res.data.result);
          setSuccess(true);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        })
        .catch(() => {
          notification.error({ message: "Une erreur s'est produite" });
        });
    }
  }, [token, verify]);

  const handleGotoLogin = () => {
    navigate("/login");
  };

  if (loading) {
    return <Loader color="primary" />;
  }

  return (
    <StyledFormWrapper>
      {isSuccess ? (
        <Result
          style={{ padding: 0 }}
          status="success"
          title="Votre compte est activé avec succès !"
          extra={[
            <Button key="login" onClick={handleGotoLogin}>
              Page de connexion
            </Button>
          ]}
        />
      ) : (
        <Result
          style={{ padding: 0 }}
          status="error"
          title="Votre lien d'activation n'est plus valide"
          extra={[
            <Button key="login" onClick={handleGotoLogin}>
              Page de connexion
            </Button>
          ]}
        />
      )}
    </StyledFormWrapper>
  );
};

export default Activation;
