/* eslint-disable import/no-duplicates */
import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";

const getLocalLang = () => (localStorage.getItem("i18nextLng") === "fr" ? fr : enUS);

/**
 *
 * @param {T} date
 * @param {string} formatStr
 * @returns {date}
 */
export function formatDate<T extends string | number | Date>(date: T, formatStr: string): string {
  try {
    return format(new Date(date), formatStr, {
      useAdditionalWeekYearTokens: true,
      useAdditionalDayOfYearTokens: true,
      locale: fr
    });
  } catch (error) {
    return null;
  }
}
