import { Layout as AntLayout } from "antd";
import React from "react";
import RelookingBar from "../app-bar/relooking";

export interface LayoutProps {
  children?: React.ReactNode;
}

export const RelookingLayout = ({ children }: LayoutProps): JSX.Element => (
  <div className="app-layout">
    <RelookingBar />
    <AntLayout.Content className="main">{children}</AntLayout.Content>
  </div>
);

RelookingLayout.defaultProps = {
  children: null
};

export default RelookingLayout;
