import { navigate } from "@reach/router";
import { useStore } from "app/contexts/store-context";
import useConfirmDownload from "app/hooks/use-download";
import { ArrowLeftIcon, GridIcon, SaveIcon } from "app/icons";
import { templateDrawerAtom } from "app/store/template";
import { useAtom } from "jotai";
import Button from "../button";
import { StyledHeader } from "./styles";

export const RelookingBar = (): JSX.Element => {
  const { downloadResume } = useConfirmDownload();
  const [, showTemplateDrawer] = useAtom(templateDrawerAtom);

  const {
    state: { resume }
  } = useStore();

  const handleOpenTemplateDrawer = () => {
    showTemplateDrawer(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDownload = () => {
    downloadResume(resume);
  };

  return (
    <StyledHeader className="bg-primary flex h-[64px] justify-between">
      <div className="flex items-center flex-1 gap-4">
        <div className="hidden sm:flex left text-tidny w-[64px] p-3 h-full opacidty-25  items-center justify-center text-white bg-[#3D1EB0] ">
          <img src="/favicon-white.svg" alt="" />
        </div>

        <Button color="secondary" onClick={handleBack} className="ml-1" startIcon={<ArrowLeftIcon color="#fff" />}>
          <span className="text-md">Retour</span>
        </Button>
      </div>

      <div className="flex items-center pr-2 space-x-4">
        <Button color="secondary" onClick={handleOpenTemplateDrawer} className=" !hidden sm:!flex  border-9 shadow-md">
          <GridIcon color="#fff" />
          <span>Changer de modèle</span>
        </Button>

        <Button
          onClick={handleDownload}
          className="shadow-md"
          startIcon={<SaveIcon color="#fff" />}
          variant="contained"
          color="secondary"
        >
          <span className="hidden sm:inline"> Télécharger</span>
        </Button>
      </div>
    </StyledHeader>
  );
};

export default RelookingBar;
