import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const REGISTER = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      email
    }
  }
`;

export const VERIFY = gql`
  mutation verifyAccount($input: AccountVerificationInput!) {
    result: verifyAccount(input: $input)
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation resetPasswordRequest($input: ResetPasswordRequestInput!) {
    result: resetPasswordRequest(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    result: resetPassword(input: $input)
  }
`;

export const UPDATE_USER_INFOS = gql`
  mutation updateUserInfos($input: UpdateUserInfosInput!) {
    result: updateUserInfos(input: $input) {
      id
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    result: updatePassword(input: $input)
  }
`;

export const UPDATE_EMAIL = gql`
  mutation updateEmail($input: UpdateEmailInput!) {
    result: updateEmail(input: $input)
  }
`;

export const RESEND_VERIFICATION_MAIL = gql`
  mutation resendVerificationEmail($input: ResendVerificationEmailInput!) {
    resendVerificationEmail(input: $input)
  }
`;
