import { useLocation } from "@reach/router";
import { Drawer } from "antd";
import TipsItemCard from "app/components/ui/card/tips-item-card";
import useResize from "app/hooks/useResize";
import { useMemo } from "react";

const TIPS_BY_SECTION = {
  presentation: {
    description:
      "L'en-tête  de votre CV est un élément clé qui permet de présenter votre identité et votre expertise de manière professionnelle et attractive. Il doit être soigné et bien présenté afin de donner une bonne première impression à votre futur employeur.",
    tips: [
      "Il comprend généralement votre nom, votre prénom et vos coordonnées (adresse, téléphone, email)",
      "Mentionnez votre photo si vous en avez une : si vous choisissez d'inclure une photo de vous sur votre CV, assurez-vous qu'elle est de qualité et professionnelle. Évitez les photos de vacances ou de soirées entre amis.",
      "Mentionnez votre nationalité et votre permis de travail si cela est pertinent"
    ]
  },
  experiences: {
    description:
      'La section "Expérience professionnelle" de votre CV sert à présenter votre parcours professionnel et à mettre en avant vos réalisations et vos compétences clés. Elle doit donner une idée de votre expertise et de votre expérience dans votre domaine de travail, et permettre au recruteur de comprendre comment vous avez progressé dans votre carrière.',
    tips: [
      "Mettez l'accent sur vos réalisations et vos compétences en utilisant des verbes d'action et des exemples concrets.",
      "Présentez vos expériences de manière chronologique, en commençant par les plus récentes.",
      "Faites attention à la longueur de vos descriptions et évitez les détails inutiles.",
      "Vérifiez soigneusement votre CV pour éviter les fautes d'orthographe et de grammaire.",
      "Personnalisez votre présentation en fonction du poste visé et de votre parcours professionnel."
    ]
  },
  education: {
    description:
      "La section \"Education\" de votre CV sert à présenter votre parcours scolaire et académique. Elle doit donner une idée de vos diplômes et de vos formations, et permettre au recruteur de comprendre votre niveau d'études et votre domaine de spécialisation. Dans cette section, vous devez décrire vos diplômes et vos formations en précisant le nom de l'établissement, la spécialité et la date d'obtention.",
    tips: [
      "Présentez vos diplômes et vos formations de manière chronologique, en commençant par les plus récents.",
      "Indiquez le nom de l'établissement, la spécialité et la date d'obtention de chaque diplôme ou formation.",
      "Incluez des informations sur vos résultats et sur les projets ou les stages réalisés au cours de vos études si cela est pertinent pour le poste visé.",
      "Mettez en avant les diplômes et les formations les plus pertinents pour le poste visé et votre parcours professionnel.",
      "Personnalisez votre présentation en fonction du poste visé et de votre parcours professionnel."
    ]
  },
  competences: {
    description:
      'La section "Compétences" de votre CV sert à présenter les compétences et les connaissances clés que vous avez acquises au cours de votre parcours professionnel et scolaire. Elle permet au recruteur de comprendre votre expertise et votre niveau de maîtrise dans différents domaines et de savoir comment vous pourriez être utile à l\'entreprise.',
    tips: [
      "Présentez vos compétences de manière concise et illustrez-les avec des exemples concrets de situations où vous avez mis ces compétences en pratique.",
      "Incluez vos compétences techniques et professionnelles, comme la maîtrise de logiciels ou de langues étrangères, ainsi que vos compétences comportementales, comme votre capacité à travailler en équipe ou à gérer des projets.",
      "Mettez en avant les compétences les plus pertinentes pour le poste visé et votre parcours professionnel."
    ]
  },
  langues: {
    description:
      'La section "Langues" de votre CV sert à présenter les langues que vous parlez couramment ou que vous maîtrisez de manière avancée. Elle permet au recruteur de comprendre votre niveau de maîtrise de chaque langue et de savoir comment vous pourriez être utile à l\'entreprise en termes de communication et de relation avec les clients ou les partenaires étrangers.',
    tips: [
      'Présentez vos langues de manière concise et précisez votre niveau de maîtrise de chaque langue (par exemple : "Anglais - niveau avancé").',
      'Si vous avez une certification ou un diplôme dans une langue, mentionnez-le (par exemple : "Espagnol - diplôme DELE niveau C2").',
      "Mettez en avant les langues les plus pertinentes pour le poste visé et votre parcours professionnel."
    ]
  }
};
interface SectionTipsProps {
  visible: boolean;
  onClose: VoidFunction;
}

function SectionTips({ visible, onClose }: SectionTipsProps) {
  const { width } = useResize();
  const location = useLocation();

  const sectionTips = useMemo(() => {
    const splitPath = location.pathname.split("/");

    return TIPS_BY_SECTION[splitPath[splitPath.length - 1]] || { definition: "", tips: [] };
  }, [location.pathname]);

  const drawerWidth = useMemo(() => {
    if (width > 768) {
      return "35vw";
    }

    if (width > 425) {
      return "65vw";
    }
    return "100vw";
  }, [width]);

  return (
    <Drawer title="Conseils" visible={visible} width={drawerWidth} onClose={onClose}>
      <div className="fldex items-centder">
        {sectionTips.description && (
          <div className="bg-slate-100 p-3 rounded-sm rounded-bl-3xl rounded-tr-3xl">{sectionTips.description}</div>
        )}

        <div className="space-y-5">
          {sectionTips.tips.map((tip, index) => (
            <TipsItemCard color={index % 2 === 0 ? "primary" : "secondary"} description={tip} />
          ))}
        </div>
      </div>
    </Drawer>
  );
}

export default SectionTips;
