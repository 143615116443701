import { Alert, Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Label from "app/components/ui/label";
import { ChangeEvent, useMemo } from "react";
import ProgressDate from "app/components/ui/progress/progress-date";
import TextEditor from "app/main/text-editor";
import Input from "app/components/ui/input";
import { ProgressEntryProps } from "app/components/ui/progress/types";
import { Education } from "generated/graphql";

const errorsLabel = {
  degree: "Veuillez entrer le diplôme obtenu",
  school: "Veuillez entrer l'établissement qui vous a décerné votre diplôme",
  location: "Veuillez entrer le lieu de votre établissement"
};

const EducationInputs = ({ progression: education, index, onChange }: ProgressEntryProps<Education>) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    onChange(name, value, index);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target;

    onChange(name, checked, index);
  };

  const handleDescriptionChange = (text: string) => {
    onChange("description", text, index);
  };

  const errors = useMemo(() => {
    const temp = Object.entries(errorsLabel).reduce((prev, [key, value]) => {
      if (!education[key]) {
        prev.push(value);
      }

      return prev;
    }, []);

    if (!education.isCurrent && (!education.startDate || !education.endDate)) {
      temp.push("Veuillez entrer la période de votre diplôme");
    }

    if (
      !education.isCurrent &&
      education.startDate &&
      education.endDate &&
      new Date(education.startDate) > new Date(education.endDate)
    ) {
      temp.push("La date de début ne peut pas être supérieure à la date de fin");
    }

    return temp;
  }, [education]);

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Label>Diplôme</Label>
        <Input name="degree" value={education.degree} onChange={handleInputChange} />
      </Col>

      <Col span={12}>
        <Label>Établissement</Label>
        <Input name="school" value={education.school} onChange={handleInputChange} />
      </Col>

      <Col span={12}>
        <Label>Site web</Label>
        <Input name="link" type="url" autoComplete="off" value={education.link} onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <Label>Lieux</Label>
        <Input name="location" autoComplete="off" value={education.location} onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <br />
        <Checkbox name="isCurrent" onChange={handleCheckboxChange} checked={education.isCurrent}>
          Je suis toujours inscrit ici
        </Checkbox>
      </Col>

      <Col xs={24} md={12}>
        <Label>Date de début</Label>
        <ProgressDate
          key={1}
          value={education.startDate}
          onChange={(value) => {
            onChange("startDate", value, index);
          }}
        />
      </Col>

      <Col xs={24} md={12}>
        {education.isCurrent ? (
          <div className="flex items-end h-full align-bottom">
            <div className="w-full border-1 border-[1px] rounded-[4px] border-[#d9d9d9] h-[48px]  flex items-center pl-2">
              Présent
            </div>
          </div>
        ) : (
          <>
            <Label>Date de fin</Label>
            <ProgressDate
              key={2}
              value={education.endDate}
              onChange={(value) => {
                onChange("endDate", value, index);
              }}
            />
          </>
        )}
      </Col>

      <Col span={24}>
        <Label>Description</Label>
        <TextEditor value={education.description} onChange={handleDescriptionChange} />
      </Col>

      {!!errors.length && (
        <Alert
          className="w-full"
          type="error"
          message={
            <ul className="list-disc list-inside">
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          }
        />
      )}
    </Row>
  );
};

export default EducationInputs;
