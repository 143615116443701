import { RouteComponentProps } from "@reach/router";
import MainLayout from "app/components/ui/layout";
import Home from "./Home";

const Dashboard = (props: RouteComponentProps) => (
  <MainLayout>
    <Home />
  </MainLayout>
);

export default Dashboard;
