import { RouteComponentProps } from "@reach/router";
import { AutoComplete, Col, Row } from "antd";
import Input from "app/components/ui/input";
import Label from "app/components/ui/label";
import { ProfileTitleOption } from "app/constants";
import { useStore } from "app/contexts/store-context";
import { makeSearch } from "app/utils";
import { FormEvent, useState } from "react";
import styled from "styled-components";
import PictureUploadButton from "./picture-upload-button";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-end;

  .info-item {
    flex-basis: calc(50% - 10px);
  }

  .info-item.full {
    flex-basis: 100%;
  }

  input {
    margin: 0;
  }

  .ant-collapse-content {
    color: rgba(25, 47, 98, 0.74);
  }
`;

const OTHERS_INFOS = [
  {
    key: "drivingLicence",
    label: "Permis de conduire",
    type: "string",
    placeholder: "Permis B"
  },
  {
    key: "birthdate",
    label: "Date de naissance",
    type: "string",
    placeholder: "10/05/1992"
  },
  {
    key: "citizenship",
    label: "Nationalité",
    type: "string",
    placeholder: "Sénégalaise"
  },
  {
    key: "civilStatus",
    label: "État civil",
    type: "string",
    placeholder: "Marié"
  }
];

const Presentation = (props: RouteComponentProps): JSX.Element => {
  const {
    state: {
      resume: { personalInfo: profile, ...rest }
    },
    dispatch
  } = useStore();
  const [options, setOptions] = useState<{ value: string }[]>([]);

  const handleChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();

    dispatch({
      type: "resume",
      payload: {
        ...rest,
        personalInfo: {
          ...profile,
          [e.currentTarget.name]: e.currentTarget.value
        }
      }
    });
  };

  const handleTitleChange = (data: string) => {
    dispatch({
      type: "resume",
      payload: {
        ...rest,
        personalInfo: {
          ...profile,
          title: data
        }
      }
    });
  };

  const handleSearch = (text) => {
    setOptions(makeSearch(ProfileTitleOption, text));
  };

  return (
    <Wrapper>
      <PictureUploadButton picture={rest.picture} />
      <div className="w-full">
        <Row gutter={[12, 12]}>
          <Col xs={24} md={12}>
            <Label htmlFor="firstname">Prénom</Label>
            <Input
              value={profile?.firstname}
              id="firstname"
              placeholder="Ex: Paul"
              name="firstname"
              onChange={handleChange}
            />
          </Col>

          <Col xs={24} md={12}>
            <Label htmlFor="lastname">Nom</Label>
            <Input
              id="lastname"
              value={profile?.lastname}
              placeholder="Ex: Martin"
              name="lastname"
              onChange={handleChange}
            />
          </Col>

          <Col xs={24} md={12}>
            <Label htmlFor="title">Titre du poste souhaité</Label>
            {/* <Input
              id="title"
              value={profile?.title}
              placeholder="Ex: Responsable Commercial"
              name="title"
              onChange={handleChange}
            /> */}

            <AutoComplete
              value={profile?.title}
              options={options}
              style={{ width: "100%" }}
              // onSelect={onSelect}
              onSearch={handleSearch}
              onChange={handleTitleChange}
              placeholder="Ex: Responsable Commercial"
            />
          </Col>

          <Col xs={24} md={12}>
            <Label htmlFor="email">E-mail</Label>
            <Input
              id="email"
              value={profile?.email}
              placeholder="Ex: paulmartin@email.com"
              name="email"
              type="email"
              onChange={handleChange}
            />
          </Col>

          <Col xs={24} md={12}>
            <Label htmlFor="phone">Téléphone</Label>
            <Input
              id="phone"
              value={profile?.phone}
              placeholder="Ex: +221 XX XXX XX XX"
              name="phone"
              onChange={handleChange}
            />
          </Col>

          <Col xs={24} md={12}>
            <Label htmlFor="address">Adresse</Label>
            <Input
              id="address"
              value={profile?.address}
              placeholder="Ex: Dakar, Sénégal"
              name="address"
              onChange={handleChange}
            />
          </Col>

          {OTHERS_INFOS.map(({ key, label, placeholder }) => (
            <Col xs={24} md={12}>
              <Label htmlFor={key}>{label}</Label>
              <Input
                id={key}
                value={profile?.[key]}
                placeholder={`Ex: ${placeholder}`}
                name={key}
                onChange={handleChange}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
};

export default Presentation;
