import React from "react";
import { ModalProps as AntModalProps } from "antd";
import { StyledModal } from "./styles";
import ModalFooter from "./footer";

export interface ModalProps extends AntModalProps {
  children?: React.ReactNode;
  footerExtra?: React.ReactNode;
  okText?: string;
  cancelText?: string;
}

const Modal = ({
  title,
  children,
  visible,
  okText,
  cancelText,
  confirmLoading,
  width,
  footerExtra,
  onCancel,
  onOk
}: ModalProps): JSX.Element => (
  <StyledModal
    title={title}
    visible={visible}
    onCancel={onCancel}
    width={width}
    footer={
      <ModalFooter
        okText={okText}
        cancelText={cancelText}
        confirmLoading={confirmLoading}
        footerExtra={footerExtra}
        onCancel={onCancel}
        onOk={onOk}
      />
    }
  >
    {children}
  </StyledModal>
);

Modal.defaultProps = {
  okText: "Confirmer",
  cancelText: "Annuler",
  children: null,
  footerExtra: null
};

export default Modal;
