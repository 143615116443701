import { Form, Input } from "antd";
import Button from "app/components/ui/button";
import Loader from "app/components/ui/loader";
import { ILoginFormValues, LoginFormProps } from "../types";

export const LoginForm = ({ submitted, onSubmit: onLogin }: LoginFormProps): JSX.Element => {
  const handleLogin = (values: ILoginFormValues) => {
    onLogin(values);
  };

  return (
    <div>
      <Form name="login-form" onFinish={handleLogin} layout="vertical">
        <div className="space-y-5">
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, type: "email", message: "Veuillez saisir votre e-mail!" }]}
          >
            <Input type="email" placeholder="Votre e-mail" />
          </Form.Item>

          <Form.Item
            label="Mot de passe"
            name="password"
            rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
          >
            <Input.Password type="password" placeholder="Votre mot de passe" />
          </Form.Item>
          <div className="text-xs text-right">
            <a href="/forgot-password" className="text-primary">
              J’ai oublié mon mot de passe
            </a>
          </div>

          <Button
            disabled={submitted}
            loading={submitted}
            color="primary"
            variant="contained"
            style={{ height: 48 }}
            block
            type="submit"
          >
            {submitted ? <Loader /> : "Connexion"}
          </Button>

          <div className="text-xs text-center">
            Vous n’avez pas de compte ?{" "}
            <a href="/signup" className="text-primary font-semibold inline-block">
              INSCRIVEZ-VOUS
            </a>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
