import { ReactComponent as ForgotIllustration } from "assets/forgot-illustration.svg";
import { ReactComponent as Illustration } from "assets/login-illustration.svg";
import { ReactComponent as SignupIllustration } from "assets/signup-illustration.svg";
import styled from "styled-components";
import { LayoutProps } from ".";

const StyledAuthLayout = styled.div`
  .form-card {
    position: relative;
    border-radius: 10px;

    &::after {
      content: "";
      width: 125px;
      height: 125px;
      background-color: #65daff;

      position: absolute;
      z-index: -1;
      top: -20px;
      right: -20px;
      border-radius: 10px;
    }
  }
`;

export const AuthLayout = ({ children }: LayoutProps): JSX.Element => (
  <StyledAuthLayout className="flex bg-[#F5F5FC] min-h-screen z-10 ">
    <div className="hidden sm:w-[45%] sm:flex flex-col">
      <div className="flex h-[85%] p-20">
        {window.location?.pathname?.includes("/login") && <Illustration width="100%" height="100%" />}
        {window.location?.pathname?.includes("/signup") && <SignupIllustration width="100%" height="100%" />}
        {window.location?.pathname?.includes("/activation") && <SignupIllustration width="100%" height="100%" />}
        {window.location?.pathname?.includes("/forgot-password") && <ForgotIllustration width="100%" height="100%" />}
        {window.location?.pathname?.includes("/reset") && <ForgotIllustration width="100%" height="100%" />}
      </div>
      <div className="flex h-[15%]  justify-center">
        <img src="/logo.svg" alt="Logo V1" className="w-[150px]" />
      </div>
    </div>
    <div className="flex flex-col relative bg-primary w-full sm:w-[55%]  z-10">
      <div className="flex h-[15%] items-center justify-center sm:justify-start sm:ml-10">
        <img src="/logo-white.svg" alt="Logo V2" className="w-[150px]" />
      </div>
      <div className="flex items-center h-[85%] p-10 sm:p-0">
        <div className="form-card shadow-lg bg-white m-autod px-5 py-10 sm:p-20 w-full sm:w-[450px] sm:ml-[-65px] ">
          {children}
        </div>
      </div>
    </div>
  </StyledAuthLayout>
);

AuthLayout.defaultProps = {
  children: null
};

export default AuthLayout;
