import { useMutation, useQuery } from "@apollo/client";
import { navigate } from "@reach/router";
import { Col, List, Modal, Row, notification } from "antd";
import Button from "app/components/ui/button";
import ResumeItemSkeleton from "app/components/ui/skeleton/resume";
import { useStore } from "app/contexts/store-context";
import { DELETE_RESUME } from "app/graphql/resumes/mutations";
import { MY_RESUMES } from "app/graphql/resumes/queries";
import useConfirmDownload from "app/hooks/use-download";
import { Resume } from "generated/graphql";
import { useState } from "react";
import DuplicateResume from "./duplicate";
import ResumeItem from "./item";
import ResumeOverview from "./overview";

interface ResumesListProps {
  onNew: () => void;
}
function ResumesList({ onNew }: ResumesListProps) {
  const { data, refetch, loading: isLoading } = useQuery(MY_RESUMES, { fetchPolicy: "network-only" });

  const [deleteResume] = useMutation(DELETE_RESUME);

  const [currentResume, setCurrentResume] = useState(null);
  const [overviewVisible, toggleOverview] = useState(false);

  const [showDucplicateModal, toggleDuplicateModal] = useState(false);
  const { downloadResume } = useConfirmDownload();
  const { dispatch } = useStore();

  const handleDeletion = (item) => {
    deleteResume({ variables: { id: item.id } }).then(() => {
      refetch();
      notification.success({ message: "Le CV a été supprimé avec succès" });
    });
  };

  const handleConfirmDeletion = (item) => {
    Modal.confirm({
      title: "Voulez-vous vraiment supprimer ce CV ?",
      width: 450,
      okButtonProps: { className: "bg-primary" },
      okText: "Oui",
      cancelText: "Non",
      onOk: () => handleDeletion(item),
      icon: null
    });
  };

  const handleAction = (item: Resume, key: string) => {
    switch (key) {
      case "VIEW":
        dispatch({ type: "resume", payload: item });
        setCurrentResume(item);
        toggleOverview(true);
        break;
      case "DELETION":
        handleConfirmDeletion(item);
        break;
      case "DUPLICATION":
        setCurrentResume(item);
        toggleDuplicateModal(true);
        break;
      case "EDITION":
        navigate(`/editor/rsme/${item.id}/presentation`);
        break;
      case "DOWNLOAD":
        downloadResume(item);
        dispatch({ type: "resume", payload: item });
        break;

      default:
        break;
    }
  };

  const handleCloseOverview = () => {
    toggleOverview(false);
  };

  const handleCloseDuplication = (success) => {
    toggleDuplicateModal(false);
    setCurrentResume(null);
    if (success) {
      refetch();
    }
  };

  if (isLoading) {
    return (
      <Row gutter={[50, 50]}>
        {[1, 2, 3, 4].map((item) => (
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
            <ResumeItemSkeleton />
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <>
      {data?.result.length ? (
        <List
          grid={{
            gutter: 50,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3
          }}
          style={{ justifyContent: "space-between" }}
          dataSource={data?.result || []}
          renderItem={(item) => (
            <List.Item style={{ marginBottom: 50 }}>
              <ResumeItem item={item} onAction={handleAction} />
            </List.Item>
          )}
        />
      ) : (
        <div className="flex flex-col items-center justify-center gap-8 h-fsit h-[calc(100vh_-_400px)] ">
          <img src="/assets/no-data.svg" alt="" width={100} />
          <span className="text-center text-md text-slate-500">
            Aucun CV n&apos;a été créé pour l&apos;instant. <br /> Commencez par en ajouter.
          </span>
          <Button variant="contained" onClick={onNew} color="primary" block className="!w-[200px]">
            Créer un nouveau CV
          </Button>
        </div>
      )}
      <ResumeOverview isVisible={overviewVisible} resume={currentResume} onClose={handleCloseOverview} />
      <DuplicateResume visible={showDucplicateModal} resumeId={currentResume?.id} onCancel={handleCloseDuplication} />
    </>
  );
}

export default ResumesList;
