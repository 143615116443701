import useResize from "app/hooks/useResize";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const A4SIZE = {
  with: 595,
  height: 842
};

interface PageProps {
  children: React.ReactNode;
  full?: boolean;
}

const StyledPage = styled.div``;

const Page = ({ children, full }: PageProps): JSX.Element => {
  const windowSize = useResize();
  const divRef = useRef(null);

  useEffect(() => {
    const div = document.getElementById("preview-page");

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const { scrollHeight } = divRef.current;
        const page = Math.ceil(scrollHeight / A4SIZE.height);
        div.style.height = `${A4SIZE.height * page}px`;
      });
    });

    observer.observe(div, {
      childList: true,
      subtree: true
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const elm = document.getElementById("preview-wrapper");
    if (elm) {
      const { clientHeight, clientWidth } = elm;
      const x = Math.min(clientHeight, clientWidth);
      let scaleX = x / A4SIZE.with;
      const scaleY = x / A4SIZE.height;
      scaleX = Math.min(scaleX, scaleY);
      const div = document.getElementById("preview-page");

      if (!full) {
        div.style.transform = `scale(${scaleX - 0.02})`;
      }
    }
  }, [windowSize, full]);

  return (
    <StyledPage ref={divRef} id="preview-page" style={{ height: A4SIZE.height, width: A4SIZE.with }}>
      {children}
    </StyledPage>
  );
};

Page.defaultProps = {
  full: false
};
export default Page;
