/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  font-size: 12px;
  padding: 8px 0;
  font-weight: 500;
  display: inline-block;
`;

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
}

export const Label = ({ children, ...rest }: LabelProps): JSX.Element => (
  <StyledLabel {...rest}>{children}</StyledLabel>
);

export default Label;
