import { RouteComponentProps } from "@reach/router";
import Progress from "app/components/ui/progress";
import { ProgressHeaderProps } from "app/components/ui/progress/types";
import { useStore } from "app/contexts/store-context";
import { Certificate } from "generated/graphql";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DateDisplaying } from "../experiences";
import CertificateInputs from "./components/certificate-inputs";

const defaultValue: Certificate = {
  name: "",
  issueDate: "",
  organization: ""
};

const Header = ({ progression, index }: ProgressHeaderProps<Certificate>) => (
  <>
    <span className="title">{progression.name ? progression.name : `[Certificat ${index + 1}]`}</span>
    <DateDisplaying startDate={progression.issueDate} endDate={null} isCurrent={null} />
  </>
);

const Certificates = (props: RouteComponentProps): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>();
  const {
    state: {
      resume: { certificates: entries, ...rest }
    },
    dispatch
  } = useStore();

  const onNew = () => {
    const newEntry = {
      ...defaultValue,
      uid: uuidv4()
    };
    dispatch({
      type: "resume",
      payload: { ...rest, certificates: [...entries, newEntry] }
    });
    setActiveKey(newEntry.uid);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const {
      dataset: { key = null }
    } = e.currentTarget;

    if (key) {
      dispatch({
        type: "resume",
        payload: { ...rest, certificates: [...entries.filter((item) => item.uid !== key)] }
      });
    }
  };

  const onCollapseChange = (key: string) => {
    setActiveKey(key);
  };

  const handleChange = (key: string, value: string, index: number) => {
    const entryToUpdate = { ...entries[index] };

    if (entryToUpdate) {
      entryToUpdate[key] = value;

      const oldData = [...entries];
      oldData[index] = entryToUpdate;

      dispatch({
        type: "resume",
        payload: { ...rest, certificates: [...oldData] }
      });
    }
  };

  return (
    <Progress
      addProgressButtonLabel="Ajouter un certificat"
      header={Header}
      content={CertificateInputs}
      currentKey={activeKey}
      progressions={entries}
      onChange={handleChange}
      onNew={onNew}
      onToggle={onCollapseChange}
      onDelete={onDelete}
    />
  );
};

export default Certificates;
