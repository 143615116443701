import { navigate, useLocation } from "@reach/router";
import {
  CertificateIcon,
  EducationIcon,
  HobbyIcon,
  IdentificationIcon,
  LanguagesIcon,
  ProfileIcon,
  ReferenceIcon,
  SkillsIcon,
  StartIcon,
  ToolsIcon,
  TrophyIcon,
  WorkIcon
} from "app/icons";
import { useStore } from "app/contexts/store-context";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { StyledWrapper } from "../../resume/steps/styles";

const icons = {
  presentation: <IdentificationIcon />,
  profile: <ProfileIcon />,
  experiences: <WorkIcon />,
  educations: <EducationIcon />,
  skills: <SkillsIcon />,
  languages: <LanguagesIcon />,
  interests: <HobbyIcon />,
  certificates: <CertificateIcon />,
  references: <ReferenceIcon />,
  strengths: <StartIcon />,
  awards: <TrophyIcon />,
  projects: <ToolsIcon />
};

export const NEXT_STEPS = [];

interface StepItemProps {
  item: any;
  isCurrent: boolean;
}
export const StepItem = ({ item, isCurrent }: StepItemProps) => {
  const onClick = () => {
    navigate(item.name);
  };

  return (
    <div
      onClick={onClick}
      className={clsx("step-item relative", {
        current: isCurrent
      })}
      role="button"
      aria-hidden
    >
      <div className="icon">{icons[item.name]}</div>
      <div className="info">
        <span>{item.label}</span>
      </div>

      {/* {!item.enable && (
        <span className="absolute right-2 bg-primary text-white rounded-full p-1 text-[9px] top-2 flex items-center justify-between gap-1">
          <LockOutlined />
          BIENTÔT
        </span>
      )} */}
    </div>
  );
};

const sections = [
  {
    id: "Contact",
    name: "contact",
    label: "Contact"
  },
  {
    id: "erddfe",
    name: "dfbdbbdf",
    label: "Date et sujet"
  },
  {
    id: "ere",
    name: "dfdbfbdfbdfdfd",
    label: "Destinataire"
  },
  {
    id: "ere",
    name: "dddffd",
    label: "Salutation"
  },
  {
    id: "ere",
    name: "ddddfdfffd",
    label: "Introduction"
  },
  {
    id: "ersdsde",
    name: "ddddfdffd",
    label: "Message"
  },
  {
    id: "essre",
    name: "ddfbpfo",
    label: "Conclusion"
  }
];

const EditionSteps = (): JSX.Element => {
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    const segments = pathname.split("/").filter((segment) => segment?.trim());

    if (segments.length) {
      setCurrent(segments[segments.length - 1]);
    }
  }, [pathname]);

  return (
    <StyledWrapper row={sections?.length} className="hidden pt-0 lg:block">
      <div className="steps-grid">
        {sections?.map((item) => (
          <StepItem key={item.id} item={item} isCurrent={item.name === current} />
        ))}
      </div>
    </StyledWrapper>
  );
};

export default EditionSteps;
