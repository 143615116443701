import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import { ColorType } from "../button";

const LoaderWrapper = styled.div`
  div {
    border: 3px solid hsl(254deg 66% 48% / 41%);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

interface LoaderProps {
  color?: ColorType;
}

const CircularLoader = ({ color }: LoaderProps) => (
  <LoaderWrapper className={clsx("balls", { [color]: !!color })}>
    <div />
  </LoaderWrapper>
);

export default CircularLoader;

CircularLoader.defaultProps = {
  color: "default"
};
