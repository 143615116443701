import { Form, Input } from "antd";
import Button from "app/components/ui/button";
import Loader from "app/components/ui/loader";
import { ILoginFormValues, LoginFormProps } from "../types";

export function ForgotForm({ submitted, onSubmit }: LoginFormProps): JSX.Element {
  const handleSubmit = (values: ILoginFormValues) => {
    onSubmit(values);
  };
  return (
    <Form name="login-form" onFinish={handleSubmit} layout="vertical">
      <div className="space-y-5">
        <Form.Item
          label="E-mail"
          name="email"
          rules={[{ required: true, type: "email", message: "Veuillez saisir votre e-mail!" }]}
        >
          <Input type="email" placeholder="Votre e-mail" />
        </Form.Item>

        <Button
          disabled={submitted}
          loading={submitted}
          color="primary"
          variant="contained"
          style={{ height: 48 }}
          block
          type="submit"
        >
          {submitted ? <Loader /> : "Envoyer le lien de réinitialisation"}
        </Button>

        <div className="text-xs text-center">
          <a href="/login" className="text-primary font-semibold">
            Retourner à la page de connexion
          </a>
        </div>
      </div>
    </Form>
  );
}

export default ForgotForm;
