import { useMutation } from "@apollo/client";
import { navigate, RouteComponentProps } from "@reach/router";
import { message, Result } from "antd";
import Button from "app/components/ui/button";
import { RESET_PASSWORD_REQUEST } from "app/graphql/auth/mutations";
import React, { useState } from "react";
import ForgotForm from "../components/ForgotForm";
import { StyledFormWrapper } from "../styles";
import { ILoginFormValues } from "../types";

const Forgot: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [resetPasswordRequest, { loading }] = useMutation(RESET_PASSWORD_REQUEST);
  const [isSuccess, setSuccess] = useState(false);

  const handleSubmit = (formValues: ILoginFormValues) => {
    resetPasswordRequest({ variables: { input: formValues } })
      .then((res) => {
        if (res.data.result === "SUCCESS") setSuccess(true);
      })
      .catch((error) => {
        message.error("E-mail incorrect");
      });
  };

  const handleGotoLogin = () => {
    navigate("/login");
  };

  return (
    <StyledFormWrapper>
      {isSuccess ? (
        <Result
          style={{ padding: 0 }}
          status="success"
          title="Un e-mail de réinitialisation de mot de passe vous a été envoyé."
          subTitle="Veuillez suivre les instructions pour réinitialiser votre mot de passe."
          extra={[
            <Button key="login" onClick={handleGotoLogin}>
              Page de connexion
            </Button>
          ]}
        />
      ) : (
        <>
          <div className="text-center text-primary font-bold text-3xl pb-5">Mot de passe oublié ?</div>
          <ForgotForm submitted={loading} onSubmit={handleSubmit} />
        </>
      )}
    </StyledFormWrapper>
  );
};

export default Forgot;
