import { Modal as AntModal } from "antd";
import styled from "styled-components";

export const StyledModal = styled(AntModal)`
  .ant-modal-body {
    max-height: 55vh;
    overflow: hidden;
    overflow-y: scroll;
    padding-block: 16px;
    padding-left: 8px;
  }

  .ant-modal-close-x {
    margin-right: 16px;

    svg {
      background-color: #edf1f7;
      border-radius: 3px;
      color: #19212c;
      height: 22px;
      padding: 4px;
      width: 22px;
    }
  }

  .ant-modal-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--neutral-900);
  }

  .ant-modal-footer {
    border-top: 0;
  }
`;

export const StyledFooterContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .extra {
    text-align: left;
  }

  .alignButtons {
    display: flex;
    gap: 6px;
  }

  button {
    border-radius: 4px;
    min-width: 105px;
    &.cancel {
      margin-right: 8px;
    }
  }
`;
