import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { Skeleton } from "antd";
import { AuthLayout } from "app/components/ui/layout/auth";
import React, { Suspense } from "react";
import Activation from "./containers/activation";
import Forgot from "./containers/Forgot";
import Login from "./containers/Login";
import Reset from "./containers/reset";
import Signup from "./containers/Signup";
import UnVerified from "./containers/unverified";
import { authManager } from "./utils";

const Auth: React.FC<RouteComponentProps> = (): JSX.Element => {
  const token = authManager.readToken();

  if (token) return <Redirect noThrow to="/" />;

  return (
    <AuthLayout>
      <Suspense fallback={<Skeleton />}>
        <Router>
          <Login path="login" />
          <UnVerified path="login/unverified" />
          <Signup path="signup" />
          <Forgot path="forgot-password" />
          <Activation path="activation/:token" />
          <Reset path="reset/:token" />
          <Redirect from="/" to="/login" noThrow />
        </Router>
      </Suspense>
    </AuthLayout>
  );
};

export default Auth;
export * from "./PrivateRoute";
