import { Button, Collapse } from "antd";
import { PlusSmallIcon, TrashIcon } from "app/icons";
import React from "react";
import styled from "styled-components";
import { StyledHeader, StyledProgressItem } from "./styles";
import { ProgressProps } from "./types";

const { Panel } = Collapse;

export const SytledAddingButton = styled(Button)`
  background: transparent;
  height: 40px !important;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  height: auto;
  color: #502acd;
  font-weight: 500;
  border-radius: 8px;
  margin: auto;
  font-size: 12px !important;
  width: 100%;
  border-style: dashed;

  :hover {
    background: transparent;
  }

  path {
    fill: #502acd;
  }
`;

const Progress = ({
  header,
  content,
  progressions,
  currentKey,
  addProgressButtonLabel,
  onNew,
  onChange,
  onToggle,
  onDelete
}: ProgressProps<any>): JSX.Element => {
  const handleToggle = (key: string | string[]) => {
    onToggle(key as string);
  };

  const handleChange = (key: string, value: any, index: number) => {
    onChange(key, value, index, currentKey);
  };

  const Header = header;
  const Content = content;

  return (
    <div>
      {progressions?.map((progression, index) => (
        <StyledProgressItem
          key={progression.uid}
          accordion
          expandIconPosition="end"
          defaultActiveKey={currentKey}
          activeKey={currentKey}
          onChange={handleToggle}
        >
          <Panel
            header={
              <StyledHeader>
                <Header progression={progression} index={index} />
              </StyledHeader>
            }
            key={progression.uid}
            extra={
              <Button type="text" data-key={progression.uid} onClick={onDelete} className="deletion-action">
                <TrashIcon />
              </Button>
            }
          >
            {React.cloneElement(<Content />, { progression, index, onChange: handleChange })}
          </Panel>
        </StyledProgressItem>
      ))}

      <SytledAddingButton
        className=" border-primary border-dashed font-light w-full"
        icon={<PlusSmallIcon color="#fff" />}
        onClick={onNew}
      >
        {!addProgressButtonLabel ? "Ajouter une progression" : addProgressButtonLabel}
      </SytledAddingButton>
    </div>
  );
};

export default Progress;
