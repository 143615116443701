import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface EditorProps {
  value: string;
  placeholder?: string;
  onChange: (content: string) => void;
}

const TextEditor = ({ value, placeholder, onChange }: EditorProps) => {
  const handleChange = (value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
    onChange(editor.getText().trim().replace("\n", "") ? value : null);
  };

  return (
    <ReactQuill
      theme="snow"
      modules={{
        toolbar: [
          ["bold", "italic", "underline", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["clean"]
        ]
      }}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

TextEditor.defaultProps = {
  placeholder: ""
};
export default TextEditor;
