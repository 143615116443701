import React, { useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useStore } from "app/contexts/store-context";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "antd";

const New = (props: RouteComponentProps): JSX.Element => {
  const [showModal, setShowModal] = useState(true);
  const {
    state: { theme },
    dispatch
  } = useStore();

  const handleNewTemplate = () => {
    const newId = uuidv4();
    dispatch({ type: "init", payload: newId });
    navigate(`/editor/rsme/${newId}/profile`);
  };

  return (
    <Modal visible>
      <div>dsjdfk</div>
    </Modal>
  );
};
export default New;
