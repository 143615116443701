import { TEMPLATES } from "app/constants";
import { ITemplateTheme } from "app/types";
import { PersnalInfo, Resume, Template } from "generated/graphql";
import React, { createContext, useContext, useReducer } from "react";

const defaultProfile: PersnalInfo = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  title: "",
  address: ""
  // socialLinks: []
};

const defaultResume = {
  personalInfo: defaultProfile,
  experiences: [],
  educations: [],
  references: [],
  skills: [],
  languages: [],
  certificates: []
};

type Action =
  | { type: "resume"; payload: Resume }
  | { type: "init-resume"; payload: Resume }
  | { type: "theme"; payload: ITemplateTheme }
  | { type: "template"; payload: Template }
  | { type: "init"; payload: string }
  | { type: "toggle-draft" };
type Dispatch = (action: Action) => void;

const storeReducer = (state: StoreProviderState, action: Action) => {
  switch (action.type) {
    case "resume":
      return { ...state, resume: action.payload, initial: false, isSaved: false };
    case "init-resume":
      return { ...state, resume: action.payload };
    case "theme":
      return { ...state, theme: action.payload };
    case "template":
      return { ...state, template: action.payload };
    // return { ...state, template: action.payload, theme: action.payload.theme };
    case "init":
      return { ...state, resume: defaultResume, template: TEMPLATES[0], theme: TEMPLATES[0].theme };
    case "toggle-draft":
      return { ...state, isSaved: !state.isSaved };

    default:
      throw new Error(`Unhandled action: ${action}`);
  }
};

export interface StoreProviderState {
  resume: Resume;
  template: Template;
  theme: ITemplateTheme;
  initial: boolean;
  isSaved: boolean;
}

export interface StoreProviderProps {
  children: React.ReactNode;
}

export const StoreContext = createContext<{ state: StoreProviderState; dispatch: Dispatch } | undefined>(undefined);

StoreContext.displayName = "StoreContext";

const defaultState: StoreProviderState = {
  resume: defaultResume,
  template: null,
  theme: null,
  initial: true,
  isSaved: true
};

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const [state, dispatch] = useReducer(storeReducer, defaultState);

  return (
    <StoreContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
