import React from "react";
import { Layout as AntLayout } from "antd";
import { EditorBar } from "../app-bar";

export interface LayoutProps {
  children?: React.ReactNode;
}

export const EditorLayout = ({ children }: LayoutProps): JSX.Element => (
  <div className="app-layout">
    <EditorBar />
    <AntLayout.Content className="flex-1 main">{children}</AntLayout.Content>
  </div>
);

EditorLayout.defaultProps = {
  children: null
};

export default EditorLayout;
