import { useMutation } from "@apollo/client";
import { Col, Form, notification, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import Button from "app/components/ui/button";
import Input from "app/components/ui/input";
import { UPDATE_EMAIL, UPDATE_PASSWORD } from "app/graphql/auth/mutations";
import { User } from "generated/graphql";
import { useState } from "react";
import { Wrapper } from "../Editor/resume/form/presentation";

interface ProfileProps {
  data: User;
}

function Settings({ data }: ProfileProps) {
  const [formValues, setFormValues] = useState<User>();
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const [updateEmail] = useMutation(UPDATE_EMAIL);
  const emailForm = useForm();
  const passwordForm = useForm();

  const handleUpdatePassword = (values) => {
    updatePassword({
      variables: {
        input: { password: values.password, newPassword: values.newPassword }
      }
    }).then(() => {
      notification.success({ message: "Mot de passe modifié avec succès!" });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  const handleUpdateEmail = (values) => {
    updateEmail({
      variables: {
        input: { newEmail: values.newEmail }
      }
    }).then(() => {
      notification.success({ message: "E-mail modifié avec succès!" });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  return (
    <div className="p-8 bg-white rounded-xl mt-5 min-h-full shadow-sm text-[rgba(25_47_98_0.74)]">
      <div className="flex justify-between">
        <span className="text-base font-semibold">Paramètres</span>
      </div>

      <Row className="mt-8" gutter={24}>
        <Col xs={24} md={12}>
          <Form name="email" onFinish={handleUpdateEmail} layout="vertical">
            <Wrapper className="space-y-3">
              <div className="info-item full">
                <span className="text-xs font-semibold">Changer d&apos;e-mail</span>
              </div>
              <div className="info-item full">
                <Form.Item label="E-mail actuelle">
                  <Input id="email" defaultValue={data?.email} value={data?.email} name="email" type="email" disabled />
                </Form.Item>
              </div>

              <div className="info-item full">
                <Form.Item
                  name="newEmail"
                  label="Nouvel e-mail"
                  rules={[{ required: true, type: "string", message: "Veuillez saisir l'e-mail!" }]}
                >
                  <Input id="email" name="newEmail" type="email" />
                </Form.Item>
              </div>

              <div className="text-right info-item full">
                <Button color="primary" variant="contained" type="submit">
                  Changer
                </Button>
              </div>
            </Wrapper>
          </Form>
        </Col>
        <Col xs={24} md={12}>
          <Form name="password-form" onFinish={handleUpdatePassword} layout="vertical">
            <Wrapper className="space-y-3">
              <div className="info-item full">
                <span className="text-xs font-semibold">Changer de mot de passe</span>
              </div>
              <div className="info-item full">
                <Form.Item label="Mot de passe actuel" name="password">
                  {/* <Label htmlFor="password">Mot de passe actuel</Label> */}
                  <Input id="password" placeholder="******" name="password" type="password" />
                </Form.Item>
              </div>

              <div className="info-item full">
                <Form.Item label="Nouveau mot de passe" name="newPassword">
                  {/* <Label htmlFor="firstname">Nouveau mot de passe</Label> */}
                  <Input id="new-password" placeholder="Nouveau mot de passe" name="newPassword" type="password" />
                </Form.Item>
              </div>

              <div className="text-right info-item full">
                <Button color="primary" variant="contained" type="submit">
                  Changer
                </Button>
              </div>
            </Wrapper>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Settings;
