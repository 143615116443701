import { gql } from "@apollo/client";

export const PRICINS = gql(`
 query pricings {
  result: pricings {
    id
    name
    price
    credits
    description
  }
 }
`);
