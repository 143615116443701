import { message } from "antd";
import { confirmDownloadingAtom, resumeDownloadingAtom } from "app/store/template";
import { getResumeTitle } from "app/utils";
import axios from "axios";
import { Resume } from "generated/graphql";
import { useAtom } from "jotai";

// function useDownload() {
//   const [download, { loading, error, data }] = useMutation(DOWNLOAD_RESUME);

//   const downloadResume = (resumeId: string) => {
//     message.info("Telechargement...");
//     download({
//       variables: {
//         id: resumeId
//       }
//     }).then((res) => {
//       FileSaver.saveAs(res.data.result, "df");
//     });
//   };

//   return { downloadResume, loading, error, data };
// }

function useConfirmDownload() {
  const [, setConfirm] = useAtom(confirmDownloadingAtom);

  const downloadResume = (resume: Resume) => {
    setConfirm(true);
  };

  return { downloadResume };
}

export default useConfirmDownload;

export function useDownload() {
  const [resumeDownloading, setInfos] = useAtom(resumeDownloadingAtom);
  const downloadResume = (resume: Resume) => {
    if (resumeDownloading.beingDownloaded) {
      message.error("Un téléchargement est déjà en cours");
      return;
    }
    setInfos((prev) => ({ ...prev, beingDownloaded: true }));

    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/resumes/download/${resume.id}`, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf"
        }
      })
      .then(async (res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const title = getResumeTitle(resume).replace(/\s/g, "_");
        link.download = `${title}.pdf`;
        link.click();

        setTimeout(() => {
          window.location.reload();
        }, 1500);

        setInfos((prev) => ({ ...prev, beingDownloaded: false, downloaded: true }));
      })
      .catch(() => {
        setInfos((prev) => ({ ...prev, beingDownloaded: false, downloaded: true }));
      });
  };

  return { downloadResume };
}
