import { RouteComponentProps } from "@reach/router";
import Progress from "app/components/ui/progress";
import { useStore } from "app/contexts/store-context";
import { formatDate } from "app/utils/date";
import { Award, Experience } from "generated/graphql";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DateDisplaying } from "../experiences";
import ExperienceInputs from "./components/inputs";

const defaultValue: Award = {
  name: "",
  description: ""
};

interface DateDisplayingProps {
  startDate: string;
  endDate: string;
  isCurrent: boolean;
  format?: string;
}

interface HeaderProps {
  progression: Award;
  index: number;
}

const Header = ({ progression, index }: HeaderProps) => (
  <>
    <span className="title">{progression.name ? progression.name : `[Distinction ${index + 1}]`}</span>
    <DateDisplaying startDate={progression.date} endDate={null} isCurrent={false} />
  </>
);

const AwardSection = (props: RouteComponentProps): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>();
  const {
    state: { resume },
    dispatch
  } = useStore();

  const onNew = () => {
    const newExperience = {
      ...defaultValue,
      uid: uuidv4()
    };
    dispatch({
      type: "resume",
      payload: { ...resume, awards: [...resume.awards, newExperience] }
    });
    setActiveKey(newExperience.uid);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const {
      dataset: { key = null }
    } = e.currentTarget;

    if (key) {
      dispatch({
        type: "resume",
        payload: { ...resume, awards: [...resume.awards.filter((item) => item.uid !== key)] }
      });
    }
  };

  const onCollapseChange = (key: string) => {
    setActiveKey(key);
  };

  const handleChange = (key: string, value: string, index: number) => {
    const experienceToUpdate = { ...resume.awards[index] };

    if (experienceToUpdate) {
      experienceToUpdate[key] = value;

      const oldData = [...resume.awards];
      oldData[index] = experienceToUpdate;

      dispatch({
        type: "resume",
        payload: { ...resume, awards: [...oldData] }
      });
    }
  };

  return (
    <Progress
      addProgressButtonLabel="Ajouter une distinction"
      header={Header}
      content={ExperienceInputs}
      currentKey={activeKey}
      progressions={resume?.awards || []}
      onChange={handleChange}
      onNew={onNew}
      onToggle={onCollapseChange}
      onDelete={onDelete}
    />
  );
};

export default AwardSection;
