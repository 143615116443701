import { RouteComponentProps } from "@reach/router";
import SkillsList from "app/components/ui/skills";
import { LanguageLevelLabelArray } from "app/constants";
import { useStore } from "app/contexts/store-context";
import { CheckMarkIcon, CloseRoundIcon, PlusIcon } from "app/icons";
import clsx from "clsx";
import { Skill } from "generated/graphql";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const COMMON_LANGUAGES = ["Wolof", "Lingala", "Français", "Anglais"];

export const StyledHelperItem = styled.button`
  padding: 0 12px;
  display: inline-flex;
  background-color: #f3f5fa;
  border-radius: 30px;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  height: 36px;

  span {
    width: 24px;
  }

  .check,
  .close {
    display: none;
  }

  &.selected {
    background-color: var(--primary-color);
    color: #fff;

    .plus {
      display: none;
    }

    .check {
      display: inline;
    }

    :hover {
      .close {
        display: inline;
      }

      .check {
        display: none;
      }
    }
  }
`;

const Languages = (props: RouteComponentProps): JSX.Element => {
  const [currentSkill, setCurrentSkill] = useState<Skill>(null);

  const {
    state: {
      resume: { languages, ...rest }
    },
    dispatch
  } = useStore();

  const userPrevLangs = useMemo(() => {
    if (languages) {
      return languages.map((lg) => lg.name);
    }
    return [];
  }, [languages]);

  const onSave = (value: Skill) => {
    if (value) {
      const savingValue = { ...value };
      if (!savingValue.id) {
        savingValue.id = uuidv4();
      }

      dispatch({
        type: "resume",
        payload: { ...rest, languages: [...languages, savingValue] }
      });
    }
  };

  const handleSelection = (value: Skill, key: number) => {
    if (key !== null) {
      const selectedSkill = languages.find((item, index) => index === Number(key));
      dispatch({
        type: "resume",
        payload: { ...rest, languages: [...languages.filter((item, index) => index !== Number(key))] }
      });
      setCurrentSkill(selectedSkill);
    }
  };

  const handleDeletion = (value: Skill, key: number) => {
    if (value) {
      dispatch({
        type: "resume",
        payload: { ...rest, languages: [...languages.filter((item) => item.id !== value.id)] }
      });
    }
  };

  const handleAdd = (lg: string) => {
    if (userPrevLangs.includes(lg)) {
      dispatch({
        type: "resume",
        payload: { ...rest, languages: [...languages.filter((item) => item.name !== lg)] }
      });
    } else {
      dispatch({
        type: "resume",
        payload: { ...rest, languages: [...languages, { name: lg, level: 3, id: uuidv4() } as Skill] }
      });
    }
  };

  const handleNew = () => {
    const newEntry: Skill = {
      uid: uuidv4(),
      name: "",
      level: 0
    };

    dispatch({
      type: "resume",
      payload: { ...rest, languages: [...languages, newEntry] }
    });
  };

  const handleChange = (key: string, value: string | number | boolean | moment.Moment, index: number) => {
    const entryToUpdate = { ...languages[index] };

    if (entryToUpdate) {
      entryToUpdate[key] = value;

      const oldData = [...languages];
      oldData[index] = entryToUpdate;

      dispatch({
        type: "resume",
        payload: { ...rest, languages: [...oldData] }
      });
    }
  };

  return (
    <div>
      <span className="text-[14px] font-semibold">Choix rapide</span>
      <div className="flex gap-4 mt-4 flex-wrap mb-8">
        {COMMON_LANGUAGES.map((lg) => (
          <StyledHelperItem
            className={clsx({ selected: userPrevLangs.includes(lg) })}
            key={lg}
            onClick={() => handleAdd(lg)}
          >
            <span className="plus">
              <PlusIcon color="rgba(25,47,98,0.74)" />
            </span>
            <span className="check">
              <CheckMarkIcon />
            </span>
            <span className="close">
              <CloseRoundIcon color="#fff" />
            </span>
            {lg}
          </StyledHelperItem>
        ))}
      </div>

      <SkillsList
        addingButtonLabel="Ajouter une langue"
        label={{ name: "Langue", level: "Niveau de langue" }}
        levelLabels={LanguageLevelLabelArray}
        skills={languages}
        onSelectSkill={handleSelection}
        onDeleteSkill={handleDeletion}
        onChange={handleChange}
        onNew={handleNew}
      />
    </div>
  );
};

export default Languages;
