import { RouteComponentProps } from "@reach/router";
import Button from "app/components/ui/button";
import Modal from "app/components/ui/modal";
import client from "app/config/apollo";
import { useStore } from "app/contexts/store-context";
import { pictureCacheTimeAtom, pictureUploadingAtom } from "app/store";
import { compressImage } from "app/utils";
import axios from "axios";
import { ProfilePicture } from "generated/graphql";
import { useAtom, useSetAtom } from "jotai";
import { ChangeEvent, useRef, useState } from "react";
import { CircleStencil, FixedCropper, FixedCropperRef } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

interface PictureUploadProps extends RouteComponentProps {
  picture: ProfilePicture;
  visible: boolean;
  onClose: () => void;
}

const PictureUpload = ({ picture, visible, onClose }: PictureUploadProps): JSX.Element => {
  const cropperRef = useRef<FixedCropperRef>(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [src, setSrc] = useState(picture?.originalUrl);
  const inputRef = useRef(null);
  const [_, toggleUploading] = useAtom(pictureUploadingAtom);

  const {
    state: {
      resume: { ...rest }
    }
  } = useStore();

  const pictureTime = useSetAtom(pictureCacheTimeAtom);

  const handleFileSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedPicuture: File = event.target.files[0];

    const compressedFile = await compressImage(selectedPicuture);

    const thumbUrl = URL.createObjectURL(compressedFile);

    setSrc(thumbUrl);
    setOriginalFile(compressedFile);
  };

  const handleCrop = () => {
    const canvas = cropperRef.current?.getCanvas();

    if (canvas) {
      const formData = new FormData();

      canvas.toBlob((blob) => {
        if (blob) {
          toggleUploading(true);
          formData.append("original", originalFile);
          formData.append("cropped", blob);

          axios
            .post(`${process.env.REACT_APP_API_END_POINT}/resumes/upload-picture/${rest.id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(async () => {
              await client.refetchQueries({ include: "all" });
              pictureTime(Date.now());
              toggleUploading(false);
            })
            .catch((error) => {
              toggleUploading(false);
            });
        }
      }, "image/jpeg");
      onClose();
      setSrc(null);
    }
  };

  const handleSelectPicture = () => {
    inputRef.current.click();
  };

  return (
    <Modal
      visible={visible}
      title="Photo de profil"
      okText="Enregistrer"
      onOk={handleCrop}
      onCancel={onClose}
      okButtonProps={{ disabled: src === null }}
      footerExtra={
        picture ? (
          <div>
            <label htmlFor="upload-picture-button" className="flex items-center cursor-pointer">
              <input
                ref={inputRef}
                accept=".jpg, .jpeg, .png"
                id="upload-picture-button"
                type="file"
                onChange={handleFileSelected}
                className="hidden"
              />
              <Button onClick={handleSelectPicture}>Nouvelle photo</Button>
            </label>
          </div>
        ) : null
      }
    >
      {src || picture ? (
        <FixedCropper
          src={src || picture?.originalUrl}
          ref={cropperRef}
          stencilProps={{
            handlers: false,
            lines: false,
            resizable: false
          }}
          stencilSize={{
            width: 400,
            height: 400
          }}
          stencilComponent={CircleStencil}
          className="cropper"
        />
      ) : (
        <div className="h-[300px] flex items-center justify-center bg-slate-50 border-4 border-dashed border-primary/20 ">
          <label htmlFor="upload-picture-button" className="flex items-center cursor-pointer">
            <input
              ref={inputRef}
              accept=".jpg, .jpeg, .png"
              id="upload-picture-button"
              type="file"
              onChange={handleFileSelected}
              className="hidden"
            />

            <span className="font-semibold ml-2 text-primary/80">Choisir une photo</span>
          </label>
        </div>
      )}
    </Modal>
  );
};

export default PictureUpload;
