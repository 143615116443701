import { useMutation } from "@apollo/client";
import { navigate, RouteComponentProps, useParams } from "@reach/router";
import { Alert, Result } from "antd";
import Button from "app/components/ui/button";
import { RESET_PASSWORD } from "app/graphql/auth/mutations";
import React, { useState } from "react";
import ResetForm from "../components/reset-form";
import { StyledFormWrapper } from "../styles";
import { ILoginFormValues } from "../types";

const Reset: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [hasError, setError] = useState(false);
  const { token } = useParams();
  const [isSuccess, setSuccess] = useState(false);

  const handleLogin = (formValues: ILoginFormValues) => {
    resetPassword({
      variables: {
        input: {
          token,
          password: formValues.password
        }
      }
    })
      .then((res) => {
        if (res.data.result === "SUCCESS") setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const handleGotoLogin = () => {
    navigate("/login");
  };

  return (
    <StyledFormWrapper>
      {isSuccess ? (
        <Result
          style={{ padding: 0 }}
          status="success"
          title="Mot de passe réinitialisé avec succès !"
          extra={[
            <Button key="login" onClick={handleGotoLogin}>
              Page de connexion
            </Button>
          ]}
        />
      ) : (
        <>
          <div className="text-center text-primary font-bold text-3xl pb-5">Réinitialiser votre mot passe</div>
          {hasError && <Alert message="Lien de réinitialisation invalide" className="mb-3" type="error" />}
          <ResetForm submitted={loading} onSubmit={handleLogin} />
        </>
      )}
    </StyledFormWrapper>
  );
};

export default Reset;
