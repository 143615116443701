import { RouteComponentProps } from "@reach/router";
import Progress from "app/components/ui/progress";
import { useStore } from "app/contexts/store-context";
import { Project } from "generated/graphql";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DateDisplaying } from "../experiences";
import EntriesInputs from "./components/entry-inputs";

const defaultValue: Project = {
  title: "",
  description: "",
  isCurrent: false
};

interface HeaderProps {
  progression: Project;
  index: number;
}

const Header = ({ progression, index }: HeaderProps) => (
  <>
    <span className="title">{progression.title ? progression.title : `[Projet ${index + 1}]`}</span>
    <DateDisplaying startDate={progression.startDate} endDate={progression.endDate} isCurrent={progression.isCurrent} />
  </>
);

const ProjectSection = (props: RouteComponentProps): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>();
  const {
    state: { resume },
    dispatch
  } = useStore();

  const onNew = () => {
    const newEntry = {
      ...defaultValue,
      uid: uuidv4()
    };
    dispatch({
      type: "resume",
      payload: { ...resume, projects: [...resume.projects, newEntry] }
    });
    setActiveKey(newEntry.uid);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const {
      dataset: { key = null }
    } = e.currentTarget;

    if (key) {
      dispatch({
        type: "resume",
        payload: { ...resume, projects: [...resume.projects.filter((item) => item.uid !== key)] }
      });
    }
  };

  const onCollapseChange = (key: string) => {
    setActiveKey(key);
  };

  const handleChange = (key: string, value: string, index: number) => {
    const entryToUpdate = { ...resume.projects[index] };

    if (entryToUpdate) {
      entryToUpdate[key] = value;

      const oldData = [...resume.projects];
      oldData[index] = entryToUpdate;

      dispatch({
        type: "resume",
        payload: { ...resume, projects: [...oldData] }
      });
    }
  };

  return (
    <Progress
      addProgressButtonLabel="Ajouter un projet"
      header={Header}
      content={EntriesInputs}
      currentKey={activeKey}
      progressions={resume.projects}
      onChange={handleChange}
      onNew={onNew}
      onToggle={onCollapseChange}
      onDelete={onDelete}
    />
  );
};

export default ProjectSection;
