import { Button } from "antd";
import { CloseIcon } from "app/icons";
import React from "react";
import styled from "styled-components";

const StyldWrapper = styled.div<{ width?: string }>`
  // width: ${(props) => (props.width ? props.width : "342px")};
`;

interface DrawerProps {
  isOpen: boolean;
  width?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
  onClose: () => void;
}

const Drawer = ({ isOpen, width, header, children, onClose }: DrawerProps) => (
  <StyldWrapper
    width={width}
    className={` fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 opacity-0 inset-0 ease-in-out ${
      isOpen ? " translate-x-0 opacity-100" : " delay-300 translate-x-full  "
    }`}
  >
    <div
      className={`w-[320px] right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ${
        isOpen ? " translate-x-0 " : " translate-x-[320px] "
      }`}
    >
      <article className="relative flex flex-col space-y-6 overflow-hidden h-full p-1">
        <header className="p-4 font-bold text-base border-b border-[#d9d9d9] h-[64px] flex justify-between items-center">
          <span>{header}</span>
          <Button className="p-2 h-auto hover:bg-black/5" onClick={onClose} type="text">
            <CloseIcon />
          </Button>
        </header>
        <div className="h-full p-4 overflow-y-scroll">{children}</div>
      </article>
    </div>
    <div aria-hidden className="w-screen h-full" onClick={onClose} />
  </StyldWrapper>
);

Drawer.defaultProps = {
  header: null,
  width: "auto",
  children: null
};

export default Drawer;
