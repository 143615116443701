import { useMutation } from "@apollo/client";
import { ApolloError, GraphQLErrors } from "@apollo/client/errors";
import { navigate, RouteComponentProps } from "@reach/router";
import { Alert } from "antd";
import HTTP_STATUS_CODES from "app/constants/http-status-codes";
import { LOGIN } from "app/graphql/auth/mutations";
import React, { useState } from "react";
import LoginForm from "../components/login-form";
import { StyledFormWrapper } from "../styles";
import { ILoginFormValues } from "../types";
import { authManager } from "../utils";

const isForbidden = (errors: GraphQLErrors) =>
  errors.some(({ extensions: { response } }) => (response as any).statusCode === HTTP_STATUS_CODES.FORBIDDEN);

const Login: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [login, { loading }] = useMutation(LOGIN);
  const [hasError, setError] = useState(false);

  const handleLogin = (formValues: ILoginFormValues) => {
    login({
      variables: {
        input: {
          ...formValues
        }
      }
    })
      .then((result) => {
        authManager.storeToken(result.data.login.accessToken);
        window.location.href = "/";
      })
      .catch(({ graphQLErrors }: ApolloError) => {
        if (isForbidden(graphQLErrors)) {
          navigate("/login/unverified", { state: { email: formValues.email } });
          return;
        }
        setError(true);
      });
  };

  return (
    <StyledFormWrapper>
      <div className="text-center text-primary font-bold text-3xl pb-5">Connexion</div>
      {hasError && <Alert message="E-mail ou mot de passe incorrect" className="mb-3" type="error" />}
      <LoginForm submitted={loading} onSubmit={handleLogin} />
    </StyledFormWrapper>
  );
};

export default Login;
