import { useQuery } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import { RESUME_PREVIEW } from "app/graphql/resumes/queries";
import useTemplate from "app/hooks/useTemplate";
import React from "react";
import Page from "./Editor/resume/preview/page";
import { StyledPreviewWrapper } from "./Editor/resume/preview/styles";

// eslint-disable-next-line react/require-default-props
const PublicPreview: React.FC<RouteComponentProps & { id?: string }> = ({ id }): JSX.Element => {
  const { data } = useQuery(RESUME_PREVIEW, { variables: { id } });

  const Template = useTemplate(data?.result?.template);

  return (
    <StyledPreviewWrapper id="preview-wrapper">
      <Page full>
        <React.Suspense fallback="">
          {Template &&
            React.cloneElement(<Template />, {
              resume: data.result,
              sections: data.result?.sections?.reduce((prev, curr) => ({ ...prev, [curr.name]: curr }), {}),
              settings: data?.result?.settings || data?.result?.template?.settings
            })}
        </React.Suspense>
      </Page>
    </StyledPreviewWrapper>
  );
};

export default PublicPreview;
