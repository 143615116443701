import { Col, Row } from "antd";
import Input from "app/components/ui/input";
import Label from "app/components/ui/label";
import ProgressDate from "app/components/ui/progress/progress-date";
import { ProgressEntryProps } from "app/components/ui/progress/types";
import TextEditor from "app/main/text-editor";
import { Award } from "generated/graphql";
import { ChangeEvent } from "react";

export const ExperienceInputs = ({ progression: experience, index, onChange }: ProgressEntryProps<Award>) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    onChange(name, value, index);
  };

  const handleDescriptionChange = (text: string) => {
    onChange("description", text, index);
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Label>Intitulé de la distinction</Label>
        <Input value={experience.name} name="name" onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <Label>Décerner par</Label>
        <Input name="issuer" value={experience.issuer} onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <Label>Date </Label>
        <ProgressDate
          key={1}
          value={experience.date}
          onChange={(value) => {
            onChange("date", value, index);
          }}
        />
      </Col>

      <Col span={24}>
        <Label>Description</Label>
        <TextEditor value={experience.description} onChange={handleDescriptionChange} />
      </Col>
    </Row>
  );
};

export default ExperienceInputs;
