import { useLocation } from "@reach/router";
import { Drawer } from "antd";
import { useStore } from "app/contexts/store-context";
import { stepsDrawerAtom } from "app/store";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { StepItem } from ".";
import { StyledWrapper } from "./styles";

const EditionDrawerSteps = (): JSX.Element => {
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(null);
  const [showDrawer, toggleDrawer] = useAtom(stepsDrawerAtom);
  const {
    state: {
      resume: { sections }
    }
  } = useStore();

  useEffect(() => {
    const segments = pathname.split("/").filter((segment) => segment?.trim());

    if (segments.length) {
      setCurrent(segments[segments.length - 1]);
    }
  }, [pathname]);

  const handleClose = () => {
    toggleDrawer(false);
  };

  return (
    <Drawer placement="left" visible={showDrawer} onClose={handleClose} width="70%">
      <StyledWrapper row={sections?.length} className="!w-auto">
        <div className="steps-grid">
          {sections?.map((item) => (
            <StepItem key={item.id} item={item} isCurrent={item.name === current} />
          ))}
        </div>
      </StyledWrapper>
    </Drawer>
  );
};

export default EditionDrawerSteps;
