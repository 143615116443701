import styled from "styled-components";

export const StyledFormWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  .header {
    height: 64px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    padding: 1rem;
  }

  .form-navigation {
    width: 100%;
    display: flex;
    gap: 10px;
    box-shadow: 0 -1rem 2.4rem -2.4rem rgba(255, 255, 255, 0.9);
    padding: 0.5rem;
    border-radius: 4px;

    .nav {
      width: 100%;
      display: flex;
      gap: 5px;
    }

    button {
      flex: 1;
      height: 48px;
      gap: 20px;

      &.flex-2 {
        flex: 2;
      }
    }
  }
`;

export const StyledHeader = styled.div`
  padding: 12px 24px;

  .description {
    font-size: 1rem;
  }
`;

export const StyledBody = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;

  .content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > div.body {
      flex: auto;
      overflow-y: scroll;
      padding: 1rem;
    }
  }
`;
