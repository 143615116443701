import { navigate } from "@reach/router";
import { Drawer } from "antd";
import Button from "app/components/ui/button";
import useTemplate from "app/hooks/useTemplate";
import { DownloadIcon, EditionIcon, SaveIcon } from "app/icons";
// import Drawer from "app/ui/drawer";
import useConfirmDownload from "app/hooks/use-download";
import { getResumeTitle } from "app/utils";
import { Resume } from "generated/graphql";
import React, { useMemo } from "react";
import useResize from "app/hooks/useResize";
import styled from "styled-components";
import Page from "../Editor/resume/preview/page";
import { StyledPreviewWrapper } from "../Editor/resume/preview/styles";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;

  .content {
    height: calc(100% - 60px);
  }

  .actions-bar {
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    button {
      height: 40px;
      align-self: flex-end;
    }
  }
`;

interface ResumeOverviewProps {
  resume: Resume;
  isVisible: boolean;

  onClose: () => void;
}

function ResumeOverview({ isVisible, resume, onClose }: ResumeOverviewProps) {
  const Template = useTemplate(resume?.template);
  const { downloadResume } = useConfirmDownload();
  const { width } = useResize();

  const handleDownload = () => {
    downloadResume(resume);
  };

  const handleEdition = () => {
    navigate(`/editor/rsme/${resume.id}/presentation`);
  };

  const drawerWidth = useMemo(() => {
    if (width > 768) {
      return "45vw";
    }

    if (width > 425) {
      return "65vw";
    }
    return "100vw";
  }, [width]);

  return (
    <Drawer title={resume ? getResumeTitle(resume) : ""} visible={isVisible} width={drawerWidth} onClose={onClose}>
      <Wrapper>
        <div className="content">
          <StyledPreviewWrapper id="preview-wrapper">
            {resume && (
              <Page>
                <React.Suspense fallback="">
                  {Template &&
                    React.cloneElement(<Template />, {
                      resume,
                      sections: resume.sections?.reduce((prev, curr) => ({ ...prev, [curr.name]: curr }), {}),

                      settings: resume?.settings || resume?.template?.settings
                    })}
                </React.Suspense>
              </Page>
            )}
          </StyledPreviewWrapper>
        </div>
        <div className="actions-bar">
          <Button onClick={handleDownload} variant="contained" startIcon={<SaveIcon color="#fff" />}>
            Telecharger
          </Button>

          <Button
            onClick={handleEdition}
            variant="outlined"
            startIcon={<EditionIcon className="w-5" color="var(--primary-color)" />}
          >
            Editer
          </Button>
        </div>
      </Wrapper>
    </Drawer>
  );
}

export default ResumeOverview;
