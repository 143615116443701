import clsx from "clsx";

interface TipsItemCardProps {
  color: "primary" | "secondary";
  description: string;
}
function TipsItemCard({ color, description }: TipsItemCardProps) {
  return (
    <div className={clsx("relative bg-whitdse mt-4 rounded-lg rounded-bl-3xl rounded-tr-3xl p-3 bg-slate-100")}>
      <span
        className={clsx(
          "absolute w-[25px] h-[25px] -top-3 -left-3 -z-10 text-white p-1 text-2xl font-bold rounded-md leading-[0] ",
          {
            "bg-secondary": color === "secondary",
            "bg-primary": color === "primary"
          }
        )}
      >
        .
      </span>
      {description}
    </div>
  );
}

export default TipsItemCard;
