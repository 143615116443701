import clsx from "clsx";
import React from "react";
import styled from "styled-components";
import { ColorType } from "../button";

const LoaderWrapper = styled.div`
  width: 4em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  div {
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    background-color: #fff;
  }

  &.primary {
    div {
      background-color: var(--primary-color);
    }
  }

  div:nth-of-type(1) {
    transform: translateX(-100%);
    animation: left-swing 0.5s ease-in alternate infinite;
  }

  div:nth-of-type(3) {
    transform: translateX(-95%);
    animation: right-swing 0.5s ease-out alternate infinite;
  }

  @keyframes left-swing {
    50%,
    100% {
      transform: translateX(95%);
    }
  }

  @keyframes right-swing {
    50% {
      transform: translateX(-95%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

interface LoaderProps {
  color?: ColorType;
}

const Loader = ({ color }: LoaderProps) => (
  <LoaderWrapper className={clsx("balls", { [color]: !!color })}>
    <div />
    <div />
    <div />
  </LoaderWrapper>
);

export default Loader;

Loader.defaultProps = {
  color: "default"
};
