import { useQuery } from "@apollo/client";
import { Link, Router } from "@reach/router";
import { Modal } from "antd";
import Button from "app/components/ui/button";
import CircularLoader from "app/components/ui/loader/circular";
import { useStore } from "app/contexts/store-context";
import { useUser } from "app/contexts/user-context";
import { CURRENT_USER } from "app/graphql/auth/queries";
import { TEMPLATES } from "app/graphql/templates/queries";
import { useDownload } from "app/hooks/use-download";
import { SaveIcon } from "app/icons";
import { confirmDownloadingAtom, resumeDownloadingAtom, templatesAtom } from "app/store/template";
import { useAtom } from "jotai";
import { useEffect } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { ThemeProvider } from "styled-components";
import Dashboard from "./Dashboard";
import Editor from "./Editor";
import New from "./New";
import Account from "./account";
import Subscriptions from "./subscriptions";

const Main = (): JSX.Element => {
  const {
    state: { theme, resume }
  } = useStore();

  const { user, setUser } = useUser();
  const { data: templates } = useQuery(TEMPLATES);

  const [, setTemplates] = useAtom(templatesAtom);
  const [resumeDownloading, setInfos] = useAtom(resumeDownloadingAtom);
  const [confirmDownload, setConfirm] = useAtom(confirmDownloadingAtom);
  useQuery(CURRENT_USER, {
    onCompleted(data) {
      setUser(data.result);
    }
  });
  const { downloadResume } = useDownload();

  useEffect(() => {
    if (templates?.result?.length) {
      setTemplates(templates.result);
    }
  }, [templates, setTemplates]);

  const handleAfterClose = () => {
    setInfos((prev) => ({ ...prev, beingDownloaded: false, downloaded: false }));
  };

  const handleCloseModal = () => {
    if (!resumeDownloading.beingDownloaded) {
      setInfos((prev) => ({ ...prev, beingDownloaded: false, downloaded: false }));
    }
  };
  const closeConfirmModal = () => {
    setConfirm(false);
  };

  const handleDownload = () => {
    if (resume.id) {
      setConfirm(false);
      downloadResume(resume);
    }
  };

  const navigateToSubsciptionPage = () => {
    window.location.pathname = "/subscriptions";
  };

  return (
    <ThemeProvider theme={theme || {}}>
      <Router>
        <Dashboard path="/" />
        <Account path="/account" />
        <New path="/new" />
        <Editor path="/editor/*" />
        <Subscriptions path="subscriptions" />
      </Router>

      <Modal
        closable={false}
        closeIcon={null}
        footer={null}
        visible={resumeDownloading.beingDownloaded || resumeDownloading.downloaded}
        afterClose={handleAfterClose}
        onCancel={handleCloseModal}
        width={400}
      >
        <div className="flex gap-3 mb-4">
          {resumeDownloading.beingDownloaded && (
            <>
              <CircularLoader />
              <span className="text-lg font-semibold">En cours de téléchargement...</span>
            </>
          )}

          {resumeDownloading.downloaded && (
            <div>
              <span className="text-lg font-semibold">CV téléchargé ✅</span>
            </div>
          )}
        </div>
        <span className="font-semibold">Vous avez un ami qui a besoin d&apos;un CV ? </span>

        <div className="flex gap-5 mt-5 ">
          <WhatsappShareButton title="Taf Taf CV" separator=" - " url="https://taftafcv.com/">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <FacebookShareButton quote="Taf Taf CV" url="https://taftafcv.com/" hashtag="#taftafcv">
            <span className="share-icon">
              <FacebookIcon size={32} round />
            </span>
          </FacebookShareButton>
          <TwitterShareButton title="Taf Taf CV" via="" url="https://taftafcv.com/" hashtags={["taftafcv"]}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton title="Taf Taf CV" source="Taf Taf CV" url="https://taftafcv.com/">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </Modal>

      <Modal
        // closable={false}
        closable
        closeIcon={null}
        footer={null}
        visible={confirmDownload}
        onCancel={closeConfirmModal}
        // afterClose={handleAfterClose}
        // onCancel={handleCloseModal}
      >
        <div className="flex flex-col items-center gap-5 text-center">
          {user?.credits <= 0 ? (
            <>
              <span className="font-medium">Vous n&apos;avez aucun crédit de téléchargement</span>

              <Button className="!p-4" variant="contained" onClick={navigateToSubsciptionPage}>
                Acheter
              </Button>
            </>
          ) : (
            <>
              <span className="text-lg">Télécharger la version finale de votre cv </span>
              <Button
                onClick={handleDownload}
                color="primary"
                variant="contained"
                startIcon={<SaveIcon color="#fff" />}
              >
                Télécharger
              </Button>
            </>
          )}
        </div>
      </Modal>
    </ThemeProvider>
  );
};
export default Main;
