import { RouteComponentProps } from "@reach/router";
import Progress from "app/components/ui/progress";
import { useStore } from "app/contexts/store-context";
import { Reference } from "generated/graphql";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReferenceInputs from "./components/reference-inputs";

interface HeaderProps {
  progression: Reference;
  index: number;
}

const Header = ({ progression, index }: HeaderProps) => (
  <span className="title">{progression.fullname ? progression.fullname : `[Référence ${index + 1}]`}</span>
);

const defaultValue: Reference = {
  fullname: "",
  relation: "",
  title: "",
  company: "",
  email: "",
  phone: ""
};

function References(props: RouteComponentProps) {
  const [activeKey, setActiveKey] = useState<string>();
  const {
    state: { resume },
    dispatch
  } = useStore();

  const handleNew = () => {
    const newEntry = {
      ...defaultValue,
      uid: uuidv4()
    };
    dispatch({
      type: "resume",
      payload: { ...resume, references: [...(resume.references || []), newEntry] }
    });
    setActiveKey(newEntry.uid);
  };

  const handleDeletion = (e: any) => {
    e.stopPropagation();
    const {
      dataset: { key = null }
    } = e.currentTarget;

    if (key) {
      dispatch({
        type: "resume",
        payload: { ...resume, references: [...resume.references.filter((item) => item.uid !== key)] }
      });
    }
  };

  const handleCollapseChange = (key: string) => {
    setActiveKey(key);
  };

  const handleChange = (key: string, value: string, index: number) => {
    const experienceToUpdate = { ...resume.references[index] };

    if (experienceToUpdate) {
      experienceToUpdate[key] = value;

      const oldData = [...resume.references];
      oldData[index] = experienceToUpdate;

      dispatch({
        type: "resume",
        payload: { ...resume, references: [...oldData] }
      });
    }
  };

  return (
    <Progress
      addProgressButtonLabel="Ajouter une référence"
      header={Header}
      content={ReferenceInputs}
      currentKey={activeKey}
      progressions={resume.references || []}
      onChange={handleChange}
      onNew={handleNew}
      onToggle={handleCollapseChange}
      onDelete={handleDeletion}
    />
  );
}

export default References;
