import styled from "styled-components";

export const StyledHeader = styled.div`
  // align-items: center;
  // background-color: #502acd;
  // color: #fff;
  // display: flex;
  // justify-content: space-between;
  // position: fixed;
  // right: 0;
  // width: 100%;
  // padding: 12px 24px;

  // .left {
  //   display: flex;
  //   align-items: center;

  //   button {
  //     color: #fff;
  //     font-size: 14px;
  //     font-weight: 500;
  //   }

  //   path {
  //     fill: #fff;
  //   }
  // }

  // .right {
  //   display: flex;
  //   gap: 10px;
  //   align-items: center;

  //   button {
  //     width: 180px;
  //   }

  //   button.download {
  //     background: #fff;
  //     color: #000;
  //     height: 42px;
  //     font-weight: 500;
  //     border-color: #fff;
  //   }

  //   .close {
  //     background-color: transparent;
  //     border: 0;
  //     padding: 0;
  //     width: auto;
  //   }
  // }

  .profile-btn {
    cursor: pointer;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;

    :hover {
      background-color: rgb(101 218 255 / 61%);
    }
  }
`;
