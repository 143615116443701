import { gql } from "@apollo/client";

export const RESUME_FRAGMENT = gql`
  fragment ResumeFragment on Resume {
    id
    title
    profile
    interests
    picture {
      originalUrl
      croppedUrl
    }
    personalInfo {
      title
      firstname
      lastname
      email
      phone
      address
      drivingLicence
      birthdate
      citizenship
      civilStatus
    }

    experiences {
      id
      _id
      uid
      jobTitle
      employer
      description
      location
      startDate
      endDate
      isCurrent
      link
    }

    projects {
      id
      _id
      uid
      title
      description
      startDate
      endDate
      isCurrent
      link
    }

    educations {
      id
      _id
      uid
      degree
      school
      location
      startDate
      endDate
      isCurrent
      description
      link
    }

    references {
      id
      _id
      uid
      fullname
      email
      phone
      title
      relation
      company
    }

    certificates {
      id
      _id
      uid
      name
      organization
      issueDate
      link
    }

    skills {
      id
      uid
      area
      skills {
        id
        uid
        name
        level
      }
    }

    languages {
      id
      uid
      name
      level
    }

    strengths {
      id
      uid
      name
    }

    awards {
      id
      uid
      name
      date
      issuer
      description
    }

    template {
      id
      uid
      preview
      name
      component
      customizable
      settings {
        colors {
          bg
          text
        }
        fontFamily
        fontSize
        dateFormat
      }
    }

    sections {
      id
      name
      label
      isVisible
    }

    settings {
      colors {
        bg
        text
      }
      fontFamily
      fontSize
      dateFormat
      groupSkillsByArea
      picture {
        show
        size
        border
      }
    }

    updatedAt
  }
`;
