import React from "react";
import AppBar from "../app-bar";

export interface LayoutProps {
  children?: React.ReactNode;
}

const MainLayout = ({ children }: LayoutProps): JSX.Element => (
  <div className="app-layout">
    <AppBar />
    <div className="flex-1 main">{children}</div>
  </div>
);

MainLayout.defaultProps = {
  children: null
};

export default MainLayout;
export * from "./editor";
