import { Form, Input } from "antd";
import Button from "app/components/ui/button";
import Loader from "app/components/ui/loader";
import { ILoginFormValues, LoginFormProps } from "../types";

export const ResetForm = ({ submitted, onSubmit }: LoginFormProps): JSX.Element => {
  const handleSubmit = (values: ILoginFormValues) => {
    onSubmit(values);
  };

  return (
    <div>
      <Form name="login-form" onFinish={handleSubmit} layout="vertical">
        <div className="space-y-5">
          <Form.Item
            label="Mot de passe"
            name="password"
            rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
          >
            <Input.Password type="password" placeholder="Votre mot de passe" />
          </Form.Item>

          <Form.Item
            label="Confirmer votre mot de passe"
            name="conf-password"
            rules={[
              { required: true, message: "Veuillez confirmer votre mot de passe!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Les deux mots de passe que vous avez saisis ne correspondent pas!"));
                }
              })
            ]}
          >
            <Input.Password visibilityToggle={false} type="password" placeholder="Confirmer votre mot de passe" />
          </Form.Item>

          <Button
            disabled={submitted}
            loading={submitted}
            color="primary"
            variant="contained"
            style={{ height: 48 }}
            block
            type="submit"
          >
            {submitted ? <Loader /> : "Réinitialiser"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ResetForm;
