import client from "app/config/apollo";

// export const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;
export const TOKEN_KEY = "REACT_APP_TOKEN_KEY";

const STORAGE = window.localStorage;

export const authManager = {
  logout() {
    this.removeToken();
    client.resetStore();
    window.location.href = "/login";
  },
  storeToken(token: any): void {
    if (token) {
      STORAGE.setItem(TOKEN_KEY, JSON.stringify(token));
    }
  },
  readToken(): any {
    return JSON.parse(STORAGE.getItem(TOKEN_KEY));
  },
  removeToken(): void {
    STORAGE.removeItem(TOKEN_KEY);
  }
};
