import { useMutation } from "@apollo/client";
import { navigate, RouteComponentProps } from "@reach/router";
import { Tabs } from "antd";
import Button from "app/components/ui/button";
import { CREATE_RESUME } from "app/graphql/resumes/mutations";
import { PlusIcon } from "app/icons";
import { Template } from "generated/graphql";
import { useState } from "react";
import ResumesList from "../resumes/list";
import TemplateSelection from "../template-selection/grid";
import { StyledBody } from "./styles";

const { TabPane } = Tabs;

const INFO_BY_TAB = {
  "1": {
    buttonLabel: "Nouveau CV"
  },
  "2": {
    buttonLabel: "Nouvelle lettre"
  }
};

const Dashboard = (props: RouteComponentProps) => {
  const [createResume] = useMutation(CREATE_RESUME);
  const [shouldShowModal, toggleModal] = useState(false);
  const [currentTab, setCurrentTab] = useState("1");

  const handleNewResume = () => {
    switch (currentTab) {
      case "1":
        toggleModal(true);
        break;
      case "2":
        navigate(`/editor/cvltr/fake-id`);
        break;

      default:
        break;
    }
  };

  const handleConfirm = (selectedTemplate: Template) => {
    createResume({ variables: { templateId: selectedTemplate.id } }).then((res) => {
      navigate(`/editor/rsme/${res.data.result.id}/presentation`);
    });
  };

  const handleCancel = () => {
    toggleModal(false);
  };

  const handleTabChange = (activeKey: string) => {
    setCurrentTab(activeKey);
  };

  return (
    <StyledBody className="p-5 lg:px-[5rem] xl:px-[10rem]">
      <h1 className="title">Tableau de bord</h1>
      <Tabs
        tabBarExtraContent={
          <Button
            variant="contained"
            startIcon={<PlusIcon color="#fff" />}
            onClick={handleNewResume}
            color="primary"
            block
            className="min-w-[40px]"
          >
            <span className="hidden sm:block">{INFO_BY_TAB[currentTab].buttonLabel}</span>
          </Button>
        }
        activeKey={currentTab}
        onChange={handleTabChange}
      >
        <TabPane className="relative" tab="Mes CV" key="1">
          <ResumesList onNew={handleNewResume} />
        </TabPane>

        {/* <TabPane className="relative" tab="Mes Lettres" key="2">
        </TabPane> */}
      </Tabs>

      <TemplateSelection visible={shouldShowModal} onCancel={handleCancel} onConfirm={handleConfirm} />
    </StyledBody>
  );
};

export default Dashboard;
