import { Button, Col, Row, Select } from "antd";
import { TrashIcon } from "app/icons";
import Input from "../input";
import Label from "../label";
import { StyledSkillItem } from "./styles";
import { SkillItemProps } from "./types";

const SkillItem = ({ label, levelLabels, index, skill, onDelete, onChange }: SkillItemProps): JSX.Element => {
  const handleDeletion = (e) => {
    e.stopPropagation();
    onDelete(skill, index);
  };

  const handleInputChange = (e) => {
    onChange("name", e.target.value, index);
  };
  return (
    <StyledSkillItem className="border-[1px]">
      <Row className="w-full" gutter={[10, 0]}>
        <Col span={12}>
          <Label>{label.name}</Label>
          <Input name="jobTitle" value={skill.name} onChange={handleInputChange} />
        </Col>
        <Col span={11}>
          <Label>{label.level}</Label>
          <Select
            value={skill.level}
            onChange={(value) => {
              onChange("level", value, index);
            }}
            options={levelLabels}
            className="w-full"
          />
        </Col>
        <Col span={1}>
          <div className="flex items-center h-full">
            <Button className="close" onClick={handleDeletion}>
              <TrashIcon />
            </Button>
          </div>
        </Col>
      </Row>
    </StyledSkillItem>
  );
};

export default SkillItem;
