import { Input } from "antd";
import Label from "app/components/ui/label";
import { ProgressEntryProps } from "app/components/ui/progress/types";
import SkillsList from "app/components/ui/skills";
import { SkillLevelLabelArray } from "app/constants";
import { Skill, SkillArea } from "generated/graphql";
import { v4 as uuidv4 } from "uuid";

export const SkillInputs = ({ progression: area, index, onChange }: ProgressEntryProps<SkillArea>) => {
  const handleDeletion = (value: Skill, key: number) => {
    onChange("skills", [...area.skills.filter((item, index) => index !== Number(key))], index);
  };

  const handleChange = (key: string, value: string | number | boolean | moment.Moment, childIndex: number) => {
    const entryToUpdate = { ...area.skills[childIndex] };
    if (entryToUpdate) {
      entryToUpdate[key] = value;
      const oldData = [...area.skills];
      oldData[childIndex] = entryToUpdate;
      onChange("skills", [...oldData], index);
    }
  };

  const handleName = (e) => {
    onChange("area", e.target.value, index);
  };

  const handleNew = () => {
    const newEntry: Skill = {
      uid: uuidv4(),
      name: "",
      level: 0
    };

    onChange("skills", [...area.skills, newEntry], index);
  };

  return (
    <div>
      <Label>Domaine de compétence</Label>
      <Input value={area.area} onChange={handleName} placeholder="Entrer un domaine de compétence" />
      <br />
      <br />
      <SkillsList
        addingButtonLabel="Ajouter une compétence"
        label={{ name: "Compétence", level: "Niveau" }}
        levelLabels={SkillLevelLabelArray}
        skills={area.skills}
        onDeleteSkill={handleDeletion}
        onChange={handleChange}
        onNew={handleNew}
      />
    </div>
  );
};

export default SkillInputs;
