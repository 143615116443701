import { RouteComponentProps } from "@reach/router";
import Progress from "app/components/ui/progress";
import { useStore } from "app/contexts/store-context";
import { formatDate } from "app/utils/date";
import { Experience } from "generated/graphql";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ExperienceInputs from "./components/experience-inputs";

const defaultValue: Experience = {
  jobTitle: "",
  description: "",
  isCurrent: false
};

interface DateDisplayingProps {
  startDate: string;
  endDate: string;
  isCurrent: boolean;
  format?: string;
}

const formatDisplayingDate = ({ startDate, endDate, isCurrent, format }: DateDisplayingProps) => {
  let str = "";
  if (startDate) {
    str += formatDate(startDate.toString(), format);
  }

  if (endDate || isCurrent) {
    if (startDate) {
      str += " - ";
    }

    if (isCurrent) {
      str += "Présent";
    } else {
      str += formatDate(endDate.toString(), format);
    }
  }

  return str;
};

export const DateDisplaying = ({ ...props }: DateDisplayingProps): JSX.Element => (
  <span className="capitalize">{formatDisplayingDate(props)}</span>
);

DateDisplaying.defaultProps = {
  format: "MM/yyyy"
};

export interface HeaderProps<T> {
  progression: T;
  index: number;
}

const Header = ({ progression, index }: HeaderProps<Experience>) => (
  <>
    <span className="title">{progression.jobTitle ? progression.jobTitle : `[Poste ${index + 1}]`}</span>
    <DateDisplaying startDate={progression.startDate} endDate={progression.endDate} isCurrent={progression.isCurrent} />
  </>
);

const ExperienceSection = (props: RouteComponentProps): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>();
  const {
    state: { resume },
    dispatch
  } = useStore();

  const onNew = () => {
    const newExperience = {
      ...defaultValue,
      uid: uuidv4()
    };
    dispatch({
      type: "resume",
      payload: { ...resume, experiences: [...resume.experiences, newExperience] }
    });
    setActiveKey(newExperience.uid);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const {
      dataset: { key = null }
    } = e.currentTarget;

    if (key) {
      dispatch({
        type: "resume",
        payload: { ...resume, experiences: [...resume.experiences.filter((item) => item.uid !== key)] }
      });
    }
  };

  const onCollapseChange = (key: string) => {
    setActiveKey(key);
  };

  const handleChange = (key: string, value: string, index: number) => {
    const experienceToUpdate = { ...resume.experiences[index] };

    if (experienceToUpdate) {
      experienceToUpdate[key] = value;

      const oldData = [...resume.experiences];
      oldData[index] = experienceToUpdate;

      dispatch({
        type: "resume",
        payload: { ...resume, experiences: [...oldData] }
      });
    }
  };

  return (
    <Progress
      addProgressButtonLabel="Ajouter une expérience"
      header={Header}
      content={ExperienceInputs}
      currentKey={activeKey}
      progressions={resume.experiences}
      onChange={handleChange}
      onNew={onNew}
      onToggle={onCollapseChange}
      onDelete={onDelete}
    />
  );
};

export default ExperienceSection;
