import { loadTemplate } from "app/utils";
import { Template as ITemplate } from "generated/graphql";
import React, { useEffect, useState } from "react";

const useTemplate = (template: ITemplate): React.LazyExoticComponent<React.ComponentType<any>> => {
  const [Template, setTemplate] = useState<React.LazyExoticComponent<React.ComponentType<any>>>();

  useEffect(() => {
    if (template) {
      (async () => {
        const loadedTemplate = await loadTemplate(template.component);
        // const loadedTemplate = await loadTemplate("TemplateLoading");
        setTemplate(loadedTemplate);
      })();
    }
  }, [template]);

  return Template;
};

export default useTemplate;
