import styled from "styled-components";

export const StyledWrapper = styled.div<{ row: number }>`
  position: relative;
  width: 240px;
  flex: 0 0 auto;
  background-color: #f6faff;
  overflow-y: auto;

  .steps {
    height: 500px;
  }

  .more-options {
    position: absolute;
    box-shadow: 0 -1rem 2.4rem -2.4rem rgba(0, 0, 0, 0.9);
    bottom: 0;
    width: 100%;
    height: 80px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 100%;
      font-weight: 500;
      border-color: #502acd;
      color: #502acd;

      path {
        stroke: #502acd;
      }
    }
  }

  .steps-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(${({ row }) => row}, 60px);

    .step-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      flex: 1;

      &.current {
        background-color: #fff;
      }

      :hover:not(.current) {
        background-color: #502acd;
        color: #fff;
      }

      .icon {
        display: flex;
        flex: 0 0 auto;
        width: 40px;
        justify-content: center;

        svg {
          width: 20px;
        }
      }

      .info {
        flex: 1;
        display: flex;
        text-align: left;
      }
    }
  }
`;
