import { PlusSmallIcon } from "app/icons";
import { Skill } from "generated/graphql";
import { SytledAddingButton } from "../progress";
import SkillItem from "./item";
import { StyledSkillsList } from "./styles";
import { SkillsListProps } from "./types";

const SkillsList = ({
  label,
  levelLabels,
  skills,
  addingButtonLabel,
  onSelectSkill,
  onDeleteSkill,
  onChange,
  onNew
}: SkillsListProps): JSX.Element => {
  const onDelete = (item: Skill, index: number) => {
    if (onDeleteSkill) onDeleteSkill(item, index);
  };

  const onSelect = (item: Skill, index: number) => {
    if (onSelectSkill) onSelectSkill(item, index);
  };

  return (
    <StyledSkillsList>
      {skills.map((skill, index) => (
        <SkillItem
          label={label}
          levelLabels={levelLabels}
          key={skill.id}
          index={index}
          skill={skill}
          onSelect={onSelect}
          onDelete={onDelete}
          onChange={onChange}
        />
      ))}

      <SytledAddingButton
        className=" border-primary w-full border-dashed"
        icon={<PlusSmallIcon className="w-4" color="#fff" />}
        onClick={onNew}
      >
        {addingButtonLabel}
      </SytledAddingButton>
    </StyledSkillsList>
  );
};

export default SkillsList;
