import { gql } from "@apollo/client";

export const CURRENT_USER = gql`
  query currentUser {
    result: currentUser {
      id
      gender
      lastname
      firstname
      email
      phone
      profile
      credits
    }
  }
`;
