import { RouteComponentProps } from "@reach/router";
import { Checkbox } from "antd";
import Progress from "app/components/ui/progress";
import { useStore } from "app/contexts/store-context";
import { SkillArea } from "generated/graphql";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { HeaderProps } from "../experiences";
import SkillInputs from "./skill-inputs";

export const SkillLabel = {
  1: "Débutant",
  2: "Intermédiaire",
  3: "Avancé",
  4: "Expert",
  5: "Maîtrise"
};

const defaultValue: SkillArea = {
  area: "",
  skills: []
};

const Header = ({ progression, index }: HeaderProps<SkillArea>) => (
  <span className="title">{progression.area ? progression.area : `[Domaine de compétence ${index + 1}]`}</span>
);

const Skills = (_: RouteComponentProps): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>();

  const {
    state: {
      resume: { skills, ...rest }
    },
    dispatch
  } = useStore();

  const handleChange = (key: string, value: string | number | boolean | moment.Moment, index: number) => {
    const entryToUpdate = { ...skills[index] };
    if (entryToUpdate) {
      entryToUpdate[key] = value;
      const oldData = [...skills];
      oldData[index] = entryToUpdate;

      dispatch({
        type: "resume",
        payload: { ...rest, skills: [...oldData] }
      });
    }
  };

  const onNew = () => {
    const newEntries = {
      ...defaultValue,
      uid: uuidv4()
    };
    dispatch({
      type: "resume",
      payload: { ...rest, skills: [...skills, newEntries] }
    });
    setActiveKey(newEntries.uid);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const {
      dataset: { key = null }
    } = e.currentTarget;
    if (key) {
      dispatch({
        type: "resume",
        payload: { ...rest, skills: [...skills.filter((item) => item.uid !== key)] }
      });
    }
  };

  const onCollapseChange = (key: string) => {
    setActiveKey(key);
  };

  const handleCheckbox = () => {
    dispatch({
      type: "resume",
      payload: {
        ...rest,
        skills,
        settings: {
          ...rest.settings,
          groupSkillsByArea: !rest.settings.groupSkillsByArea
        }
      }
    });
  };

  return (
    <div>
      <div className="pb-4">
        <Checkbox checked={rest.settings?.groupSkillsByArea} onChange={handleCheckbox}>
          <i>Afficher les domaines de compétence</i>
        </Checkbox>
      </div>
      <Progress
        addProgressButtonLabel="Ajouter un domaine de compétence"
        header={Header}
        content={SkillInputs}
        currentKey={activeKey}
        progressions={skills}
        onChange={handleChange}
        onNew={onNew}
        onToggle={onCollapseChange}
        onDelete={onDelete}
      />
    </div>
  );
};

export default Skills;
