import { RouteComponentProps } from "@reach/router";
import { Button, Checkbox, Collapse, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import RelookingLayout from "app/components/ui/layout/relooking";
import { StyledProgressItem } from "app/components/ui/progress/styles";
import { useStore } from "app/contexts/store-context";
import useTemplate from "app/hooks/useTemplate";
import { formatDate } from "app/utils/date";
import clsx from "clsx";
import React, { ReactNode, useMemo } from "react";

import Page from "../preview/page";
import { StyledWrapper } from "./styles";

import ColorsList from "./colors-list";

const { Panel } = Collapse;

const FONT_FAMILIES = ["Arial", "Calibri", "Helvetica", "Times New Roman"];
const FONT_SIZE = ["XSS", "XS", "S", "M", "L", "XL", "XLL"];

const DATE_FORMATS = [
  {
    format: "MM/yyyy",
    value: "10/10/1997",
    label: "mm/aaaa"
  },
  {
    format: "MM-yyyy",
    value: "10/10/1997",
    label: "mm-aaaa"
  },
  {
    format: "MM.yyyy",
    value: "10/10/1997",
    label: "mm.aaaa"
  },
  {
    format: "yyyy/MM",
    value: "10/10/1997",
    label: "aaaa/mm"
  }
];

function Section({ title, children }: { title: string; children: ReactNode }) {
  return (
    <section className="p-3 border-[1px] rounded-lg">
      <div>
        <span className="text-lg font-semibold">{title}</span>
      </div>

      <div className="py-4">{children}</div>
    </section>
  );
}

const PICTURE_SIZE = ["xs", "s", "m", "l", "xl"];

const Customization = (props: RouteComponentProps) => {
  const {
    state: { resume, theme },
    dispatch
  } = useStore() as any;
  const Template = useTemplate(resume.template);

  const settings = useMemo(() => resume?.settings || resume?.template?.settings, [resume]);

  const handleColorChange = ({ type, color, index }) => {
    const { colors } = settings;
    const data = [...colors[type]];
    data[index] = color;
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          colors: {
            ...colors,
            [type]: data
          }
        }
      }
    });
  };

  const handleFontFamily = (value) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          fontFamily: value
        }
      }
    });
  };

  const handleFontSize = (value) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          fontSize: value
        }
      }
    });
  };

  const handleDateFormat = (format: string) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          dateFormat: format
        }
      }
    });
  };

  const handlePictureSetting = (e: CheckboxChangeEvent) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          picture: {
            ...settings.picture,
            show: e.target.checked
          }
        }
      }
    });
  };

  const handlePictureSettingd = (e) => {
    const {
      dataset: { key = null, value = null }
    } = e.currentTarget;

    dispatch({
      type: "resume",
      payload: {
        ...resume,
        settings: {
          ...settings,
          picture: {
            ...settings.picture,
            [key]: value
          }
        }
      }
    });
  };

  return (
    <RelookingLayout>
      <StyledWrapper className="p-2 flex flex-col lg:grid grid-cols-[25%_auto]">
        <div className="p-5 overflow-auto bg-white border-2 rounded-lg shadow-md lg:block">
          <div className="mb-4 text-center">
            <h4 className="text-xl font-bold text-center">Personnalisation</h4>
            <span className="text-xs ">
              Ajustez les couleurs, les tailles, les polices... pour avoir un style personnel
            </span>
          </div>

          <div className="space-y-4">
            <Section title="Photo">
              <div className="space-y-4">
                <div className="space-y-2">
                  <Checkbox name="isCurrent" checked={settings?.picture?.show} onChange={handlePictureSetting}>
                    Afficher
                  </Checkbox>
                </div>

                {settings?.picture?.show && (
                  <>
                    <div className="space-y-2">
                      <span className="text-xs font-semibold">Taille</span>

                      <div className="">
                        <div className="flex flex-wrap gap-2">
                          {PICTURE_SIZE.map((size) => (
                            <Button
                              ghost={size === settings?.picture?.size}
                              type={size === settings?.picture?.size ? "primary" : "default"}
                              data-key="size"
                              data-value={size}
                              className="w-[60px] h-[40px] uppercase text-[14px]"
                              onClick={handlePictureSettingd}
                            >
                              {size}
                            </Button>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <span className="text-xs font-semibold">Bords</span>

                      <div className="flex flex-wrap gap-2">
                        <div>
                          <Button
                            ghost={settings?.picture?.border === "square"}
                            type={settings?.picture?.border === "square" ? "primary" : "default"}
                            data-key="border"
                            data-value="square"
                            className="h-[40px] w-[60px]"
                            onClick={handlePictureSettingd}
                          >
                            <span className="w-[20px] h-[20px] bg-primary/90 " />
                          </Button>
                        </div>
                        <div>
                          <Button
                            ghost={settings?.picture?.border === "circle"}
                            type={settings?.picture?.border === "circle" ? "primary" : "default"}
                            data-key="border"
                            data-value="circle"
                            className="h-[40px] w-[60px]"
                            onClick={handlePictureSettingd}
                          >
                            <span className="w-[20px] h-[20px] bg-primary/90 rounded-full" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Section>

            <Section title="Couleurs">
              <div className="space-y-4">
                <div className="space-y-2">
                  <span className="text-xs font-semibold">Couleurs des blocs</span>

                  <div className="">
                    <ColorsList type="bg" colors={settings?.colors?.bg || []} onClick={handleColorChange} />
                  </div>
                </div>

                <div className="space-y-2">
                  <span className="text-xs font-semibold">Couleurs des textes</span>

                  <div className="">
                    <ColorsList type="text" colors={settings?.colors?.text || []} onClick={handleColorChange} />
                  </div>
                </div>
              </div>
            </Section>

            <Section title="Police">
              <div className="space-y-4">
                <div className="space-y-2">
                  <span className="text-xs font-semibold">Police de caractère</span>

                  <div className="">
                    <Select className="w-full" value={settings?.fontFamily} onChange={handleFontFamily}>
                      {FONT_FAMILIES.map((font) => (
                        <Select.Option key={font}>{font}</Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <br />
                <div className="!mt-4 space-y-2">
                  <div>
                    <span className="text-xs font-semibold">Taille de police</span>
                  </div>

                  <div className="">
                    <Select className="w-full" value={settings?.fontSize} onChange={handleFontSize}>
                      {FONT_SIZE.map((size) => (
                        <Select.Option key={size}>{size}</Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </Section>

            <Section title="Date">
              <div className="space-y-2">
                <span className="text-xs font-semibold">Comment voulez-vous afficher les dates sur votre CV ?</span>

                <div className="">
                  <Select className="w-full" value={settings?.dateFormat} onChange={handleDateFormat}>
                    {DATE_FORMATS.map((format) => (
                      <Select.Option key={format.label} value={format.format}>
                        {format.label}{" "}
                        <span className="text-xs text-salte-10 text-slate-600">
                          {" "}
                          ( ex: {formatDate(format.value, format.format)})
                        </span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Section>
          </div>
        </div>

        <div id="preview-wrapper" className="justify-center hidden content lg:flex">
          <Page>
            <React.Suspense fallback="">
              {Template &&
                React.cloneElement(<Template />, {
                  resume,

                  sections: resume.sections?.reduce((prev, curr) => ({ ...prev, [curr.name]: curr }), {}),

                  settings: resume?.settings || resume?.template?.settings
                })}
            </React.Suspense>
          </Page>
        </div>
      </StyledWrapper>
    </RelookingLayout>
  );
};

export default Customization;
