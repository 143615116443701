import { RouteComponentProps, Router } from "@reach/router";
import ResumeEditor from "./resume";
import EditorForm from "./letter";

function Editor(props: RouteComponentProps) {
  return (
    <Router>
      <ResumeEditor path="rsme/:id/*" />
      <EditorForm path="cvltr/:id/*" />
    </Router>
  );
}

export default Editor;
