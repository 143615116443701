import { RouteComponentProps } from "@reach/router";
import { useStore } from "app/contexts/store-context";
import TextEditor from "app/main/text-editor";

const Profile = (props: RouteComponentProps): JSX.Element => {
  const {
    state: { resume },
    dispatch
  } = useStore();

  const handleChange = (text: string) => {
    dispatch({
      type: "resume",
      payload: {
        ...resume,
        profile: text
      }
    });
  };

  return (
    <div className="space-y-5">
      <TextEditor value={resume?.profile} placeholder="Ajouter des informations sur vous" onChange={handleChange} />
    </div>
  );
};

export default Profile;
