import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { authManager } from "../utils";

const Logout: React.FC<RouteComponentProps> = (): JSX.Element => {
  useEffect(() => {
    authManager.logout();
  }, []);

  return null;
};

export default Logout;
