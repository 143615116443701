import { ColorPicker } from "antd";
import styled from "styled-components";

const StyledColorWrapper = styled.div`
  .ant-color-picker-trigger {
    width: 60px;
    height: 40px;

    .ant-color-picker-color-block {
      width: 100%;
      height: 100%;
    }
  }
`;

interface ColorsListProps {
  type: string;
  colors: string[];
  onClick: (value: { type: string; color: string; index: number }) => void;
}

function ColorsList({ type, colors, onClick }: ColorsListProps) {
  return (
    <StyledColorWrapper className="flex flex-wrap gap-2">
      {colors.map((color, index) => (
        <ColorPicker
          // eslint-disable-next-line react/no-array-index-key
          key={`${type}-${index}`}
          defaultValue={color}
          onChange={(_, hex) => {
            onClick({ type, color: hex, index: Number(index) });
          }}
        />
      ))}
    </StyledColorWrapper>
  );
}

export default ColorsList;
