import { gql } from "@apollo/client";
import { RESUME_FRAGMENT } from "./fragments";

export const CREATE_RESUME = gql`
  mutation createResume($templateId: ID) {
    result: createResume(templateId: $templateId) {
      id
    }
  }
`;

export const UPDATE_RESUME = gql`
  mutation updateResume($id: ID!, $input: UpdateResumeInput!) {
    result: updateResume(id: $id, input: $input) {
      ...ResumeFragment
    }
  }
  ${RESUME_FRAGMENT}
`;

export const DELETE_RESUME = gql`
  mutation deleteResume($id: ID!) {
    deleteResume(resumeId: $id)
  }
`;

export const DUPLICATE_RESUME = gql`
  mutation duplicateResume($id: ID!, $newTitle: String!) {
    duplicateResume(resumeId: $id, newTitle: $newTitle) {
      id
    }
  }
`;

export const DOWNLOAD_RESUME = gql`
  mutation downloadResume($id: ID!) {
    result: downloadResume(resumeId: $id)
  }
`;

export const DELETE_RESUME_PICTURE = gql`
  mutation deleteResumePicture($id: ID!) {
    result: deleteResumePicture(resumeId: $id) {
      _id
    }
  }
`;
