import { RouteComponentProps } from "@reach/router";
import Progress from "app/components/ui/progress";
import { useStore } from "app/contexts/store-context";
import { Education } from "generated/graphql";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DateDisplaying } from "../experiences";
import EducationInputs from "./components/education-inputs";

const defaultValue: Education = {
  fieldOfStudy: "",
  description: "",
  isCurrent: false
};

const Header = ({ progression, index }: any) => (
  <>
    <span className="title">{progression.degree ? progression.degree : `[Diplôme ${index + 1}]`}</span>
    <DateDisplaying startDate={progression.startDate} endDate={progression.endDate} isCurrent={progression.isCurrent} />
  </>
);

const EducationSection = (props: RouteComponentProps): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>();
  const {
    state: {
      resume: { educations, ...rest }
    },
    dispatch
  } = useStore();

  const onNew = () => {
    const newEducation = {
      ...defaultValue,
      uid: uuidv4()
    };
    // setEducations([...educations, newEducation as IEducation]);
    dispatch({
      type: "resume",
      payload: { ...rest, educations: [...educations, newEducation] }
    });
    setActiveKey(newEducation.uid);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const {
      dataset: { key = null }
    } = e.currentTarget;

    if (key) {
      dispatch({
        type: "resume",
        payload: { ...rest, educations: [...educations.filter((item) => item.uid !== key)] }
      });
    }
  };

  const onCollapseChange = (key: string | string[]) => {
    setActiveKey(key as string);
  };

  const handleChange = (key: string, value: string, index: number) => {
    const educationToUpdate = { ...educations[index] };

    if (educationToUpdate) {
      educationToUpdate[key] = value;

      const oldData = [...educations];
      oldData[index] = educationToUpdate;

      dispatch({
        type: "resume",
        payload: { ...rest, educations: [...oldData] }
      });
    }
  };

  return (
    <Progress
      addProgressButtonLabel="Ajouter une formation"
      header={Header}
      content={EducationInputs}
      currentKey={activeKey}
      progressions={educations}
      onChange={handleChange}
      onNew={onNew}
      onToggle={onCollapseChange}
      onDelete={onDelete}
    />
  );
};

export default EducationSection;
