import ContentLoader from "react-content-loader";

function ResumeItemSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={386}
      viewBox="0 0 100% 386"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      // {...props}
    >
      <path d="M 0 0 h 250 v 354 H 0 z" />
      <rect x="266" y="0" rx="5" ry="5" width="125" height="33" />
      <rect x="266" y="96" rx="5" ry="5" width="125" height="33" />
      <rect x="266" y="48" rx="5" ry="5" width="125" height="33" />
      <rect x="266" y="144" rx="5" ry="5" width="125" height="33" />
      <path d="M 0 361 h 133 v 9 H 0 z M 0 377 h 110 v 9 H 0 z" />
    </ContentLoader>
  );
}

export default ResumeItemSkeleton;
