import { List, notification } from "antd";
import Modal from "app/components/ui/modal";
import { templatesAtom } from "app/store/template";
import { Template } from "generated/graphql";
import { useAtom } from "jotai";
import { useCallback, useState } from "react";
import TemplateItem from "./item";

type VoidFunction = () => void;

interface TemplateSelectionProps {
  visible: boolean;
  onCancel: VoidFunction;
  onConfirm: (template: Template) => void;
}
function TemplateSelection({ visible, onCancel, onConfirm }: TemplateSelectionProps) {
  const [templates] = useAtom(templatesAtom);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateSelection = useCallback((selectedItem: Template) => {
    setSelectedTemplate(selectedItem);
  }, []);

  const handleConfirm = () => {
    if (!selectedTemplate) {
      notification.warning({ message: "Veuillez choisir un modèle" });
      return;
    }

    onConfirm(selectedTemplate);
    setSelectedTemplate(null);
  };
  const handleCancel = () => {
    onCancel();
    setSelectedTemplate(null);
  };

  return (
    <Modal
      title="Choisissez un modèle"
      okText="Suivant"
      visible={visible}
      width={900}
      onCancel={handleCancel}
      onOk={handleConfirm}
    >
      <List
        grid={{
          gutter: 50,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3
        }}
        style={{ justifyContent: "space-between" }}
        dataSource={templates || []}
        renderItem={(template) => (
          <List.Item style={{ marginBottom: 50 }}>
            <TemplateItem
              key={template.id}
              item={template}
              isSelected={template.component === selectedTemplate?.component}
              onSelect={handleTemplateSelection}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
}

export default TemplateSelection;
