import { Collapse } from "antd";
import styled from "styled-components";

export const StyledProgressWrapper = styled.div``;

export const StyledProgressItem = styled(Collapse)`
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid #dadde1;

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-extra {
    align-self: center;
  }

  .ant-collapse-content {
    border-radius: 8px;
    border-top: none;
  }

  .deletion-action {
    height: auto;
    padding: 0;
  }
`;

export const StyledHeader = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);

  .title {
    font-weight: 500;
    font-size: 15px;
    color: #000;
  }
`;
