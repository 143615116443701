import { Col, Row, Select } from "antd";
import { MONTHS, YEARS_RANGE } from "app/constants";
import { useEffect, useState } from "react";

interface ProgressDateProps {
  value: string;
  isCurrent?: boolean;
  onChange: (value: string) => void;
}
function ProgressDate({ value, isCurrent, onChange }: ProgressDateProps) {
  const [date, setDate] = useState<{ month: number | string; year: number | string }>({ month: "Mois", year: "Année" });
  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setDate({ month: date.getMonth(), year: date.getFullYear() });
    }
  }, [value]);

  const handleChange = (value, key) => {
    const newDate = { ...date, [key]: value };

    if (Number.isInteger(newDate.month) && Number.isInteger(newDate.year)) {
      onChange(new Date(Number(newDate.year), Number(newDate.month)).toISOString());
    }

    setDate((prev) => ({ ...prev, [key]: value }));
  };

  return isCurrent ? (
    <div className="flex align-bottom items-end h-full">
      <div className="w-full border-1 border-[1px] rounded-[4px] border-[#d9d9d9] h-[48px]  flex items-center pl-2">
        Présent
      </div>
    </div>
  ) : (
    <Row gutter={5}>
      <Col span={12}>
        <Select
          defaultValue="Mois"
          value={date.month}
          onChange={(value) => {
            handleChange(value, "month");
          }}
          options={MONTHS}
          className="w-full"
        />
      </Col>
      <Col span={12}>
        <Select
          defaultValue="Année"
          value={date.year}
          onChange={(value) => {
            handleChange(value, "year");
          }}
          options={YEARS_RANGE.map((year) => ({ label: year, value: year }))}
          className="w-full"
        />
      </Col>
    </Row>
  );
}

ProgressDate.defaultProps = {
  isCurrent: false
};
export default ProgressDate;
