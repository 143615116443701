import styled from "styled-components";

export const StyledEditorWrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .body-absolute {
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .body {
    display: flex;
    positon: absolute;
    height: 100%;
    width: 100%;
  }
`;
