import styled from "styled-components";

export const StyledTemplateItem = styled.div`
  .highlight-mark {
    display: none;
  }

  &.selected {
    border: 2px solid var(--primary-color);

    .highlight-mark {
      display: flex;
      color: #fff;
      left: calc(50% - 16px);
      top: calc(50% - 16px);
    }
  }
`;
