import styled from "styled-components";

export const StyledWrapper = styled.div`
  // display: grid;
  // grid-template-columns: 300px calc(100% - 540px) 240px;
  // display: flex;
  height: 100%;
  background-color: #f0f2f5;
  position: relative;
  color: initial;

  .content {
    width: 100%;
    overflow: scroll;
    height: 100%;
  }

  .side-bar {
    // width: 280px;
    background-color: #fff;

    div {
      // border-top: 1px solid #000;

      .title {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
      }
    }
  }
`;
