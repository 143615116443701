import { Alert, Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Label from "app/components/ui/label";
import TextEditor from "app/main/text-editor";
import ProgressDate from "app/components/ui/progress/progress-date";
import { ProgressEntryProps } from "app/components/ui/progress/types";
import { Experience } from "generated/graphql";
import { ChangeEvent, useMemo } from "react";
import Input from "app/components/ui/input";

const errorsLabel = {
  jobTitle: "Veuillez entrer le titre votre poste",
  employer: "Veuillez entrer votre employeur",
  location: "Veuillez entrer le lieux de votre emploi"
};

export const ExperienceInputs = ({ progression: experience, index, onChange }: ProgressEntryProps<Experience>) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    onChange(name, value, index);
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target;

    onChange(name, checked, index);
  };

  const handleDescriptionChange = (text: string) => {
    onChange("description", text, index);
  };

  const errors = useMemo(() => {
    const temp = Object.entries(errorsLabel).reduce((prev, [key, value]) => {
      if (!experience[key]) {
        prev.push(value);
      }

      return prev;
    }, []);

    if (!experience.isCurrent && (!experience.startDate || !experience.endDate)) {
      temp.push("Veuillez entrer la période de votre emploi");
    }

    if (
      !experience.isCurrent &&
      experience.startDate &&
      experience.endDate &&
      new Date(experience.startDate) > new Date(experience.endDate)
    ) {
      temp.push("La date de début ne peut pas être supérieure à la date de fin");
    }

    return temp;
  }, [experience]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Label>Titre du poste</Label>
        <Input value={experience.jobTitle} name="jobTitle" onChange={handleInputChange} />
      </Col>

      <Col span={12}>
        <Label>Employeur</Label>
        <Input name="employer" value={experience.employer} onChange={handleInputChange} />
      </Col>

      <Col span={12}>
        <Label>Site web</Label>
        <Input name="link" type="url" value={experience.link} onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <Label>Lieux</Label>
        <Input name="location" value={experience.location} onChange={handleInputChange} />
      </Col>

      <Col span={24}>
        <Checkbox name="isCurrent" onChange={handleCheckboxChange} checked={experience.isCurrent}>
          Je travaille toujours ici.
        </Checkbox>
      </Col>

      <Col xs={24} md={12}>
        <Label>Date de début</Label>
        <ProgressDate
          key={1}
          value={experience.startDate}
          onChange={(value) => {
            onChange("startDate", value, index);
          }}
        />
      </Col>

      <Col xs={24} md={12}>
        {experience.isCurrent ? (
          <div className="flex items-end h-full align-bottom">
            <div className="w-full border-1 border-[1px] rounded-[4px] border-[#d9d9d9] h-[48px]  flex items-center pl-2">
              Présent
            </div>
          </div>
        ) : (
          <>
            <Label>Date de fin</Label>
            <ProgressDate
              key={2}
              value={experience.endDate}
              onChange={(value) => {
                onChange("endDate", value, index);
              }}
            />
          </>
        )}
      </Col>

      <Col span={24}>
        <Label>Description</Label>
        <TextEditor value={experience.description} onChange={handleDescriptionChange} />
      </Col>

      {!!errors.length && (
        <Alert
          className="w-full"
          type="error"
          message={
            <ul className="list-disc list-inside">
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          }
        />
      )}
    </Row>
  );
};

export default ExperienceInputs;
